import React, { useEffect } from 'react';
import '../../styles/Support.scss';

const Support = () => {
  useEffect(() => {
    const main = document.getElementsByTagName('main')[0]; // eslint-disable-line no-undef
    main.classList.add('Support-Page');

    return () => {
      main.classList.remove('Support-Page');
    };
  });

  return (
    <iframe
      title="Customer Bookings"
      src="https://outlook.office365.com/owa/calendar/customers@eifas.org/bookings/"
      width="100%"
      style={{ height: '100vh', border: 0 }}
    />
  );
};

export default Support;
