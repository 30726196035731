/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import SimpleDataTableContainer from 'common/containers/SimpleDataTable';
import Paper from '@material-ui/core/Paper';

const TransactionsTable = ({ account }, { t }) => (
  <Paper>
    <div style={{ padding: 15 }}>
      <h2 style={{ display: 'block', margin: '10px 0' }}>{t('Account Transactions')}</h2>

      <ul style={{ fontSize: 14, marginBottom: 20 }}>
        <li>
          {t('Account Number')}: {account.account_number}
        </li>
        <li>
          {t('Bank Name')}: {account.bank_name}
        </li>
        <li>
          {t('IBAN')}: {account.iban}
        </li>
        <li>
          {t('BIC')}: {account.bic}
        </li>
      </ul>
    </div>

    <SimpleDataTableContainer
      endpoint="/api/v1/figo_transactions"
      query={{
        figo_account_id: account.id,
      }}
      columns={[
        {
          title: t('Type'),
          field: 'type',
          render: rowData => (
            <Tooltip
              title={
                rowData.processed
                  ? t('This transaction is processed and assigned to an invoice.')
                  : t('This transaction is not processed yet.')
              }
              placement="top"
            >
              <span style={{ cursor: 'pointer' }}>{rowData.type}</span>
            </Tooltip>
          ),
        },
        {
          title: t('Amount'),
          field: 'amount',
          render: rowData =>
            new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: rowData.currency,
              minimumFractionDigits: 2,
            }).format(rowData.amount),
        },
        {
          title: t('Name'),
          field: 'name',
        },
        {
          title: t('Booking Text'),
          field: 'booking_text',
        },
        {
          title: t('Purpose'),
          field: 'purpose',
        },
        {
          title: t('Booking Date'),
          field: 'booking_date',
          render: rowData => moment(rowData.booking_date).format('Do MMMM YYYY'),
        },
        {
          title: t('Value Date'),
          field: 'value_date',
          render: rowData => moment(rowData.value_date).format('Do MMMM YYYY'),
        },
      ]}
      fallbackMessage={t('The are no results for this query.')}
      tableCellStyles={{ paddingRight: 10, paddingLeft: 10 }}
      rowStyles={rowData => ({ borderLeft: `5px solid ${rowData.processed ? 'green' : 'red'}` })}
    />
  </Paper>
);

TransactionsTable.propTypes = {
  account: PropTypes.object.isRequired,
};

TransactionsTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TransactionsTable;
