import omit from 'lodash/omit';
import { FETCH_INVOICE, REMOVE_INVOICE } from '../constants/types';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INVOICE: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      };
    }

    case REMOVE_INVOICE: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
