import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'users/components/inbox/MaterialTable';

const InboxNotificationsPage = ({ notifications, handleUpdate }, { t }) => (
  <section className="cnt notifications-page">
    <Paper className="root-paper">
      {notifications.today.length === 0 && (
        <h4 style={{ padding: 20 }}>{t('No overdue notifications.')}</h4>
      )}

      {notifications.today.length > 0 && (
        <MaterialTable
          title={t('Due Today/Overdue')}
          notifications={notifications.today}
          handleUpdate={handleUpdate}
          search={false}
        />
      )}

      <br />

      {notifications.other.length === 0 && (
        <h4 style={{ padding: 20 }}>{t('No other notifications.')}</h4>
      )}
      {notifications.other.length > 0 && (
        <MaterialTable
          title={t('Other')}
          notifications={notifications.other}
          handleUpdate={handleUpdate}
        />
      )}
    </Paper>
  </section>
);

InboxNotificationsPage.propTypes = {
  notifications: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

InboxNotificationsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InboxNotificationsPage;
