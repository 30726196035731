/* eslint-disable camelcase */
import {
  createResource,
  fetchResources,
  fetchResource,
  updateResource,
  deleteResource,
} from './api';
import {
  ADD_DOCUMENT,
  FETCH_DOCUMENTS,
  UPDATE_DOCUMENT,
  REMOVE_DOCUMENT,
} from '../constants/types';
import { closeModalForm } from './utils';
import history from 'common/store/history';

// Common update logic function
const commonUpdateLogic = (id, values, { setSubmitting, setErrors }, dispatch, type) => {
  const data = { document: { ...values } };

  return updateResource({
    type: 'documents',
    endpointPath: `customers/${values.customer_id}/documents/${id}`,
    version: '/api/v2',
    data,
    setSubmitting,
    setErrors,
  })(dispatch).then(() => {
    dispatch({
      type,
      payload: { id, ...values },
    });
  });
};

export const addDocument = document => dispatch => {
  dispatch({
    type: ADD_DOCUMENT,
    payload: document,
  });
};

export const createDocument = (values, { setSubmitting, setErrors }) => dispatch => {
  const data = { document: { ...values } };

  createResource({
    type: 'documents',
    endpointPath: `customers/${values.customer_id}/documents`,
    data,
    setSubmitting,
    setErrors,
  })(dispatch).then(document => {
    dispatch({
      type: ADD_DOCUMENT,
      payload: document,
    });
  });
};

export const updateDocument = ({ id, ...values }, { setSubmitting, setErrors }) => dispatch => {
  return commonUpdateLogic(id, values, { setSubmitting, setErrors }, dispatch, UPDATE_DOCUMENT);
};

export const toggleDocumentStatus = ({ id, ...rest }) => dispatch => {
  rest.status = rest.status === 'open' ? 'processed' : 'open';

  return commonUpdateLogic(id, rest, {}, dispatch, UPDATE_DOCUMENT);
};

export const fetchDocuments = (customerId, query = {}) => dispatch => {
  fetchResources({
    type: 'documents',
    query,
    endpointPath: `customers/${customerId}/documents`,
  })(dispatch).then(data => {
    dispatch({
      type: FETCH_DOCUMENTS,
      payload: { data, customerId },
    });
  });
};

export const fetchDocument = ({ documentId, customerId }) => dispatch =>
  fetchResource({
    type: 'documents',
    endpointPath: `customers/${customerId}/documents/${documentId}`,
  })(dispatch).then(document => {
    dispatch({
      type: ADD_DOCUMENT,
      payload: document,
    });
  });

export const deleteDocument = (id, customerId) => dispatch =>
  deleteResource({
    type: 'documents',
    endpointPath: `customers/${customerId}/documents/${id}`,
  })(dispatch).then(() => {
    dispatch({
      type: REMOVE_DOCUMENT,
      payload: { customerId, id },
    });
  });

export const toInvoice = (id, customerId, values, { setErrors, setSubmitting }) => dispatch => {
  const data = { document: { ...values } };

  if (!values.kind) {
    setErrors({ kind: 'is required' });
    setSubmitting(false);
    return;
  }

  createResource({
    type: 'documents',
    endpointPath: `customers/${customerId}/documents/${id}/to_invoice`,
    data,
    successMessage: 'Successfully created invoice!',
  })(dispatch).then(data => {
    dispatch({
      type: REMOVE_DOCUMENT,
      payload: { customerId, id },
    });

    history.push(`/react_admins/customers/${customerId}/invoices/${data.id}`);
  });
};

export const fetchLatestDocuments = customerId => dispatch => {
  const headers = { Range: '0-8', 'Range-Unit': 'items' };
  const query = { order: { created_at: 'desc' } };

  return fetchResources({
    type: 'documents',
    endpointPath: `customers/${customerId}/documents`,
    query,
    headers,
  })(dispatch).then(data => {
    dispatch({
      type: FETCH_DOCUMENTS,
      payload: { data, customerId },
    });
  });
};

export const notifyCustomer = ({ customerId, documentId, data, actions }) => dispatch =>
  updateResource({
    version: '/api/v2',
    method: 'POST',
    data,
    endpointPath: `customers/${customerId}/documents/${documentId}/notify_customer`,
    successMessage: 'Successfully created a notification for the customer.',
    ...actions,
  })(dispatch).then(document => {
    dispatch(closeModalForm());
    dispatch({ type: ADD_DOCUMENT, payload: document });

    return document;
  });

export const createTicketForDocument = ({
                                          ticketDetails,
                                          successMessage = 'Ticket successfully created! ',
                                          errorMessage = 'Error on creating ticket.'
                                        }) => {
  return (dispatch) => {
    const { customerId, documentId, subject, description, customer_type, ticket_status_id } = ticketDetails;

    const data = {
      subject,
      description,
      customer_type,
      ticket_status_id,
    };

    // Return the promise from createResource
    return createResource({
      type: 'tickets',
      version: '/api/v2',
      endpointPath: `customers/${customerId}/documents/${documentId}/ticket`,
      data,
      successMessage: successMessage,
      errorMessage: errorMessage,
    })(dispatch)
        .then(ticket => {
          dispatch({
            type: 'ADD_TICKET',
            payload: ticket,
          });
          return ticket;
        })
        .catch(error => {
          throw error;
        });
  };
};
