import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthorizationGateContainer = ({ roles, feature, children, redirect, as: Wrapper }) => {
  const { role, features } = useSelector(state => state.auth.currentUser);
  const location = useLocation();
  const normalizedRoles = Array.isArray(roles) ? roles : roles.split(', ');
  const featureEnabled = feature ? features.includes(feature) : true;
  const adminApp = location.pathname.includes('/react_admins');
  const roleAllowed = normalizedRoles.length === 0 ? true : normalizedRoles.includes(role);

  if (roleAllowed && featureEnabled) {
    return Wrapper ? <Wrapper>{children}</Wrapper> : children;
  }

  if (adminApp) {
    return redirect ? <Redirect to="/react_admins" /> : null;
  }

  return null;

  // return redirect ? <Redirect to="/users/home" /> : null;
};

AuthorizationGateContainer.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node.isRequired,
  feature: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  redirect: PropTypes.bool,
};

AuthorizationGateContainer.defaultProps = {
  roles: [],
  feature: null,
  as: null,
  redirect: false,
};

export default AuthorizationGateContainer;
