import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { teal as green, orange, red, blue } from '@material-ui/core/colors';

export const RedButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export const GreenButton = withStyles(theme => ({
  root: {
    color: `${theme.palette.getContrastText(green[500])} !important`,
    backgroundColor: `${green[500]} !important`,
    '&:hover': {
      backgroundColor: `${green[700]} !important`,
    },
  },
}))(Button);

export const OrangeButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

export const BlueButton = withStyles(theme => ({
  root: {
    color: `${theme.palette.getContrastText(blue[500])} !important`,
    backgroundColor: `${blue[500]} !important`,
    '&:hover': {
      backgroundColor: `${blue[700]} !important`,
    },
  },
}))(Button);
