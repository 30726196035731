/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar';
import AnimateHeight from 'react-animate-height';

import AngularLink from 'common/components/AngularLink';
import DropdownMenu from 'common/components/DropdownMenu';
import AuthorizationGateContainer from 'common/containers/AuthorizationGate';

const getLogoPath = role => {
  switch (role) {
    case 'payroll_staff':
      return '/users/employees/salary';
    case 'documents_staff':
      return '/users/documents';
    default:
      return '/users/home';
  }
};

const Sidebar = (
  {
    currentUser: {
      role,
      submittedReportsCount,
      vacationsRequestsCount,
      customerName,
      notificationsCount,
      features,
      figoConnected,
    },
    signOut,
  },
  { t }
) => {
  const location = useLocation();
  const defaultOpen = location.pathname.includes('/evaluations/individual');

  return (
    <header id="header" role="banner">
      <PerfectScrollbar className="scrollbar" option={{ suppressScrollX: true }}>
        <h1 className="ir">
          <AngularLink to={getLogoPath(role)}>eifas</AngularLink>
        </h1>

        <nav className="navigation" role="navigation">
          <h2 className="hidden">Navigation</h2>

          <ul>
            <AuthorizationGateContainer roles="manager, guest, investor, accounting_staff" as="li">
              <span className="title">{t('Financial Accounting')}</span>

              <ul>
                <li>
                  <AngularLink to="/users/home">
                    <React.Fragment>
                      <i className="icon fa fa-home" />
                      {t('Overview')}
                    </React.Fragment>
                  </AngularLink>
                </li>

                <AuthorizationGateContainer roles="manager" as="li" feature="inbox">
                  <NavLink
                    to="/users/inbox"
                    activeClassName="current"
                    className="with-label"
                    isActive={(match, location) =>
                      ['/users/inbox', '/users/inbox/invoices'].includes(location.pathname)
                    }
                  >
                    <i className="icon fa fa-envelope" />
                    {t('Inbox')}
                    {notificationsCount > 0 && <span className="label">{notificationsCount}</span>}
                  </NavLink>
                </AuthorizationGateContainer>

                <AuthorizationGateContainer roles="manager" as="li" feature="figo">
                  <NavLink to="/users/bank_accounts" activeClassName="current">
                    <i className="icon fa fa-bank" />
                    {t('Bank Accounts')}
                  </NavLink>
                </AuthorizationGateContainer>

                <DropdownMenu defaultOpen={defaultOpen}>
                  {(handleClick, open) => (
                    <li id="reports_menu">
                      <a href="javascript:void(0)" onClick={handleClick}>
                        <i className="icon fa fa-file" />
                        {t('Reports')}
                      </a>

                      <AnimateHeight height={open ? 'auto' : 0} duration={300}>
                        <ul className="secondary-menu beExpanded" style={{ display: 'block' }}>
                          <li>
                            <AngularLink to="/users/evaluations/bwa">
                              {t('Business Assessment')}
                            </AngularLink>
                          </li>

                          <li>
                            <AngularLink to="/users/evaluations/cashflow">
                              {t('Cashflow')}
                            </AngularLink>
                          </li>

                          <AuthorizationGateContainer
                            roles="manager, guest, accounting_staff"
                            as="li"
                          >
                            <AngularLink to="/users/evaluations/susa">
                              {t('Trial Balance')}
                            </AngularLink>
                          </AuthorizationGateContainer>

                          <AuthorizationGateContainer
                            roles="manager, accounting_staff"
                            feature="individual_reports"
                            as="li"
                          >
                            <NavLink
                              to="/users/evaluations/individual"
                              activeClassName="current"
                              style={{ background: 'unset' }}
                            >
                              {t('Individual Reports')}
                            </NavLink>
                          </AuthorizationGateContainer>
                        </ul>
                      </AnimateHeight>
                    </li>
                  )}
                </DropdownMenu>

                <AuthorizationGateContainer roles="manager, guest, accounting_staff" as="li">
                  {/* TODO: Remove constant condition when feature is ready for prod */}
                  {/* eslint-disable-next-line  */}
                  {role === 'manager' && features.includes('figo') && figoConnected && false ? (
                    <NavLink exact to="/users/open_items" activeClassName="current">
                      <i className="icon fa fa-book" />
                      {t('Accounts Receivable / Payable')}
                    </NavLink>
                  ) : (
                    <AngularLink to="/users/evaluations/fordverb">
                      <i className="icon fa fa-book" />
                      {t('Accounts Receivable / Payable')}
                    </AngularLink>
                  )}
                </AuthorizationGateContainer>

                <AuthorizationGateContainer
                  roles="manager, guest"
                  feature="invoice_pdf_mgt"
                  as="li"
                >
                  <AngularLink to="/users/invoices">
                    <i className="icon fa fa-book" />
                    {t('Invoice PDFs')}
                  </AngularLink>
                </AuthorizationGateContainer>
              </ul>
            </AuthorizationGateContainer>

            <AuthorizationGateContainer
              roles="manager, guest, payroll_staff"
              feature="employee_mgt"
              as="li"
            >
              <span className="title">{t('Employee')}</span>

              <ul>
                <DropdownMenu>
                  {(handleClick, open) => (
                    <li id="management_menu">
                      <a href="javascript:void(0)" onClick={handleClick}>
                        <i className="icon fa fa-user" />
                        {t('Management')}
                      </a>

                      <AnimateHeight height={open ? 'auto' : 0} duration={300}>
                        <ul className="secondary-menu beExpanded" style={{ display: 'block' }}>
                          <li>
                            <AngularLink to="/users/employees/salary">{t('Salary')}</AngularLink>
                          </li>

                          <AuthorizationGateContainer
                            roles="manager, guest, payroll_staff"
                            feature="show_wage"
                            as="li"
                          >
                            <AngularLink to="/users/employees/wage">{t('Wage')}</AngularLink>
                          </AuthorizationGateContainer>

                          <li>
                            <AngularLink to="/users/employees/expenses" className="with-label">
                              {t('Expenses')}
                              {submittedReportsCount > 0 && (
                                <span className="label">{submittedReportsCount}</span>
                              )}
                            </AngularLink>
                          </li>

                          <li>
                            <AngularLink to="/users/employees/vacations" className="with-label">
                              {t('Vacation Requests')}
                              {vacationsRequestsCount > 0 && (
                                <span className="label">{vacationsRequestsCount}</span>
                              )}
                            </AngularLink>
                          </li>
                        </ul>
                      </AnimateHeight>
                    </li>
                  )}
                </DropdownMenu>
              </ul>
            </AuthorizationGateContainer>

            <AuthorizationGateContainer
              roles="manager, guest, documents_staff, accounting_staff"
              as="li"
            >
              <span className="title">{t('Documents')}</span>

              <ul>
                <li>
                  <AngularLink to="/users/documents/new">
                    <i className="icon fa fa-cloud-upload" />
                    {t('Upload Documents')}
                  </AngularLink>
                </li>

                <li>
                  <AuthorizationGateContainer
                    roles="manager, guest, payroll_staff, accounting_staff"
                    feature="cashbook"
                    as="li"
                  >
                    <NavLink exact to="/users/cashbook" activeClassName="current">
                      <i className="icon fa fa-money" />
                      {t('Cashbook')}
                    </NavLink>
                  </AuthorizationGateContainer>
                </li>

                <li>
                  <AngularLink to="/users/documents/my_uploads">
                    <i className="icon fa fa-folder-open" />
                    {t('Processing')}
                  </AngularLink>
                </li>

                <li>
                  <AngularLink to="/users/documents/my_storage">
                    <i className="icon fa fa-hdd-o" />
                    {t('My Storage')}
                  </AngularLink>
                </li>
              </ul>
            </AuthorizationGateContainer>

            <AuthorizationGateContainer roles="manager" as="li" feature="inbox">
              <span className="title">{t('Settings')}</span>

              <ul>
                <li>
                  <NavLink exact to="/users/support" activeClassName="current">
                    <i className="icon fa fa-question-circle" />
                    {t('Support')}
                  </NavLink>
                </li>

                <li>
                  <NavLink exact to="/users/inbox/settings" activeClassName="current">
                    <i className="icon fa fa-envelope" />
                    {t('Inbox Settings')}
                  </NavLink>
                </li>
              </ul>
            </AuthorizationGateContainer>
          </ul>
        </nav>
      </PerfectScrollbar>

      <div className="bottom">
        <AuthorizationGateContainer roles="guest, investor">
          <div className="guest-note">{t('Guest User - Limited Accessibility')}</div>
        </AuthorizationGateContainer>

        <div className="content">
          <ul className="clr">
            <li>
              <AngularLink to="/users/settings">
                <i className="icon fa fa-cog" />
                {customerName}
              </AngularLink>
            </li>

            <li>
              <a href="javascript:void(0)" onClick={() => signOut()}>
                <i className="icon fa fa-sign-out" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

Sidebar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

Sidebar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Sidebar;
