import {
  SHOW_LOADER,
  HIDE_LOADER,
  OPEN_HELP_MODAL,
  CLOSE_HELP_MODAL,
  FETCH_TEMPLATES,
  FETCH_BWA_TYPES,
  FETCH_COUNTRIES,
  FETCH_INDUSTRY_KEYS,
  FETCH_LEGAL_FORM_ENTRIES,
  OPEN_MODAL_FORM,
  CLOSE_MODAL_FORM,
  SET_GRID_ACCOUNT_DATA,
  RESET_GRID_ACCOUNT_DATA,
  SET_POPUP_WINDOW_OPEN,
  SET_POPUP_WINDOW_CLOSE,
} from '../constants/types';

const INITIAL_STATE = {
  loader: false,
  helpModalOpen: false,
  modalFormOpen: false,
  modalFormData: {},
  countries: [],
  industry_keys: [],
  legal_form_entries: [],
  bwaTypes: [],
  templates: [],
  gridAccountCode: null,
  gridAccountType: null,
  popupWindowOpen: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case OPEN_HELP_MODAL: {
      return {
        ...state,
        helpModalOpen: true,
      };
    }

    case CLOSE_HELP_MODAL: {
      return {
        ...state,
        helpModalOpen: false,
      };
    }

    case OPEN_MODAL_FORM: {
      const { id, ...rest } = action.payload;

      return {
        ...state,
        modalFormOpen: id,
        modalFormData: rest,
      };
    }

    case CLOSE_MODAL_FORM: {
      return {
        ...state,
        modalFormOpen: false,
        modalFormData: {},
      };
    }

    case FETCH_TEMPLATES: {
      return {
        ...state,
        templates: action.payload,
      };
    }

    case FETCH_BWA_TYPES: {
      return {
        ...state,
        bwaTypes: action.payload,
      };
    }

    case FETCH_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case FETCH_INDUSTRY_KEYS: {
      return {
        ...state,
        industry_keys: action.payload,
      };
    }

    case FETCH_LEGAL_FORM_ENTRIES: {
      return {
        ...state,
        legal_form_entries: action.payload,
      };
    }

    case SET_GRID_ACCOUNT_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case RESET_GRID_ACCOUNT_DATA: {
      return {
        ...state,
        gridAccountCode: null,
        gridAccountType: null,
      };
    }

    case SET_POPUP_WINDOW_OPEN: {
      return {
        ...state,
        popupWindowOpen: true,
        popupInvoiceId: action.payload,
      };
    }

    case SET_POPUP_WINDOW_CLOSE: {
      return {
        ...state,
        popupWindowOpen: false,
        popupInvoiceId: null,
      };
    }

    default:
      return state;
  }
}
