/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';

const AccountsTable = ({ accounts, history }, { t }) => (
  <MaterialTable
    title={t('Bank Accounts')}
    columns={[
      {
        title: t('Account ID'),
        field: 'account_id',
      },
      {
        title: t('Account Number'),
        field: 'account_number',
      },
      {
        title: t('Type'),
        field: 'type',
      },
      {
        title: t('Bank Code'),
        field: 'bank_code',
      },
      {
        title: t('Bank Name'),
        field: 'bank_name',
      },
      {
        title: t('BIC'),
        field: 'bic',
      },
      {
        title: t('IBAN'),
        field: 'iban',
      },
      {
        title: t('Currency'),
        field: 'currency',
      },
      {
        title: t('Owner'),
        field: 'owner',
      },
    ]}
    data={accounts}
    options={{
      search: true,
      paging: true,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50],
    }}
    onRowClick={(event, rowData) => history.push(`/users/bank_accounts/${rowData.id}/transactions`)}
  />
);

AccountsTable.propTypes = {
  accounts: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

AccountsTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(AccountsTable);
