import { SIGN_OUT, SIGN_IN } from '../constants/types';
import { createResource, deleteResource, fetchResource } from './api';
import history from 'common/store/history';

export const handleSignOut = (type = 'users') => dispatch =>
  deleteResource({ type: 'sessions', endpointPath: `${type}/sign_out.json`, baseUrl: false })(
    dispatch
  ).then(() => {
    dispatch({ type: SIGN_OUT });
    if (type === 'users') {
      window.location.reload(); // eslint-disable-line no-undef
    } else {
      history.replace('/react_admins/login');
    }
  });

export const signIn = ({ type = 'users', data, ...rest }) => dispatch =>
  createResource({
    type: 'sessions',
    endpointPath: `${type}/sign_in.json`,
    data,
    baseUrl: false,
    ...rest,
  })(dispatch).then(() => {
    fetchResource({ type: 'store', endpointPath: 'store' })(dispatch).then(user => {
      dispatch({ type: SIGN_IN, payload: user });
    });
  });
