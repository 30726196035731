/* eslint-disable react/prop-types */

import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const KeyboardDateField = ({
  label,
  form: { setFieldValue },
  field: { name, value },
  format,
  ...rest
}) => {
  let selectedValue;

  if (value === '' || !value) {
    selectedValue = null;
  } else {
    selectedValue = moment(value);
  }

  return (
    <KeyboardDatePicker
      name={name}
      label={label}
      format={format || 'DD/MM/YYYY'}
      placeholder="25/01/2020"
      maxDate="2100-01-01"
      onChange={value => {
        if (value) {
          setFieldValue(name, value.toString(), true);
        } else {
          setFieldValue(name, null);
        }
      }}
      value={selectedValue}
      {...rest}
    />
  );
};

export default KeyboardDateField;
