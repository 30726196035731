import { FETCH_ACTIVITY_TYPES, FETCH_ACTIVITY_TYPE } from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
  params: {
    page: 1,
    per_page: 10,
    total: 0,
    q: null,
  },
  show: {
    loading: false,
    item: null,
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_TYPES:
      return {
        ...state,
        items: arrayToHashMap(action.payload.items),
        params: {
          ...action.payload.params,
          ...action.payload.paginationMeta,
        },
        show: {
          ...INITIAL_STATE.show,
        },
      };
    case FETCH_ACTIVITY_TYPE:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
