/* eslint-disable no-undef */

export const dataURItoBlob = dataURI => {
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);

  ia.forEach(i => {
    ia[i] = byteString.charCodeAt(i);
  });

  return new Blob([ia], { type: mimeString });
};

export const arrayToHashMap = arr =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

export const normalizeInitialValues = (values, nullableFields = []) => {
  const normalized = {};

  Object.keys(values).forEach(key => {
    if (values[key] !== null && values[key] !== undefined) {
      normalized[key] = values[key];
    } else {
      normalized[key] = nullableFields.includes(key) ? values[key] : '';
    }
  });

  return normalized;
};

export const handleImageFormData = ({ values, formData, resourceKey, key }) => {
  const image = values[key];

  if (image) {
    formData.set(`${resourceKey}[${key}]`, image);
  } else {
    formData.delete(`${resourceKey}[${key}]`);
  }
};

export const generateFormData = ({ values, resourceKey, imageKey = null }) => {
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    if (imageKey === key) {
      handleImageFormData({
        values,
        formData,
        resourceKey,
        key,
      });
    } else {
      formData.append(`${resourceKey}[${key}]`, values[key]);
    }
  });

  return formData;
};
