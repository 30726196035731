export const translations = {
  de: {
    'Cash basis taxpayer': 'Ist-Versteuerer',
    'Accrual basis taxpayer': 'Soll-Versteuerer',
    'Taxation Mode': 'Besteuerungsart',
    Email: 'E-Mail',
    'First Name': 'Vorname',
    'Last Name': 'Nachname',
    Password: 'Passwort',
    'Password Confirmation': 'Passwort Bestätigung',
    Telephone: 'Telefon',
    Address: 'Adresse',
    Status: 'Status',
    Active: 'Aktiviert',
    Disabled: 'Deaktiviert',
    Role: 'Rolle',
    Agent: 'Mitarbeiter',
    Admin: 'Admin',
    Submit: 'Absenden',
    Actions: 'Aktionen',
    Disable: 'Deaktivieren',
    Enable: 'Aktivieren',
    Accountants: 'Buchhalter',
    'Activity Types': 'Tätigkeitsarten',
    'Edit Activity Type': 'Tätigkeitsart anpassen',
    Name: 'Name',
    'Price per hour (EUR/h)': 'Preis pro Stunde (€/h)',
    Note: 'Notiz',
    Customer: 'Kunde',
    'Activity Name': 'Name der Tätigkeit',
    Price: 'Preis',
    'Related customer': 'Verknüpfter Kunde',
    'Activity Type': 'Tätigkeitsart',
    'Create Activity Type': 'Tätigkeitsart hinzufügen',
    ID: 'ID',
    'Transactions #': 'Transaktions #',
    'Transactions Sum': 'Transaktionssumme',
    'Exportable Transaction Amount': '',
    'Document Number': 'Dokumentnummer',
    'Assign Invoice': 'Rechnung zuordnen',
    'Process Transaction': 'Transaktion verarbeiten',
    'Search by ID or Document Number': '',
    'ID / Document Number': '',
    'No transactions to show.': '',
    Type: 'Art',
    Amount: 'Betrag',
    'Booking Text': '',
    Purpose: '',
    'Booking Date': '',
    'Value Date': '',
    'Processed?': 'Verarbeitet?',
    'Invoice ID': 'Rechnungs-ID',
    'Request Changes': '',
    'Request Invoice': 'Anforderungsrechnung',
    Filters: '',
    Unprocessed: 'Nicht verarbeitet',
    Processed: 'Verarbeitet',
    All: '',
    'Start Date': '',
    'End Date': '',
    'Export Bank Statement': '',
    'The are no results for this query.': '',
    Code: 'Nummer',
    Template: '',
    Supplier: 'Lieferant',
    'Account Template': 'Kontenvorlage',
    'No Template': 'Keine Vorlage',
    'Supplier Accounts': 'Lieferantenkonten',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Bank account number': 'Bankkontonummer',
    'Supplier Banking Accounts': '',
    'This creditor is versioned and can no longer be updated.': '',
    'View new version': '',
    'This is a new version of a previous creditor.': '',
    'View old version': '',
    'Supplier Number': 'Lieferantennummer',
    Logo: 'Logo',
    Street: 'Straße',
    'House Number': 'Hausnummer',
    Country: 'Land',
    City: 'Stadt',
    Zip: 'PLZ',
    'Company Information': 'Firmeninformationen',
    Website: 'Website',
    HRB: 'HRB',
    'Small Business': 'Kleinunternehmen',
    'VAT ID': 'USt-ID',
    'Contact Information': 'Kontaktinformationen',
    'E-Mail': 'E-Mail',
    Phone: 'Telefon',
    'Accounting Information': 'Buchhaltungsinformationen',
    'Credit Account Template SK03': 'Personenkonto SK03',
    'Credit Account Template SK04': 'Personenkonto SK04',
    Description: 'Beschreibung',
    'Save as a new version': 'Als neue Version speichern',
    Delete: 'Löschen',
    Suppliers: 'Lieferanten',
    'Record Dump Errors': 'Abzug-Fehler aufzeichnen',
    'No error on records dump': 'Kein Fehler beim Dump von Datensätzen',
    'Account Dump Errors': 'Fehler beim Kontenabzug',
    'No error on accounts dump': 'Kein Fehler beim Kontenabzug',
    'Request At': 'Angefragt um',
    Succeeded: 'Erfolgreich',
    Failed: 'Gescheitert',
    Forced: 'Erzwungen',
    Errors: 'Fehler',
    'Force Import': 'Import erzwingen',
    'Ongoing Imports': 'Laufende Importe',
    "There's currently no ongoing import": 'Es gibt derzeit keinen laufenden Import',
    'Requested At': 'Angefordert am',
    'Run Time': 'Laufzeit',
    'Previous Imports': 'Frühere Importe',
    'There are no previous imports.': 'Es gibt keine früheren Einfuhren.',
    'Legal Form': 'Rechtsform',
    'BWA Type': 'BWA Typ',
    'Tax ID': 'Steuernummer',
    'Postal Code': 'Postleitzahl',
    State: 'Status',
    Director: 'Leiter',
    '#': '#',
    'Contact Person': 'Kontaktperson',
    'VAT Declaration': 'Mehrwertsteuererklärung',
    'Agenda Company ID': 'Agenda Mandantennummer',
    'Reports End Date': 'Enddatum der Berichte',
    'Invoice Email': 'Rechnungs-E-Mail',
    'Website Domain': 'Website-Domäne',
    'Tax Number': 'Steuernummer',
    "Employer's Liability Insurance Association": 'Berufsgenossenschaft',
    'Membership Number': 'Mitgliedsnummer',
    'Classification Number': 'Klassifizierungsnummer',
    'Taxation of Invoices': 'Besteuerung von Rechnungen',
    'Vat Declaration Period': 'MwSt.-Erklärungszeitraum',
    'Vat Declaration Date': 'Datum MwSt.-Erklärung',
    'Wage Declaration Period': 'Zeitraum für die Lohnabrechnung',
    'Wage Declaration Date': 'Datum Gehaltserklärung',
    'Reduction of Fees': 'Gebührenermäßigung',
    'Regular Working Hours': 'Regelmäßige Arbeitszeiten',
    'Payment of Wage Tax': 'Zahlung der Lohnsteuer',
    'Credit Transfer': 'Überweisung',
    'Direct Debit': 'Lastschriftverfahren',
    'Social Insurance Payment': 'Zahlung der Sozialversicherung',
    'Allocation Obligation (U1)': 'Zuteilungsverpflichtung (U1)',
    Yes: 'Ja',
    No: 'Nein',
    'Immediate Notification': 'Sofortige Benachrichtigung',
    'Business Number': 'Geschäftsnummer',
    'Finance Office Registration Number': 'Registernummer Finanzamt',
    'Sub Accounts': 'Unterkonten',
    Company: 'Firma',
    'Member Since': 'Mitglied seit',
    Customers: 'Kunden',
    Number: 'Nummer',
    'Created At': 'Erstellt am',
    'Latest Invoices': 'Neueste Rechnungen',
    'Document Patterns': 'Dokumentenmuster',
    Imports: 'Importe',
    'Recycle Bin': 'Mülleimer',
    'Process Grid': 'Prozessraster',
    'Process Page': 'Seite verarbeiten',
    'Index Invoices': 'Indexrechnungen',
    'Create Invoice': 'Rechnung erstellen',
    'There is no content for this section.': 'Kein Inhalt für diesen Bereich.',
    'Latest Documents': 'Neueste Dokumente',
    Documents: 'Dokumente',
    'Create Document': 'Dokument erstellen',
    Tracker: '',
    'Bank Transactions': 'Bankverkehr',
    'Payment Methods': '',
    'Manage Users': 'Benutzer verwalten',
    'Customer Time Trackings': '',
    Edit: 'Bearbeiten',
    'Customer Time Tracking': '',
    'Edit Customer Time Tracking': '',
    'Start Time': '',
    'End Time': '',
    'Time spent': '',
    'Activity Type Customer': '',
    Chargeable: '',
    Incomplete: '',
    'Not invoiceable': '',
    Invoiceable: 'Rechnungsdatum',
    Invoiced: 'Rechnung',
    'Start time': '',
    'Stop time': '',
    'Sum of time': '',
    User: 'Benutzer',
    'Not Invoiceable': 'Rechnungsdatum',
    'Total Invoiceable Amount': 'Rechnungsdatum',
    'Export Time Tracking': '',
    'Create Customer Time Tracking': '',
    'Invoice Category': '',
    'Only one category can be set as a category for invoices': '',
    'Uploadable Node': '',
    "If it's possible to upload documents to this node": '',
    Uploadable: '',
    'Updated At': 'Aktualisiert am',
    'Document Categories': 'Dokumentkategorien',
    Subcategories: 'Unterkategorien',
    'Number of uploads': 'Anzahl Uploads',
    'Created by': 'Erstellt durch',
    'Add Customer DocumentPack': 'Dokumentenpaket zum Kunden hinzufügen',
    'Customer Document Packs': 'Kunden Dokumentenpakete',
    'Remaining documents': 'Verbleibende Dokumente',
    Kind: 'Art',
    Issuer: 'Aussteller',
    VAT: 'MwSt',
    'Banking accounts': 'Bankkonten',
    Currency: 'Währung',
    'Base Currency': 'Hauptwährung',
    Account: 'Konto',
    'Create Account': 'Konto anlegen',
    'Contra Account': 'Gegenkonto',
    'Create Contra Account': 'Gegenkonto anlegen',
    'Cost Center': 'Kostenstelle',
    'Cost Unit': 'Kostenträger',
    'D/C Flag': '',
    Debit: 'Soll',
    Credit: 'Haben',
    'Tax Key': 'Steuerschlüssel',
    'Eligible?': 'Abzugsberechtigt?',
    Action: 'Aktion',
    'Assign Pattern': 'Muster zuordnen',
    'There are currently no document patterns created.':
      'Aktuell sind keine Dokumentenmuster erstellt.',
    'Invoices Without Available Pattern': 'Rechnungen ohne Dokumentenmuster',
    'Preview Invoice': 'Vorschau der Rechnung',
    'No Preview Available': 'Keine Vorschau verfügbar',
    'Open in a new tab': 'In neuem Tab öffnen',
    'Update Document': 'Dokument aktualisieren',
    Category: 'Kategorie',
    Year: 'Jahr',
    Cycle: 'Zyklus',
    'Move to invoices': 'Zu Rechnungen verschieben',
    'Notify Customer': 'Kunden benachrichtigen',
    'File Preview': 'Dateivorschau',
    Previous: 'Vorherige',
    Next: 'Nächste',
    Comments: 'Kommentare',
    'Notify Managers': 'Manager benachrichtigen',
    Export: 'Export',
    'Drag and drop Images or PDFs files here. You can drop directories too.':
      'Ziehe Dateien oder komplette Ordner mit dem Mauszeiger auf diese Fläche.',
    'You can also click to choose files': 'Du kannst auch Dateien mit einem Klick hier auswählen',
    'Imported Files': 'Importierte Dateien',
    'Set All Years': 'Jahr für alle',
    'Set All Cycles': 'Zyklus für alle',
    'Set All Categories Category': '',
    Reset: 'Zurücksetzen',
    'Submit All': 'Alle hochladen',
    'File Name': 'Dateiname',
    Submitted: 'Übertragen',
    Employee: 'Mitarbeiter',
    'Running Number': 'Geschäftsnummer',
    Date: 'Datum',
    Preview: 'Vorschau',
    Title: 'Titel',
    'Name Prefix': 'Namenspräfix',
    'Name Suffix': 'Namenszusatz',
    'Job Description': 'Stellenbeschreibung',
    Position: 'Position',
    'Date of Birth': 'Geburtsdatum',
    'Birth Country': 'Geburtsland',
    'Birth City': 'Geburtsort',
    'Marital Status': 'Beziehungsstand',
    Nationality: 'Nationalität',
    'Residence City': 'Wohnort',
    'Additional Address Information': 'Zusätzliche Adressinformationen',
    'BIC/SWIFT': 'BIC/SWIFT',
    'Social Security Number': 'Sozialversicherungsnummer',
    'Retired?': 'im Ruhestand?',
    'Retirement Type': 'Art des Ruhestandes',
    'Tax Class': 'Steuerklasse',
    'Employment Date': 'Anstellungsdatum',
    'Unemployment Date (leave blank for unlimited)': 'Kündigungsdatum (Leer lassen für unbegrenzt)',
    'Secondary Job': 'Zweitjob',
    'Secondary Job Employment Date': 'Zweitjob Anstellungsdatum',
    'Secondary Job Insurance Type': 'Zweitjob Versicherungsart',
    'Statutory Health Insurance': 'Gesetzliche Krankenversicherung',
    'Private Health Insurance': 'Private Krankenversicherung',
    'Type of Health Insurance': 'Art der Krankenversicherung',
    'Last statutory Health Insurance': 'Letzte gesetzliche Krankenversicherung',
    'Wage Type': 'Lohnart',
    Salary: 'Gehalt',
    Wage: 'Lohn',
    'Weekly Working Hours': 'Wochenarbeitszeit (h)',
    'Employment Type': 'Anstellungsdatum',
    Standard: 'Standart',
    Intern: 'Intern',
    'Part Time': 'Teilzeit',
    'Mini Job': 'Mini-Job',
    'Exemption from pension insurance': 'Ausnahme zur Rentenversicherung',
    'Vacation Days Eligible': 'Urlaubsanspruch (Tage)',
    'Vacation Days from Last Year': 'Urlaubstage vom letzten Jahr',
    'Severely Disabled': 'Schwerbehindert',
    'Hide Registration Form': 'Anmeldeformular ausblenden',
    Employees: 'Angestellte',
    'Process Requests': 'Anfragen bearbeiten',
    'Payslip Database': 'Gehaltszettel-Datenbank',
    'Payroll Exports': 'Gehaltsabrechnung Exporte',
    'Successfully uploaded document!': 'Erfolgreich hochgeladenes Dokument!',
    'Please provide all required data': 'Bitte geben Sie alle erforderlichen Daten an',
    'Imported files': 'Importe',
    'File name': 'Dateiname',
    'Drag and drop CSVs or PDFs files here. You can drop directories too.':
      'Ziehen Sie CSV- oder PDF-Dateien hierher und legen Sie sie ab. Sie können auch Verzeichnisse ablegen.',
    Browse: 'Durchsuchen',
    Cancel: 'Abbrechen',
    Reason: 'Grund',
    'Amount (€)': 'Betrag (€)',
    Total: 'Gesamt',
    'BIC/Swift': 'BIC/Swift',
    'UnEmployment Date': 'Anstellungsdatum',
    Unlimited: 'Unbegrenzt',
    'Severly Disabled': 'Schwerbehindert',
    'Vacation Days Taken': 'Ferientage',
    'Vacation Days Remaining': 'Ferientage',
    'Employee Information': 'Angestellte',
    'Employee Documents': 'Dokumente hochladen',
    'Upload Document': 'Dokumente hochladen',
    'Send Credentials': '',
    'Request Information': 'Anforderungsrechnung',
    'There is no content to display for this section': '',
    'Year is invalid. Please fix that and reupload the file.': '',
    'Successfully uploaded employee payslip.': '',
    'No.': 'Nr.',
    'Renumeration Type': 'Abrechnungsart',
    Renumeration: 'Abrechnung',
    'Sick Days': 'Krankheitstage',
    'Vacation Days': 'Ferientage',
    Month: 'Monat',
    'File Upload': 'Dateiupload',
    Payslips: 'Gehaltsabrechnungen',
    Expenses: 'Auslagen',
    'Upload PDF': 'PDF hochladen',
    'Do you need some help?': 'Benötigen Sie Hilfe?',
    'Please let us know how we can help you today?':
      'Bitte teilen Sie uns mit, wie wir Ihnen heute helfen können?',
    'Ongoing Import': 'Laufender Import',
    Import: 'Importe',
    'Upload Dumps': 'Dokumente hochladen',
    'Choose an invoice from right pane to view the preview.':
      'Wählen Sie zur Vorschau eine Rechnung aus.',
    'Show Booking': 'Buchungen anzeigen',
    'Invoices With Attachments': 'Rechnungen mit Anhängen',
    'Download Invoices': 'Rechnungen herunterladen',
    Other: '',
    'Process All Invoices': '',
    'Close Cycle': '',
    'Set All Invoice Types': 'Rechnungsart für alle',
    'Invoice Type': 'Rechnungsart',
    'Send OCR Request': '',
    'View Activities': 'Aktivitäten anzeigen',
    'Invoice Info': 'Rechnungsinfo',
    'Request Customer Changes': 'Änderungen vom Kunden anfordern',
    'Mark As Incomplete': '',
    'Account Payable': 'Ford./Verb.',
    'Account Receivable': 'Ford./Verb.',
    'Mark As Processed': 'Als verarbeitet markieren',
    'Mark As Unprocessed': 'Als offen markieren',
    Attachment: 'Anhang',
    Restore: 'Wiederherstellen',
    'Attachment Activities': 'Anhang Aktivitäten',
    Actor: '',
    Details: 'Datails',
    'Action Time': '',
    'Invoice Activities': 'Rechnungsaktivitäten',
    'View Attachments Activity': '',
    'Invoice Pages Preview': 'Vorschau der Rechnungsseiten',
    'Invoice Number': 'Rechnungsnummer',
    'Copied to clipboard.': '',
    'Click to copy.': '',
    'Invoice Date': 'Rechnungsdatum',
    Transactions: 'Buchungen',
    'Information Missing': '',
    'Information Invalid': '',
    'Due Date (Optional)': '',
    Notes: 'Notizen',
    'This booking is already processed. Are you sure you want to Re-Assign?': '',
    'Re-Assign': '',
    'Bank Name': '',
    Remark: '',
    'Bank Accounts': 'Bankkonten',
    'Card Holder': '',
    'Card Type': '',
    'Credit Cards': '',
    'Daily Balances': 'SuSa',
    'View Bank Transactions': '',
    'Paypal Address': '',
    'Bank Code': '',
    'Default Account Code': '',
    'Finleap Connected Bank Accounts': 'Finleap Connected Bankkonten',
    'Update Connected Account': 'Verbundenes Konto aktualisieren',
    'Add Payment Method': '',
    'Bank Account': 'Bankkonto',
    'Credit Card': '',
    'Paypal Account': 'Paypal-Konto',
    'Paypal Accounts': 'Paypal-Konten',
    'Employer Export': 'Mitarbeiter',
    'Employees Export': 'Angestellte',
    'Download Employer Export': '',
    'Download Employees Export': '',
    'Generate Exports': '',
    'There is not exports generated for this year / month': '',
    'File History': '',
    'Uploaded At': 'PDF hochladen',
    'Document Link': 'Dokumentspeicherort',
    '(Re)Upload File': 'PDF hochladen',
    'Click or just drag&drop a file to this modal': '',
    'Notify Employer': 'Mitarbeiter',
    'Number - Name': '',
    'Must be a valid year.': '',
    'Must be a valid month.': '',
    'Duplicate Invoice': '',
    'To Doc': '',
    'Successfully assigned transactions total to invoice.': '',
    'Add Transactions Total': '',
    'Transactions Total': '',
    'Successfully created reccuring invoices. The grid will now refresh.': '',
    'Number of repetitions': '',
    'Cycle of repetitions': '',
    Monthly: '',
    Quarterly: '',
    'Half-Yearly': '',
    Yearly: '',
    'Invoices to create:': '',
    'Document Pattern': 'Dokumentenmuster',
    'Receipt At': 'Empfangen am',
    'Document Date': 'Dokumentendatum',
    'Accounting Year': 'Buchungsjahr',
    'Accounting Cycle': 'Buchungsperiode',
    'Gross Amount': 'Bruttobetrag',
    'Exchange Rate': 'Wechselkurs',
    'Base Amount': 'Basisbetrag',
    'Document Location': 'Dokumentspeicherort',
    'Approved at': 'Genehmigt am',
    'Processed at': 'Verarbeitet am',
    'P&L': 'GuV',
    'Payment Due (Day)': '',
    'Must be a number.': '',
    'Payment Due (Date)': '',
    'Debit/Credit': '',
    BU: '',
    'Payment Method': '',
    'Paid?': 'Bezahlt?',
    'Search by Serial': 'Seriennummer suchen',
    'Information in the grid has changed. Please update the grid!': '',
    Refresh: '',
    'Send {n} Invoices for OCR': '',
    'Duplicate {n} Invoices': '',
    'Export to CSV': 'CSV Export',
    'Status Changed By': '',
    'Latest Invoice Changes': '',
    'Last Invoice Changes': '',
    'There are no previous changes.': '',
    'Ignore Transaction / Return to grid': '',
    'Make original': '',
    'Delete / Move to doc': '',
    'Add to duplicates': '',
    'Successfully merged duplicate transactions.': '',
    'Merge Duplicate Transactions': 'Zusammenführen doppelter Transaktionen',
    'Original Transaction': 'Ursprüngliche Transaktion',
    'This transaction will be kept and others will be merged into it.': '',
    'Duplicate Transactions': 'Doppelte Transaktionen',
    'This is the list of transactions that will be merged in the original and then moved to document.':
      '',
    'Ignored Transactions': 'Ignorierte Transaktionen',
    'These transactions will be returned to the grid to be processed further.': '',
    'Deleted Transactions': 'Gelöschte Transaktionen',
    'These transactions will be moved to document if their invoice has an attachment. Otherwise they will be deleted.':
      '',
    'Successfully converted invoice to document.': '',
    'Move to Document': 'Zum Dokument wechseln',
    'Document Category': 'Dokumentkategorie',
    From: '',
    To: '',
    Fullname: '',
    'Unemployment Date': 'Anstellungsdatum',
    About: '',
    'File preview': 'Dateivorschau',
    'Expense Type': 'Auslagen',
    'Amount(€)': '',
    'Ex. Rate': '',
    'Already refunded': '',
    'Total(€)': '',
    Receipt: '',
    'Own Car Usages': '',
    'Route From': '',
    'Route To': '',
    'Distance (km)': '',
    EUR: '',
    'Report Total': 'Auswertungen',
    Value: '',
    Units: '',
    'Special Payments': '',
    'Half-Day': '',
    'Processed Requests': 'Anfragen bearbeiten',
    'Unprocessed Requests': 'Anfragen bearbeiten',
    'Other Requests': 'Anfragen bearbeiten',
    'No other requests': 'Anfragen bearbeiten',
    'No Num.': '',
    'Deleted At': '',
    Serial: '',
    Period: '',
    'Invoice File': 'Rechnungsdatei',
    'Open Attachment': '',
    'No Attachment': '',
    'Delete Forever': '',
    'Filter by serial': '',
    'Filter by year': '',
    'Filter by cycle': '',
    'No invoices to show.': '',
    'Add new Customer': 'Neuen Kunden anlegen',
    'Search Customers': 'Kunden suchen',
    'Accounting Notifications': 'Buchhaltung Benachrichtigungen',
    'Employees Notifications': 'Human Ressource Benachrichtigungen',
    Accounting: 'Buchhaltung',
    'Employee Management': 'Mitarbeiterverwaltung',
    Home: 'Home',
    Logout: 'Abmelden',
    'Default Language': 'Standardsprache',
    'Login as user': 'Als Nutzer anmelden',
    Users: 'Benutzer',
    'Gmail Code': '',
    'Assign Contra Account': 'Finleap Connected Bankkonten',
    'Invoice Details': 'Rechnungs-E-Mail',
    'Customer Notification Details': 'Benachrichtigungen',
    'Notify Customer?': 'Mitarbeiter',
    'Message for customer': '',
    'Please pay attention to errors. If you choose to continue, you may corrupt the data.': '',
    'Force Run Import': '',
    'Time Trackings': '',
    'My time trackings': '',
    'Sub Code': '',
    'Customer only accounts must be in the same template.': '',
    'Leave a comment': 'Kommentar hinterlassen',
    Reply: 'Antworten',
    'From Year': 'Von Jahr',
    'From Cycle': 'Von Periode',
    'To Year': 'Bis Jahr',
    'To Cycle': 'Bis Periode',
    'Successfully sent comment.': '',
    'Successfully updated invoice status': '',
    Invoice: 'Rechnung',
    'Feedback Required': 'Feedback erforderlich',
    Upload: 'Hochladen',
    'No Document Pattern Available': 'Kein Dokumentenmuster verfügbar',
    "Customer's Note": '',
    'No notes added.': '',
    "This booking doesn't have any invoices attached to it.": '',
    'Re-Assign Invoice': '',
    'Sign in': '',
    'Email Address': '',
    'Sign In': '',
    'Add Cash Transaction': 'Bargeldzahlung Hinzufügen',
    'Successfully created a new cash book entry.': '',
    Cashbook: 'Kassenbuch',
    'Add Transaction': 'Eintrag hinzufügen',
    Balance: 'Kassenbestand',
    Checked: 'Bestand überprüft',
    'Select Files': '',
    'Select files from your computer or drag and drop them to the area below.': '',
    'Some Files Could Not Be Uploaded': '',
    'We currently only support PDF and CSV files. The following files could not be uploaded': '',
    'Drag & Drop': '',
    'Upload Documents': 'Dokumente hochladen',
    'Remaining Uploads': '',
    'Assign Cost Center': 'Finleap Connected Bankkonten',
    'Assign Cost Unit': 'Finleap Connected Bankkonten',
    'Type here...': '',
    'Account ID': '',
    Owner: '',
    'Account Transactions': '',
    'This transaction is processed and assigned to an invoice.': '',
    'This transaction is not processed yet.': '',
    Complete: '',
    'Snooze for 2 days': '',
    'View more details': '',
    'Add attachment': '',
    'Delete attachment': '',
    'Received on': '',
    'Due Date': '',
    Overdue: 'Überfällig',
    Today: '',
    Info: '',
    'Open Items': '',
    'Open Receivables': 'Ford./Verb.',
    'Open Payables': 'Ford./Verb.',
    Debtor: '',
    Creditor: '',
    'View Invoice': 'Vorschau der Rechnung',
    Pay: 'Kann',
    Sum: '',
    'You will be redirected to the payment provider now to complete the payment.': '',
    'Make a Payment': '',
    'Your Accounts': 'Bankkonten',
    Recipient: '',
    'Recipient Bank Accounts': 'Bankkonten',
    'Recipient IBAN': '',
    'Individual Reports': 'Individuelle Auswertungen',
    View: 'Ansicht',
    Download: 'Herunterladen',
    'Financial Accounting': 'Finanzbuchhaltung',
    Overview: 'Übersicht',
    Inbox: 'Posteingang',
    Reports: 'Auswertungen',
    'Business Assessment': 'BWA',
    Cashflow: 'Cash-Flow',
    'Trial Balance': 'SuSa',
    'Accounts Receivable / Payable': 'Ford./Verb.',
    'Invoice PDFs': 'PDF Belege',
    Management: 'Verwaltung',
    'Vacation Requests': 'Urlaubstage',
    Processing: 'In Bearbeitung',
    'My Storage': 'Meine Ablage',
    Settings: 'Einstellungen',
    Support: 'Unterstützung',
    'Inbox Settings': 'Posteingangs-Einstellungen',
    'Guest User - Limited Accessibility': 'Gastzugang - limitierter Zugriff',
    'Finleap integration successful!': 'Finleap-Integration erfolgreich!',
    'Back to accounts': 'Zurück zu den Konten',
    Notifications: 'Benachrichtigungen',
    'Invoices & Documents': 'Rechnungen und Dokumente',
    Invoices: 'Rechnungen',
    'Update Whitelist Item': '',
    'Create Whitelist Item': '',
    'Manage bank accounts': 'Bankverbindungen verwalten',
    'Add a bank account': 'Bankverbindung hinzufügen',
    'Finleap Integration': 'Finleap Integration',
    'No overdue notifications.': '',
    'Due Today/Overdue': '',
    'No other notifications.': '',
    'Your Inbox Address': '',
    Whitelist: '',
    'Add Whitelist Item': '',
    'There are whitelist items created yet. Press the button to create a new whitelist item.': '',
    German: 'Deutsch',
    English: 'Englisch',
    'User Profile': 'Benutzerprofil',
    'Teamleader Integration': 'Teamleader Integration',
    'Connect with Teamleader': 'Verbindung mit Teamleader herstellen',
    'Disconnect from Teamleader': 'Verbindung mit Teamleader trennen',
    'Re-Connect with Teamleader': 'Erneut verbinden',
    'Not yet connected': 'Noch nicht verbunden',
    'Connected, but timeout. Please reconnect.':
      'Zeitüberschreitung der Verbindung. Bitte erneut verbinden.',
    'Connection established': 'Verbindung aktiv',
    'Outgoing invoice': 'Ausgangsrechnung',
    'Incoming invoice': 'Eingangsrechnung',
    'Outgoing credit': 'Ausgehende Gutschrift',
    'Incoming credit': 'Eingehende Gutschrift',
    'Human Ressource': 'Personalwesen',
    'Create Document Pattern': 'Dokumentenvorlage anlegen',
    'Document List': 'Dokumentenliste',
    'Business Partner': 'Geschäftspartner',
    'Fact pattern': 'Sachverhalt',
    'Document currency': 'Belegwährung',
    Save: 'Speichern',
    'No unprocessed invoices available.': 'Keine unverarbeiteten Rechnungen verfügbar.',
    'Mark as Unprocessed': 'Als unbearbeitet markieren',
    'Update Document Pattern': 'Dokumentenvorlage aktualisieren',
    'VAT %': 'MwSt %',
    'Due date in days': 'Fälligkeit in Tagen',
    'Payment Due Date': 'Fälligkeitsdatum',
    'Issuer (collective partner)': 'Aussteller (Sammelpartner)',
    'Internal ID': 'Interne ID',
    'Create Business Partner': 'Geschäftspartner anlegen',
    'Update Business Partner': 'Geschäftspartner aktualisieren',
    'Collective Creditor/Debtor': 'Sammelkreditor / -debitor',
    'Requested document patterns': 'Angefragte Dokumentenmuster',
    'Industry Key': 'Branchenschlüssel',
    'Update Customer': 'Kunde aktualisieren',
    Client: 'Mandant',
    'Cash reports': 'Kassenberichte',
    'Select nothing': 'Nichts auswählen',
    Clients: 'Mandanten',
    'Booking template': 'Buchungsvorlage',
    'Booking templates': 'Buchungsvorlagen',
    'Chart of accounts': 'Kontenrahmen',
    'Document Packs': 'Dokumentenpakete',
    'Add Document Pack': 'Dokumentenpaket hinzufügen',
    'E-Mail Sender': 'E-Mail Sender',
    'Time Tracking': 'Zeiterfassung',
    'Search Clients': 'Mandanten durchsuchen',
    'New Client': 'Neuer Mandant',
    'Create Client': 'Mandant anlegen',
    'Update Client': 'Mandant anpassen',
    New: 'Neu',
    'Accounting begin': 'Beginn der Buchführung',
    'Accounting begin: {n}': 'Beginn der Buchführung: {n}',
    Activities: 'Aktivitäten',
    Time: 'Zeit',
    Activity: 'Aktivität',
    'Serial Number': 'Seriennummer',
    'Incoming Invoice': 'Eingangsrechnung',
    'Outgoing Invoice': 'Ausgangsrechnung',
    'Create Teamleader Ticket': 'Teamleader Ticket erstellen',
    Subject: 'Betreff',
    'Subject is required.': 'Betreff ist ein Pflichtfeld.',
    'Ticket created successfully': 'Ticket wurde erstellt',
    'Ticket successfully created!': 'Ticket wurde erstellt',
    'Error on creating ticket. Please connect/re-connect with Teamleader.':
      'Fehler beim Erstellen des Tickets. Bitte prüfen Sie Ihre Verbindung mit Teamleader.',
    'To create a ticket, please enter the subject and your description below.':
      'Bitte Betreff und Beschreibung angeben um ein Ticket zu erstellen.',
    'All Documents': 'Alle Dokumente',
    'Missing required parameters:': 'Erforderliche Parameter fehlen:',
    'Client is not yet linked to Teamleader. Teamleader Company ID is missing.':
      'Mandant ist noch nicht mit Teamleader verbunden. Teamleader Company ID fehlt.',
    'Invalid or expired access token. Please check your Teamleader connection.':
      'Ungültiger oder abgelaufener Access Token. Bitte Teamleader Verbindung überprüfen.',
    'Token expired. Please reconnect with Teamleader.':
      'Zeitüberschreitung bei Teamleader Verbindung. Bitte neu verbinden..',
    'Error on creating ticket.': 'Fehler beim Erstellen des Tickets.',
    'Invalid Teamleader customer id.': 'Ungültige Teamleader Company ID für Mandanten gesetzt.',
    Open: 'Offen',
    'Teamleader Tickets': 'Teamleader Tickets',
    'View Ticket': 'Ticket ansehen',
    'No tickets found for this document.': 'Es wurden keine Tickets für dieses Dokument gefunden.',
    'Create Employee': 'Mitarbeiter anlegen',
    Salutation: 'Anrede',
    Mr: 'Herr',
    Ms: 'Frau',
    'House No.': 'Hasunummer',
    'Employee number': 'Mitarbeiternummer',
    'Limited until (leave blank for unlimited)': 'Befristet bis (Leer lassen für unbefristet)',
    'VAT Status': 'MwSt. Pflicht',
    Normal: 'Normal',
    Exempt: 'Befreit',
    'Teamleader Company ID': 'Teamleader Firmen-Id',
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Dezember',
    'Voluntary Statutory Health Insurance': 'Freiwillig gesetzlich Krankenversichert',
    'Update Employee': 'Mitarbeiter aktualisieren',
    Single: 'Ledig',
    Married: 'Verheiratet',
    'Civil Union': 'In eingetragener Lebenspartnerschaft',
    'Permanently Living Apart': 'Getrennt lebend',
    Divorced: 'Geschieden',
    Widowed: 'Verwitwet',
    'Health Insurance Company': 'Krankenkasse',
    'Mx.': 'Divers',
    'Health Insurance': 'Krankenkasse',
  },
  options: {
    plural_rule: 'n != 1',
    plural_number: '2',
  },
  en: {
    'Health Insurance': 'Health Insurance',
    'Mx.': 'Mx.',
    'Health Insurance Company': 'Health Insurance Company',
    Single: 'Ledig',
    Married: 'Verheiratet',
    'Civil Union': 'In eingetragener Lebenspartnerschaft',
    'Permanently Living Apart': 'Getrennt lebend',
    Divorced: 'Geschieden',
    Widowed: 'Verwitwet',
    'Update Employee': 'Update Employee',
    'Voluntary Statutory Health Insurance': 'Voluntary Statutory Health Insurance',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    'Teamleader Company ID': 'Teamleader Company ID',
    Normal: 'Normal',
    Exempt: 'Exempt',
    'VAT Status': 'VAT Status',
    'Limited until (leave blank for unlimited)': 'Limited until (leave blank for unlimited)',
    'Employee number': 'Employee number',
    'House No.': 'House No.',
    'Create Employee': 'Create Employee',
    'Incoming Invoice': 'Incoming Invoice',
    'Outgoing Invoice': 'Outgoing Invoice',
    'Serial Number': 'Serial Number',
    Activity: 'Activity',
    Time: 'Time',
    Activities: 'Activities',
    'Accounting begin: {n}': 'Accounting begin: {n}',
    'Accounting begin': 'Accounting begin',
    New: 'New',
    'Update Client': 'Update Client',
    'Create Client': 'Create Client',
    'New Client': 'New Client',
    'Search Clients': 'Search Clients',
    'Time Tracking': 'Time Tracking',
    'E-Mail Sender': 'E-Mail Sender',
    'Add Document Pack': 'Add Document Pack',
    'Document Packs': 'Document Packs',
    'Incoming invoice': 'Incoming invoice',
    'Outgoing invoice': 'Outgoing invoice',
    'Outgoing credit': 'Outgoing credit',
    'Incoming credit': 'Incoming credit',
    'Create Document Pattern': 'Create Document Pattern',
    'Chart of accounts': 'Chart of accounts',
    'Booking template': 'Booking template',
    'Booking templates': 'Booking templates',
    Clients: 'Clients',
    'Select nothing': 'Select nothing',
    'Cash reports': 'Cash reports',
    Client: 'Client',
    'Human Ressource': 'Human Ressource',
    'Update Customer': 'Update Customer',
    'Industry Key': 'Industry Key',
    'Requested document patterns': 'Requested document patterns',
    'Collective Creditor/Debtor': 'Collective Creditor/Debtor',
    'Update Business Partner': 'Update Business Partner',
    'Create Business Partner': 'Create Business Partner',
    'Internal ID': 'Internal ID',
    'Issuer (collective partner)': 'Issuer (collective partner)',
    'Payment Due Date': 'Payment Due Date',
    'Due date in days': 'Due date in days',
    'VAT %': 'VAT %',
    'Update Document Pattern': 'Update Document Pattern',
    'Mark as Unprocessed': 'Mark as unprocessed',
    'No unprocessed invoices available.': 'No unprocessed invoices available.',
    Save: 'Save',
    'Document currency': 'Document currency',
    'Fact pattern': 'Fact pattern',
    'Business Partner': 'Business Partner',
    'Document List': 'Document List',
    'Cash basis taxpayer': 'Cash basis taxpayer',
    'Accrual basis taxpayer': 'Accrual basis taxpayer',
    Email: 'Email',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Password: 'Password',
    'Password Confirmation': 'Password Confirmation',
    Telephone: 'Telephone',
    Address: 'Address',
    Status: 'Status',
    Active: 'Active',
    Disabled: 'Disabled',
    Role: 'Role',
    Agent: 'Agent',
    Admin: 'Admin',
    Submit: 'Submit',
    Actions: 'Actions',
    Disable: 'Disable',
    Enable: 'Enable',
    Accountants: 'Accountants',
    'Activity Types': 'Activity Types',
    'Edit Activity Type': 'Edit Activity Type',
    Name: 'Name',
    'Price per hour (EUR/h)': 'Price per hour (€/h)',
    Note: 'Note',
    Customer: 'Customer',
    'Activity Name': 'Activity Name',
    Price: 'Price',
    'Related customer': 'Related customer',
    'Activity Type': 'Activity Type',
    'Create Activity Type': 'Create Activity Type',
    ID: 'ID',
    'Transactions #': 'Transactions #',
    'Transactions Sum': 'Transactions Sum',
    'Exportable Transaction Amount': 'Exportable Transaction Amount',
    'Document Number': 'Document Number',
    'Assign Invoice': 'Assign Invoice',
    'Process Transaction': 'Process Transaction',
    'Search by ID or Document Number': 'Search by ID or Document Number',
    'ID / Document Number': 'ID / Document Number',
    'No transactions to show.': 'No transactions to show.',
    Type: 'Type',
    Amount: 'Amount',
    'Booking Text': 'Booking Text',
    Purpose: 'Purpose',
    'Booking Date': 'Booking Date',
    'Value Date': 'Value Date',
    'Processed?': 'Processed?',
    'Invoice ID': 'Invoice ID',
    'Request Changes': 'Request Changes',
    'Request Invoice': 'Request Invoice',
    Filters: 'Filters',
    Unprocessed: 'Unprocessed',
    Processed: 'Processed',
    All: 'All',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    'Export Bank Statement': 'Export Bank Statement',
    'The are no results for this query.': 'The are no results for this query.',
    Code: 'Code',
    Template: 'Template',
    Supplier: 'Supplier',
    'Account Template': 'Account Template',
    'No Template': 'No Template',
    'Supplier Accounts': 'Supplier Accounts',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Bank account number': 'Bank account number',
    'Supplier Banking Accounts': 'Supplier Banking Accounts',
    'This creditor is versioned and can no longer be updated.':
      'This creditor is versioned and can no longer be updated.',
    'View new version': 'View new version',
    'This is a new version of a previous creditor.':
      'This is a new version of a previous creditor.',
    'View old version': 'View old version',
    'Supplier Number': 'Supplier Number',
    Logo: 'Logo',
    Street: 'Street',
    'House Number': 'House Number',
    Country: 'Country',
    City: 'City',
    Zip: 'Zip',
    'Company Information': 'Company Information',
    Website: 'Website',
    HRB: 'HRB',
    'Small Business': 'Small Business',
    'VAT ID': 'VAT ID',
    'Contact Information': 'Contact Information',
    'E-Mail': 'E-Mail',
    Phone: 'Phone',
    'Accounting Information': 'Accounting Information',
    'Credit Account Template SK03': 'Credit Account Template SK03',
    'Credit Account Template SK04': 'Credit Account Template SK04',
    Description: 'Description',
    'Save as a new version': 'Save as a new version',
    Delete: 'Delete',
    Suppliers: 'Suppliers',
    'Record Dump Errors': 'Record Dump Errors',
    'No error on records dump': 'No error on records dump',
    'Account Dump Errors': 'Account Dump Errors',
    'No error on accounts dump': 'No error on accounts dump',
    'Request At': 'Request At',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Forced: 'Forced',
    Errors: 'Errors',
    'Force Import': 'Force Import',
    'Ongoing Imports': 'Ongoing Imports',
    "There's currently no ongoing import": "There's currently no ongoing import",
    'Requested At': 'Requested At',
    'Run Time': 'Run Time',
    'Previous Imports': 'Previous Imports',
    'There are no previous imports.': 'There are no previous imports.',
    'Legal Form': 'Legal Form',
    'BWA Type': 'BWA Type',
    'Tax ID': 'Tax ID',
    'Postal Code': 'Postal Code',
    State: 'State',
    Director: 'Director',
    'Contact Person': 'Contact Person',
    'VAT Declaration': 'VAT Declaration',
    'Agenda Company ID': 'Agenda Company ID',
    'Reports End Date': 'Reports End Date',
    'Invoice Email': 'Invoice Email',
    'Website Domain': 'Website Domain',
    'Tax Number': 'Tax Number',
    "Employer's Liability Insurance Association": "Employer's Liability Insurance Association",
    'Membership Number': 'Membership Number',
    'Classification Number': 'Classification Number',
    'Taxation of Invoices': 'Taxation of Invoices',
    'Vat Declaration Period': 'Vat Declaration Period',
    'Vat Declaration Date': 'Vat Declaration Date',
    'Wage Declaration Period': 'Wage Declaration Period',
    'Wage Declaration Date': 'Wage Declaration Date',
    'Reduction of Fees': 'Reduction of Fees',
    'Regular Working Hours': 'Regular Working Hours',
    'Payment of Wage Tax': 'Payment of Wage Tax',
    'Credit Transfer': 'Credit Transfer',
    'Direct Debit': 'Direct Debit',
    'Social Insurance Payment': 'Social Insurance Payment',
    'Allocation Obligation (U1)': 'Allocation Obligation (U1)',
    Yes: 'Yes',
    No: 'No',
    'Immediate Notification': 'Immediate Notification',
    'Business Number': 'Business Number',
    'Finance Office Registration Number': 'Finance Office Registration Number',
    'Sub Accounts': 'Sub Accounts',
    Company: 'Company',
    'Member Since': 'Member Since',
    Customers: 'Customers',
    Number: 'Number',
    'Created At': 'Created At',
    'Latest Invoices': 'Latest Invoices',
    'Document Patterns': 'Document Patterns',
    Imports: 'Imports',
    'Recycle Bin': 'Recycle Bin',
    'Process Grid': 'Process Grid',
    'Process Page': 'Process Page',
    'Index Invoices': 'Index Invoices',
    'Create Invoice': 'Create Invoice',
    'There is no content for this section.': 'There is no content for this section.',
    'Latest Documents': 'Latest Documents',
    Documents: 'Documents',
    'Create Document': 'Create Document',
    Tracker: 'Tracker',
    'Bank Transactions': 'Bank Transactions',
    'Whitelist Items': 'Whitelist Items',
    'Payment Methods': 'Payment Methods',
    'Manage Users': 'Manage Users',
    'Customer Time Trackings': 'Customer Time Trackings',
    Edit: 'Edit',
    'Customer Time Tracking': 'Customer Time Tracking',
    'Edit Customer Time Tracking': 'Edit Customer Time Tracking',
    'Start Time': 'Start Time',
    'End Time': 'End Time',
    'Time spent': 'Time spent',
    'Activity Type Customer': 'Activity Type Customer',
    Chargeable: 'Chargeable',
    Incomplete: 'Incomplete',
    'Not invoiceable': 'Not invoiceable',
    Invoiceable: 'Invoiceable',
    Invoiced: 'Invoiced',
    'Start time': 'Start time',
    'Stop time': 'Stop time',
    'Sum of time': 'Sum of time',
    User: 'User',
    'Not Invoiceable': 'Not Invoiceable',
    'Total Invoiceable Amount': 'Total Invoiceable Amount',
    'Export Time Tracking': 'Export Time Tracking',
    'Create Customer Time Tracking': 'Create Customer Time Tracking',
    'Invoice Category': 'Invoice Category',
    'Only one category can be set as a category for invoices':
      'Only one category can be set as a category for invoices',
    'Uploadable Node': 'Uploadable Node',
    "If it's possible to upload documents to this node":
      "If it's possible to upload documents to this node",
    Uploadable: 'Uploadable',
    'Updated At': 'Updated At',
    'Document Categories': 'Document Categories',
    Subcategories: 'Subcategories',
    'Number of uploads': 'Number of uploads',
    'Created by': 'Created by',
    'Add Customer DocumentPack': 'Add Customer DocumentPack',
    'Customer Document Packs': 'Customer Document Packs',
    'Remaining documents': 'Remaining documents',
    Kind: 'Kind',
    Issuer: 'Issuer',
    VAT: 'VAT',
    'Banking accounts': 'Banking accounts',
    Currency: 'Currency',
    'Base Currency': 'Base Currency',
    Account: 'Account',
    'Create Account': 'Create Account',
    'Contra Account': 'Contra Account',
    'Create Contra Account': 'Create Contra Account',
    'Cost Center': 'Cost Center',
    'Cost Unit': 'Cost Unit',
    'D/C Flag': 'D/C Flag',
    Debit: 'Debit',
    Credit: 'Credit',
    'Tax Key': 'Tax Key',
    'Eligible?': 'Eligible?',
    Action: 'Action',
    'Assign Pattern': 'Assign Pattern',
    'There are currently no document patterns created.':
      'There are currently no document patterns created.',
    'Invoices Without Available Pattern': 'Invoices Without Available Pattern',
    'The are currently no unprocessed invoices available.':
      'The are currently no unprocessed invoices available.',
    'Preview Invoice': 'Preview Invoice',
    'No Preview Available': 'No Preview Available',
    'Open in a new tab': 'Open in a new tab',
    'Update Document': 'Update Document',
    Category: 'Category',
    Year: 'Year',
    Cycle: 'Cycle',
    'Move to invoices': 'Move to invoices',
    'Notify Customer': 'Notify Customer',
    'File Preview': 'File Preview',
    Previous: 'Previous',
    Next: 'Next',
    Comments: 'Comments',
    'Notify Managers': 'Notify Managers',
    Export: 'Export',
    'Drag and drop Images or PDFs files here. You can drop directories too.':
      'Drag and drop Images or PDFs files here. You can drop directories too.',
    'You can also click to choose files': 'You can also click to choose files',
    'Imported Files': 'Imported Files',
    'Set All Years': 'Set All Years',
    'Set All Cycles': 'Set All Cycles',
    'Set All Categories Category': 'Set All Categories Category',
    Reset: 'Reset',
    'Submit All': 'Submit All',
    'File Name': 'File Name',
    Submitted: 'Submitted',
    Employee: 'Employee',
    'Running Number': 'Running Number',
    Date: 'Date',
    Preview: 'Preview',
    Salutation: 'Salutation',
    Mr: 'Mr',
    Ms: 'Ms',
    Title: 'Title',
    'Name Prefix': 'Name Prefix',
    'Name Suffix': 'Name Suffix',
    'Job Description': 'Job Description',
    Position: 'Position',
    'Date of Birth': 'Date of Birth',
    'Birth Country': 'Birth Country',
    'Birth City': 'Birth City',
    'Marital Status': 'Marital Status',
    Nationality: 'Nationality',
    'Residence City': 'Residence City',
    'Additional Address Information': 'Additional Address Information',
    'BIC/SWIFT': 'BIC/SWIFT',
    'Social Security Number': 'Social Security Number',
    'Retired?': 'Retired?',
    'Retirement Type': 'Retirement Type',
    'Tax Class': 'Tax Class',
    'Employment Date': 'Employment Date',
    'Unemployment Date (leave blank for unlimited)':
      'Unemployment Date (leave blank for unlimited)',
    'Secondary Job': 'Secondary Job',
    'Secondary Job Employment Date': 'Secondary Job Employment Date',
    'Secondary Job Insurance Type': 'Secondary Job Insurance Type',
    'Statutory Health Insurance': 'Statutory Health Insurance',
    'Private Health Insurance': 'Private Health Insurance',
    'Type of Health Insurance': 'Type of Health Insurance',
    'Last statutory Health Insurance': 'Last statutory Health Insurance',
    'Wage Type': 'Wage Type',
    Salary: 'Salary',
    Wage: 'Wage',
    'Weekly Working Hours': 'Weekly Working Hours',
    'Employment Type': 'Employment Type',
    Standard: 'Standard',
    Intern: 'Intern',
    'Part Time': 'Part Time',
    'Mini Job': 'Mini Job',
    'Exemption from pension insurance': 'Exemption from pension insurance',
    'Vacation Days Eligible': 'Vacation Days Eligible',
    'Vacation Days from Last Year': 'Vacation Days from Last Year',
    'Severely Disabled': 'Severely Disabled',
    'Hide Registration Form': 'Hide Registration Form',
    Employees: 'Employees',
    'Process Requests': 'Process Requests',
    'Payslip Database': 'Payslip Database',
    'Payroll Exports': 'Payroll Exports',
    'Successfully uploaded document!': 'Successfully uploaded document!',
    'Please provide all required data': 'Please provide all required data',
    'Imported files': 'Imported files',
    'File name': 'File name',
    'Drag and drop CSVs or PDFs files here. You can drop directories too.':
      'Drag and drop CSVs or PDFs files here. You can drop directories too.',
    Browse: 'Browse',
    Cancel: 'Cancel',
    Reason: 'Reason',
    'Amount (€)': 'Amount (€)',
    Total: 'Total',
    'BIC/Swift': 'BIC/Swift',
    'UnEmployment Date': 'UnEmployment Date',
    Unlimited: 'Unlimited',
    'Severly Disabled': 'Severly Disabled',
    'Vacation Days Taken': 'Vacation Days Taken',
    'Vacation Days Remaining': 'Vacation Days Remaining',
    'Employee Information': 'Employee Information',
    'Employee Documents': 'Employee Documents',
    'Upload Document': 'Upload Document',
    'Send Credentials': 'Send Credentials',
    'Request Information': 'Request Information',
    'There is no content to display for this section':
      'There is no content to display for this section',
    'Year is invalid. Please fix that and reupload the file.':
      'Year is invalid. Please fix that and reupload the file.',
    'Successfully uploaded employee payslip.': 'Successfully uploaded employee payslip.',
    'No.': 'No.',
    'Renumeration Type': 'Renumeration Type',
    Renumeration: 'Renumeration',
    'Sick Days': 'Sick Days',
    'Vacation Days': 'Vacation Days',
    Month: 'Month',
    'File Upload': 'File Upload',
    Payslips: 'Payslips',
    Expenses: 'Expenses',
    'Upload PDF': 'Upload PDF',
    'Do you need some help?': 'Do you need some help?',
    'Please let us know how we can help you today?':
      'Please let us know how we can help you today?',
    'Ongoing Import': 'Ongoing Import',
    Import: 'Import',
    'Upload Dumps': 'Upload Dumps',
    Unassigned: 'Unassigned',
    'Choose an invoice from right pane to view the preview.':
      'Choose an invoice from right pane to view the preview.',
    'Show Booking': 'Show Booking',
    'Invoices With Attachments': 'Invoices With Attachments',
    'Download Invoices': 'Download Invoices',
    Other: 'Other',
    'Process All Invoices': 'Process All Invoices',
    'Close Cycle': 'Close Cycle',
    'Set All Invoice Types': 'Set All Invoice Types',
    'Invoice Type': 'Invoice Type',
    'Send OCR Request': 'Send OCR Request',
    'View Activities': 'View Activities',
    'Invoice Info': 'Invoice Info',
    'Request Customer Changes': 'Request Customer Changes',
    'Mark As Incomplete': 'Mark As Incomplete',
    'Account Payable': 'Account Payable',
    'Account Receivable': 'Account Receivable',
    'Mark As Processed': 'Mark As Processed',
    'Mark As Unprocessed': 'Mark As Unprocessed',
    Attachment: 'Attachment',
    Restore: 'Restore',
    'Attachment Activities': 'Attachment Activities',
    Actor: 'Actor',
    Details: 'Details',
    'Action Time': 'Action Time',
    'Invoice Activities': 'Invoice Activities',
    'View Attachments Activity': 'View Attachments Activity',
    'Invoice Pages Preview': 'Invoice Pages Preview',
    'Invoice Number': 'Invoice Number',
    'Copied to clipboard.': 'Copied to clipboard.',
    'Click to copy.': 'Click to copy.',
    'Invoice Date': 'Invoice Date',
    Transactions: 'Transactions',
    'Account Number': 'Account Number',
    'Account Name': 'Account Name',
    'Information Missing': 'Information Missing',
    'Information Invalid': 'Information Invalid',
    'Due Date (Optional)': 'Due Date (Optional)',
    Notes: 'Notes',
    'This booking is already processed. Are you sure you want to Re-Assign?':
      'This booking is already processed. Are you sure you want to Re-Assign?',
    'Re-Assign': 'Re-Assign',
    'Bank Name': 'Bank Name',
    Remark: 'Remark',
    'Bank Accounts': 'Bank Accounts',
    'Card Holder': 'Card Holder',
    'Card Type': 'Card Type',
    'Credit Cards': 'Credit Cards',
    'Daily Balances': 'Daily Balances',
    'View Bank Transactions': 'View Bank Transactions',
    'Paypal Address': 'Paypal Address',
    'Bank Code': 'Bank Code',
    'Default Account Code': 'Default Account Code',
    'Finleap Connected Bank Accounts': 'Finleap Connected Bank Accounts',
    'Update Connected Account': 'Update Connected Account',
    'Add Payment Method': 'Add Payment Method',
    'Bank Account': 'Bank Account',
    'Credit Card': 'Credit Card',
    'Paypal Account': 'Paypal Account',
    'Paypal Accounts': 'Paypal Accounts',
    'Employer Export': 'Employer Export',
    'Employees Export': 'Employees Export',
    'Download Employer Export': 'Download Employer Export',
    'Download Employees Export': 'Download Employees Export',
    'Generate Exports': 'Generate Exports',
    'There is not exports generated for this year / month':
      'There is not exports generated for this year / month',
    'File History': 'File History',
    'Uploaded At': 'Uploaded At',
    'Document Link': 'Document Link',
    '(Re)Upload File': '(Re)Upload File',
    'Click or just drag&drop a file to this modal': 'Click or just drag&drop a file to this modal',
    'Notify Employer': 'Notify Employer',
    'Number - Name': 'Number - Name',
    'Must be a valid year.': 'Must be a valid year.',
    'Must be a valid month.': 'Must be a valid month.',
    'Duplicate Invoice': 'Duplicate Invoice',
    'To Doc': 'To Doc',
    'Successfully assigned transactions total to invoice.':
      'Successfully assigned transactions total to invoice.',
    'Add Transactions Total': 'Add Transactions Total',
    'Transactions Total': 'Transactions Total',
    'Successfully created reccuring invoices. The grid will now refresh.':
      'Successfully created reccuring invoices. The grid will now refresh.',
    'Number of repetitions': 'Number of repetitions',
    'Cycle of repetitions': 'Cycle of repetitions',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    'Half-Yearly': 'Half-Yearly',
    Yearly: 'Yearly',
    'Invoices to create:': 'Invoices to create:',
    'Document Pattern': 'Document Pattern',
    'Receipt At': 'Receipt At',
    'Document Date': 'Document Date',
    'Accounting Year': 'Accounting Year',
    'Accounting Cycle': 'Accounting Cycle',
    'Perf. Year(Start)': 'Perf. Year(Start)',
    'Perf. Month(Start)': 'Perf. Month(Start)',
    'Perf. Year(End)': 'Perf. Year(End)',
    'Perf. Month(End)': 'Perf. Month(End)',
    'Gross Amount': 'Gross Amount',
    'Exchange Rate': 'Exchange Rate',
    'Base Amount': 'Base Amount',
    'Document Location': 'Document Location',
    'Approved at': 'Approved at',
    'Processed at': 'Processed at',
    'P&L': 'P&L',
    'Payment Due (Day)': 'Payment Due (Day)',
    'Must be a number.': 'Must be a number.',
    'Payment Due (Date)': 'Payment Due (Date)',
    'Debit/Credit': 'Debit/Credit',
    BU: 'BU',
    'Payment Method': 'Payment Method',
    'Paid?': 'Paid?',
    'Search by Serial': 'Search by Serial',
    'Information in the grid has changed. Please update the grid!':
      'Information in the grid has changed. Please update the grid!',
    Refresh: 'Refresh',
    'Send {n} Invoices for OCR': 'Send {n} Invoices for OCR',
    'Duplicate {n} Invoices': 'Duplicate {n} Invoices',
    'Export to CSV': 'Export to CSV',
    'Status Changed By': 'Status Changed By',
    'Latest Invoice Changes': 'Latest Invoice Changes',
    'Last Invoice Changes': 'Last Invoice Changes',
    'There are no previous changes.': 'There are no previous changes.',
    'Ignore Transaction / Return to grid': 'Ignore Transaction / Return to grid',
    'Make original': 'Make original',
    'Delete / Move to doc': 'Delete / Move to doc',
    'Add to duplicates': 'Add to duplicates',
    'Successfully merged duplicate transactions.': 'Successfully merged duplicate transactions.',
    'Merge Duplicate Transactions': 'Merge Duplicate Transactions',
    'Original Transaction': 'Original Transaction',
    'This transaction will be kept and others will be merged into it.':
      'This transaction will be kept and others will be merged into it.',
    'Duplicate Transactions': 'Duplicate Transactions',
    'This is the list of transactions that will be merged in the original and then moved to document.':
      'This is the list of transactions that will be merged in the original and then moved to document.',
    'Ignored Transactions': 'Ignored Transactions',
    'These transactions will be returned to the grid to be processed further.':
      'These transactions will be returned to the grid to be processed further.',
    'Deleted Transactions': 'Deleted Transactions',
    'These transactions will be moved to document if their invoice has an attachment. Otherwise they will be deleted.':
      'These transactions will be moved to document if their invoice has an attachment. Otherwise they will be deleted.',
    'Successfully converted invoice to document.': 'Successfully converted invoice to document.',
    'Move to Document': 'Move to Document',
    'Document Category': 'Document Category',
    From: 'From',
    To: 'To',
    Fullname: 'Fullname',
    'Unemployment Date': 'Unemployment Date',
    About: 'About',
    'File preview': 'File preview',
    'Expense Type': 'Expense Type',
    'Amount(€)': 'Amount(€)',
    'Ex. Rate': 'Ex. Rate',
    'Already refunded': 'Already refunded',
    'Total(€)': 'Total(€)',
    Receipt: 'Receipt',
    'Own Car Usages': 'Own Car Usages',
    'Route From': 'Route From',
    'Route To': 'Route To',
    'Distance (km)': 'Distance (km)',
    EUR: 'EUR',
    'Report Total': 'Report Total',
    Value: 'Value',
    Units: 'Units',
    'Special Payments': 'Special Payments',
    'Half-Day': 'Half-Day',
    'Processed Requests': 'Processed Requests',
    'Unprocessed Requests': 'Unprocessed Requests',
    'Other Requests': 'Other Requests',
    'No other requests': 'No other requests',
    'No Num.': 'No Num.',
    'Deleted At': 'Deleted At',
    Serial: 'Serial',
    Period: 'Period',
    'Invoice File': 'Invoice File',
    'Open Attachment': 'Open Attachment',
    'No Attachment': 'No Attachment',
    'Delete Forever': 'Delete Forever',
    'Filter by serial': 'Filter by serial',
    'Filter by year': 'Filter by year',
    'Filter by cycle': 'Filter by cycle',
    'No invoices to show.': 'No invoices to show.',
    'Add new Customer': 'Add new Customer',
    'Search Customers': 'Search Customers',
    'Accounting Notifications': 'Accounting Notifications',
    'Employees Notifications': 'Employees Notifications',
    Accounting: 'Accounting',
    'Employee Management': 'Employee Management',
    Home: 'Home',
    Logout: 'Logout',
    'Default Language': 'Default Language',
    'Login as user': 'Login as user',
    Users: 'Users',
    'Gmail Code': 'Gmail Code',
    'Assign Contra Account': 'Assign Contra Account',
    'Invoice Details': 'Invoice Details',
    'Customer Notification Details': 'Customer Notification Details',
    'Message for customer': 'Message for customer',
    'Please pay attention to errors. If you choose to continue, you may corrupt the data.':
      'Please pay attention to errors. If you choose to continue, you may corrupt the data.',
    'Force Run Import': 'Force Run Import',
    'Time Trackings': 'Time Trackings',
    'My time trackings': 'My time trackings',
    'Sub Code': 'Sub Code',
    'Customer only accounts must be in the same template.':
      'Customer only accounts must be in the same template.',
    'Leave a comment': 'Leave a comment',
    Reply: 'Reply',
    'From Year': 'From Year',
    'From Cycle': 'From Cycle',
    'To Year': 'To Year',
    'To Cycle': 'To Cycle',
    'Successfully sent comment.': 'Successfully sent comment.',
    'Successfully updated invoice status': 'Successfully updated invoice status',
    Invoice: 'Invoice',
    'Feedback Required': 'Feedback Required',
    Upload: 'Upload',
    'No Document Pattern Available': 'No Document Pattern Available',
    "Customer's Note": "Customer's Note",
    'No notes added.': 'No notes added.',
    "This booking doesn't have any invoices attached to it.":
      "This booking doesn't have any invoices attached to it.",
    'Re-Assign Invoice': 'Re-Assign Invoice',
    'Sign in': 'Sign in',
    'Email Address': 'Email Address',
    'Sign In': 'Sign In',
    'Add Cash Transaction': 'Add Cash Transaction',
    'Successfully created a new cash book entry.': 'Successfully created a new cash book entry.',
    Cashbook: 'Cashbook',
    'Add Transaction': 'Add Transaction',
    Balance: 'Balance',
    Checked: 'Checked',
    'Select Files': 'Select Files',
    'Select files from your computer or drag and drop them to the area below.':
      'Select files from your computer or drag and drop them to the area below.',
    'Some Files Could Not Be Uploaded': 'Some Files Could Not Be Uploaded',
    'We currently only support PDF and CSV files. The following files could not be uploaded':
      'We currently only support PDF and CSV files. The following files could not be uploaded',
    'Drag & Drop': 'Drag & Drop',
    'Upload Documents': 'Upload Documents',
    'Remaining Uploads': 'Remaining Uploads',
    'Assign Cost Center': 'Assign Cost Center',
    'Assign Cost Unit': 'Assign Cost Unit',
    'Type here...': 'Type here...',
    'Account ID': 'Account ID',
    Owner: 'Owner',
    'Account Transactions': 'Account Transactions',
    'This transaction is processed and assigned to an invoice.':
      'This transaction is processed and assigned to an invoice.',
    'This transaction is not processed yet.': 'This transaction is not processed yet.',
    Complete: 'Complete',
    'Snooze for 2 days': 'Snooze for 2 days',
    'View more details': 'View more details',
    'Add attachment': 'Add attachment',
    'Delete attachment': 'Delete attachment',
    'Received on': 'Received on',
    'Due Date': 'Due Date',
    Overdue: 'Overdue',
    Today: 'Today',
    Info: 'Info',
    'Open Items': 'Open Items',
    'Open Receivables': 'Open Receivables',
    'Open Payables': 'Open Payables',
    Debtor: 'Debtor',
    Creditor: 'Creditor',
    'View Invoice': 'View Invoice',
    Pay: 'Pay',
    Sum: 'Sum',
    'You will be redirected to the payment provider now to complete the payment.':
      'You will be redirected to the payment provider now to complete the payment.',
    'Make a Payment': 'Make a Payment',
    'Your Accounts': 'Your Accounts',
    Recipient: 'Recipient',
    'Recipient Bank Accounts': 'Recipient Bank Accounts',
    'Recipient IBAN': 'Recipient IBAN',
    'Individual Reports': 'Individual Reports',
    View: 'View',
    Download: 'Download',
    'Financial Accounting': 'Financial Accounting',
    Overview: 'Overview',
    Inbox: 'Inbox',
    Reports: 'Reports',
    'Business Assessment': 'Business Assessment',
    Cashflow: 'Cashflow',
    'Trial Balance': 'Trial Balance',
    'Accounts Receivable / Payable': 'Accounts Receivable / Payable',
    'Invoice PDFs': 'Invoice PDFs',
    Management: 'Management',
    'Vacation Requests': 'Vacation Requests',
    Processing: 'Processing',
    'My Storage': 'My Storage',
    Settings: 'Settings',
    Support: 'Support',
    'Inbox Settings': 'Inbox Settings',
    'Guest User - Limited Accessibility': 'Guest User - Limited Accessibility',
    'Finleap integration successful!': 'Finleap integration successful!',
    'Back to accounts': 'Back to accounts',
    Notifications: 'Notifications',
    'Invoices & Documents': 'Invoices & Documents',
    Invoices: 'Invoices',
    'Update Whitelist Item': 'Update Whitelist Item',
    'Create Whitelist Item': 'Create Whitelist Item',
    'Manage bank accounts': 'Manage bank accounts',
    'Add a bank account': 'Add a bank account',
    'Finleap Integration': 'Finleap Integration',
    'No overdue notifications.': 'No overdue notifications.',
    'Due Today/Overdue': 'Due Today/Overdue',
    'No other notifications.': 'No other notifications.',
    'Your Inbox Address': 'Your Inbox Address',
    Whitelist: 'Whitelist',
    'Add Whitelist Item': 'Add Whitelist Item',
    'There are whitelist items created yet. Press the button to create a new whitelist item.':
      'There are whitelist items created yet. Press the button to create a new whitelist item.',
    'Taxation Mode': 'Taxation Mode',
    German: 'German',
    English: 'English',
    'User Profile': 'User Profile',
    'Teamleader Integration': 'Teamleader Integration',
    'Connect with Teamleader': 'Connect with Teamleader',
    'Disconnect from Teamleader': 'Disconnect from Teamleader',
    'Re-Connect with Teamleader': 'Re-Connect with Teamleader',
    'Not yet connected': 'Not yet connected',
    'Connected, but timeout. Please reconnect.': 'Connected, but timeout. Please reconnect.',
    'Connection established': 'Connection established',
    'Create Teamleader Ticket': 'Create Teamleader Ticket',
    Subject: 'Subject',
    'Subject is required.': 'Subject is required.',
    'Error on creating ticket. Please connect/re-connect with Teamleader.':
      'Error on creating ticket. Please connect/re-connect with Teamleader.',
    'Ticket created successfully': 'Ticket created successfully',
    'Ticket successfully created!': 'Ticket successfully created!',
    'To create a ticket, please enter the subject and your description below.':
      'To create a ticket, please enter the subject and your description below.',
    'Missing required parameters:': 'Missing required parameters:',
    'Client is not yet linked to Teamleader. Teamleader Company ID is missing.':
      'Client is not yet linked to Teamleader. Teamleader Company ID is missing.',
    'Invalid or expired access token. Please check your Teamleader connection.':
      'Invalid or expired access token. Please check your Teamleader connection.',
    'Token expired. Please reconnect with Teamleader.':
      'Token expired. Please reconnect with Teamleader.',
    'Error on creating ticket.': 'Error on creating ticket.',
    'Invalid Teamleader customer id.': 'Invalid Teamleader Company  ID set for client.',
    Open: 'Open',
    'Teamleader Tickets': 'Teamleader Tickets',
    'View Ticket': 'View Ticket',
    'No tickets found for this document.': 'No tickets found for this document.',
  },
};
