import omit from 'lodash/omit';
import {
  ADD_CREDITOR_BANKING_ACCOUNTS,
  UPDATE_CREDITOR_BANKING_ACCOUNTS,
  FETCH_CREDITOR_BANKING_ACCOUNTS,
  REMOVE_CREDITOR_BANKING_ACCOUNTS,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  byCreditor: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CREDITOR_BANKING_ACCOUNTS:
    case UPDATE_CREDITOR_BANKING_ACCOUNTS: {
      return {
        ...state,
        byCreditor: {
          ...state.byCreditor,
          [action.payload.creditor_id]: {
            ...state.byCreditor[action.payload.creditor_id],
            [action.payload.id]: {
              ...state.byCreditor[action.payload.creditor_id][action.payload.id],
              ...action.payload,
            },
          },
        },
      };
    }

    case FETCH_CREDITOR_BANKING_ACCOUNTS: {
      return {
        ...state,
        byCreditor: {
          ...state.byCreditor,
          [action.payload.creditorId]: arrayToHashMap(action.payload.data),
        },
      };
    }

    case REMOVE_CREDITOR_BANKING_ACCOUNTS: {
      return {
        ...state,
        byCreditor: {
          ...state.byCreditor,
          [action.payload.creditorId]: omit(state.byCreditor[action.payload.creditorId], [
            action.payload.id,
          ]),
        },
      };
    }

    default:
      return state;
  }
}
