import omit from 'lodash/omit';
import {
  ADD_DOCUMENT_PACKS,
  FETCH_DOCUMENT_PACKS,
  REMOVE_DOCUMENT_PACKS,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  byCustomer: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_DOCUMENT_PACKS: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customer_id]: {
            ...state.byCustomer[action.payload.customer_id],
            [action.payload.id]: {
              ...state.byCustomer[action.payload.customer_id][action.payload.id],
              ...action.payload,
            },
          },
        },
      };
    }

    case FETCH_DOCUMENT_PACKS: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customerId]: arrayToHashMap(action.payload.data),
        },
      };
    }

    case REMOVE_DOCUMENT_PACKS: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customerId]: omit(state.byCustomer[action.payload.customerId], [
            action.payload.id,
          ]),
        },
      };
    }

    default:
      return state;
  }
}
