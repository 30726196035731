import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleDataTable from 'common/components/SimpleDataTable/';
import { usePagination } from 'common/hooks/apiActions';

const SimpleDataTableContainer = ({
  endpoint,
  query,
  forceRerender,
  perPageOptions,
  defaultPerPage,
  ...rest
}) => {
  const { data, fetchData, pagination } = usePagination({
    endpoint,
    defaultPerPage,
    preload: false,
  });

  useEffect(() => {
    fetchData(query);
  }, [endpoint, pagination.page, pagination.perPage, query, forceRerender, fetchData]);

  return <SimpleDataTable {...rest} data={data} pagination={{ ...pagination, perPageOptions }} />;
};

SimpleDataTableContainer.propTypes = {
  endpoint: PropTypes.string.isRequired,
  query: PropTypes.object,
  forceRerender: PropTypes.bool,
  perPageOptions: PropTypes.array,
  defaultPerPage: PropTypes.number,
};

SimpleDataTableContainer.defaultProps = {
  query: {},
  forceRerender: false,
  perPageOptions: [10, 25, 50],
  defaultPerPage: 10,
};

export default SimpleDataTableContainer;
