import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';

const Pagination = ({
  classes,
  perPageOptions,
  totalCount,
  perPage,
  setPerPage,
  page,
  setPage,
  style,
}) => {
  const handlePageChange = page => {
    setPage(page);
  };

  const handlePerPageChange = e => {
    setPerPage(+e.target.value);
    setPage(1);
  };

  return (
    <TablePagination
      classes={classes}
      rowsPerPageOptions={perPageOptions}
      component="div"
      count={totalCount}
      rowsPerPage={perPage}
      page={page - 1}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={(event, page) => {
        handlePageChange(page + 1);
      }}
      onChangeRowsPerPage={handlePerPageChange}
      labelDisplayedRows={({ from, to, count }) => `Page ${page}, ${from}-${to} of ${count}`}
      style={style}
    />
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  perPageOptions: PropTypes.array,
  classes: PropTypes.object,
  style: PropTypes.object,
};

Pagination.defaultProps = {
  perPageOptions: [10, 25, 50],
  classes: {},
  style: {},
};

export default Pagination;
