import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { ViewButton, CompleteButton, SnoozeButton } from './Buttons';

const NotificationsTable = ({ notifications, handleUpdate }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align="left">Type</TableCell>
        <TableCell align="left">Info</TableCell>
        <TableCell align="right">Due Date</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {notifications.map(n => (
        <TableRow key={n.id} style={{ height: 60 }}>
          <TableCell component="th" scope="row">
            <Tooltip title={n.avatar_tooltip} placement="top">
              <Avatar>{n.avatar_letter}</Avatar>
            </Tooltip>
          </TableCell>

          <TableCell align="left" dangerouslySetInnerHTML={{ __html: n.description }} />

          <TableCell align="right">
            {n.overdue && <span style={{ color: 'red' }}>Overdue</span>}
            {n.today && <span style={{ color: 'red' }}>Today</span>}
            {n.due_date && moment(n.due_date).format('Do MMMM YYYY')}
            {!n.due_date && '----'}
          </TableCell>

          <TableCell align="right">
            <ViewButton path={n.action_path} />
            <CompleteButton notification={n} handleUpdate={handleUpdate} />
            <SnoozeButton notification={n} handleUpdate={handleUpdate} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

NotificationsTable.propTypes = {
  notifications: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default NotificationsTable;
