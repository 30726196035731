import { useState } from 'react';
import PropTypes from 'prop-types';

const DropdownMenu = ({ children, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => {
    setOpen(prevState => !prevState);
  };

  return children(handleClick, open);
};

DropdownMenu.propTypes = {
  children: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  defaultOpen: false,
};

export default DropdownMenu;
