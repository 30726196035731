export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const OPEN_HELP_MODAL = 'OPEN_HELP_MODAL';
export const CLOSE_HELP_MODAL = 'CLOSE_HELP_MODAL';
export const OPEN_MODAL_FORM = 'OPEN_MODAL_FORM';
export const CLOSE_MODAL_FORM = 'CLOSE_MODAL_FORM';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_BWA_TYPES = 'FETCH_BWA_TYPES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_INDUSTRY_KEYS = 'FETCH_INDUSTRY_KEYS';
export const FETCH_LEGAL_FORM_ENTRIES = 'FETCH_LEGAL_FORM_ENTRIES';
export const SET_GRID_ACCOUNT_DATA = 'SET_GRID_ACCOUNT_DATA';
export const RESET_GRID_ACCOUNT_DATA = 'RESET_GRID_ACCOUNT_DATA';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const FETCH_INVOICE = 'FETCH_INVOICE';
export const REMOVE_INVOICE = 'REMOVE_INVOICE';

export const ADD_DOCUMENT_CATEGORY = 'ADD_DOCUMENT_CATEGORY';
export const UPDATE_DOCUMENT_CATEGORY = 'UPDATE_DOCUMENT_CATEGORY';
export const FETCH_DOCUMENT_CATEGORIES = 'FETCH_DOCUMENT_CATEGORIES';
export const REMOVE_DOCUMENT_CATEGORY = 'REMOVE_DOCUMENT_CATEGORY';

export const ADD_ACCOUNTANT = 'ADD_ACCOUNTANT';
export const UPDATE_ACCOUNTANT = 'UPDATE_ACCOUNTANT';
export const FETCH_ACCOUNTANTS = 'FETCH_ACCOUNTANTS';
export const REMOVE_ACCOUNTANT = 'REMOVE_ACCOUNTANT';

export const ADD_CREDITOR = 'ADD_CREDITOR';
export const UPDATE_CREDITOR = 'UPDATE_CREDITOR';
export const FETCH_CREDITORS = 'FETCH_CREDITORS';
export const REMOVE_CREDITOR = 'REMOVE_CREDITOR';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_SIDEBAR_CUSTOMERS = 'FETCH_SIDEBAR_CUSTOMERS';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const REMOVE_FROM_SIDEBAR = 'REMOVE_FROM_SIDEBAR';
export const ADD_TO_SIDEBAR = 'ADD_TO_SIDEBAR';

export const ADD_USER = 'ADD_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';

export const ADD_WHITELIST_ITEM = 'ADD_WHITELIST_ITEM';
export const FETCH_WHITELIST_ITEMS = 'FETCH_WHITELIST_ITEMS';
export const UPDATE_WHITELIST_ITEM = 'UPDATE_WHITELIST_ITEM';
export const REMOVE_WHITELIST_ITEM = 'REMOVE_WHITELIST_ITEM';

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';

export const ADD_DOCUMENT_PATTERN = 'ADD_DOCUMENT_PATTERN';
export const UPDATE_DOCUMENT_PATTERN = 'UPDATE_DOCUMENT_PATTERN';
export const REMOVE_DOCUMENT_PATTERN = 'REMOVE_DOCUMENT_PATTERN';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';

export const SET_POPUP_WINDOW_OPEN = 'SET_POPUP_WINDOW_OPEN';
export const SET_POPUP_WINDOW_CLOSE = 'SET_POPUP_WINDOW_CLOSE';

export const ADD_CREDITOR_BANKING_ACCOUNTS = 'ADD_CREDITOR_BANKING_ACCOUNTS';
export const FETCH_CREDITOR_BANKING_ACCOUNTS = 'FETCH_CREDITOR_BANKING_ACCOUNTS';
export const UPDATE_CREDITOR_BANKING_ACCOUNTS = 'UPDATE_CREDITOR_BANKING_ACCOUNTS';
export const REMOVE_CREDITOR_BANKING_ACCOUNTS = 'REMOVE_CREDITOR_BANKING_ACCOUNTS';

export const ADD_ACCOUNTS = 'ADD_ACCOUNTS';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const REMOVE_ACCOUNTS = 'REMOVE_ACCOUNTS';

export const ADD_DOCUMENT_PACKS = 'ADD_DOCUMENT_PACKS';
export const FETCH_DOCUMENT_PACKS = 'FETCH_DOCUMENT_PACKS';
export const REMOVE_DOCUMENT_PACKS = 'REMOVE_DOCUMENT_PACKS';

export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES';
export const CREATE_ACTIVITY_TYPE = 'CREATE_ACTIVITY_TYPE';
export const UPDATE_ACTIVITY_TYPE = 'UPDATE_ACTIVITY_TYPE';
export const REMOVE_ACTIVITY_TYPE = 'REMOVE_ACTIVITY_TYPE';
export const FETCH_ACTIVITY_TYPE = 'FETCH_ACTIVITY_TYPE';

export const FETCH_CUSTOMER_TIME_TRACKINGS = 'FETCH_CUSTOMER_TIME_TRACKINGS';
export const FETCH_CUSTOMER_TIME_TRACKING = 'FETCH_CUSTOMER_TIME_TRACKING';
export const CREATE_CUSTOMER_TIME_TRACKING = 'CREATE_CUSTOMER_TIME_TRACKING';
export const UPDATE_CUSTOMER_TIME_TRACKING = 'UPDATE_CUSTOMER_TIME_TRACKING';
export const REMOVE_CUSTOMER_TIME_TRACKING = 'REMOVE_CUSTOMER_TIME_TRACKING';

export const FETCH_CUSTOMER_EMPOYEES_WITH_REQUEST = 'FETCH_CUSTOMER_EMPOYEES_WITH_REQUEST';
export const FETCH_CUSTOMER_EMPOYEES_WITH_CURRENT_YEAR_CLEARANCE =
  'FETCH_CUSTOMER_EMPOYEES_WITH_CURRENT_YEAR_CLEARANCE';
export const FETCH_CUSTOMER_EMPLOYEE_REQUESTS = 'FETCH_CUSTOMER_EMPLOYEE_REQUESTS';
export const FETCH_CUSTOMER_EMPLOYEE_OTHER_REQUESTS = 'FETCH_CUSTOMER_EMPLOYEE_OTHER_REQUESTS';
export const PREVIEW_CUSTOMER_EMPLOYEE_REQUEST = 'PREVIEW_CUSTOMER_EMPLOYEE_REQUEST';
export const RESET_CUSTOMER_EMPLOYEES = 'RESET_CUSTOMER_EMPLOYEES';
export const TOGGLE_CUSTOMER_EMPLOYEE_STATUS_REQUEST = 'TOGGLE_CUSTOMER_EMPLOYEE_STATUS_REQUEST';
