import omit from 'lodash/omit';
import get from 'lodash/get';
import {
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  FETCH_DOCUMENTS,
  REMOVE_DOCUMENT,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  byCustomer: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_DOCUMENT:
    case UPDATE_DOCUMENT: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customer_id]: {
            ...state.byCustomer[action.payload.customer_id],
            [action.payload.id]: {
              ...get(state, ['byCustomer', action.payload.customer_id, action.payload.id], {}),
              ...action.payload,
            },
          },
        },
      };
    }

    case FETCH_DOCUMENTS: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customerId]: arrayToHashMap(action.payload.data),
        },
      };
    }

    case REMOVE_DOCUMENT: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customerId]: omit(state.byCustomer[action.payload.customerId], [
            action.payload.id,
          ]),
        },
      };
    }

    default:
      return state;
  }
}
