import { combineReducers } from 'redux';
import { i18nState } from 'redux-i18n';
import utils from './utils';
import auth from './auth';
import notifications from './notifications';
import invoices from './invoices';
import documentCategories from './documentCategories';
import accountants from './accountants';
import customers from './customers';
import creditors from './creditors';
import users from './users';
import paymentMethods from './paymentMethods';
import whitelistItems from './whitelistItems';
import documents from './documents';
import documentPatterns from './documentPatterns';
import employees from './employees';
import creditorBankingAccounts from './creditorBankingAccounts';
import accounts from './accounts';
import documentPacks from './documentPacks';
import activityTypes from './activityTypes';
import customerTimeTrackings from './customerTimeTrackings';
import customerEmployees from './customerEmployees';

const createRootReducer = combineReducers({
  auth,
  utils,
  i18nState,
  notifications,
  invoices,
  documentCategories,
  accountants,
  customers,
  creditors,
  users,
  paymentMethods,
  whitelistItems,
  documents,
  documentPatterns,
  employees,
  creditorBankingAccounts,
  accounts,
  documentPacks,
  activityTypes,
  customerTimeTrackings,
  customerEmployees,
});

export default createRootReducer;
