import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import axios from 'common/utils/axios';

export const DEFAULT_STATE = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
};

export const useApiAction = ({ defaultData = null, apiActionFn, preload = false }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ ...DEFAULT_STATE, data: defaultData });

  const action = useCallback(
    (...args) => {
      setState(prevState => ({ ...prevState, loading: true }));
      return apiActionFn(...args)(dispatch)
        .then(data => {
          setState(prevState => ({
            ...prevState,
            loading: false,
            loaded: true,
            error: null,
            data,
          }));
        })
        .catch(error => {
          setState(prevState => ({
            ...prevState,
            loading: false,
            loaded: false,
            error,
            data: defaultData,
          }));
        });
    },
    [apiActionFn, defaultData, dispatch]
  );

  useEffect(() => {
    if (preload && !state.loading && !state.error && !state.loaded) {
      action();
    }
  }, [action, preload, state.error, state.loaded, state.loading]);

  return [state, action];
};

export const usePagination = ({
  endpoint,
  defaultQuery = {},
  preload = true,
  defaultPerPage = 25,
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState(defaultQuery);
  const [loading, setLoading] = useState(!!preload);

  const pageCount = Math.ceil(totalCount / perPage);

  const fetchData = useCallback(
    (overrideQuery = {}) => {
      setLoading(true);

      const queryString = qs.stringify(
        {
          ...query,
          ...overrideQuery,
          page,
          per_page: perPage,
        },
        { arrayFormat: 'brackets' }
      );

      return axios
        .get(`${endpoint}?${queryString}`)
        .then(({ data, headers, status }) => {
          setData(status === 204 ? [] : data);
          setTotalCount(+headers['pagination-total-count']);

          setLoading(false);

          return data;
        })
        .catch(() => {
          setLoading(false);
          setData([]);
        });
    },
    [endpoint, page, perPage, query]
  );

  useEffect(() => {
    if (preload) {
      fetchData();
    }
  }, [query, page, perPage, preload, fetchData]);

  return {
    data,
    setData,
    fetchData,
    query,
    setQuery,
    loading,
    pagination: {
      page,
      setPage,
      perPage,
      setPerPage,
      totalCount,
      setTotalCount,
      pageCount,
    },
  };
};
