import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import { updateResource, fetchResources } from 'common/actions/api';
import { fetchInvoice } from 'common/actions/invoices';
import Modal from 'common/components/Modal';
import InvoicesTable from 'users/components/inbox/InvoicesTable';
import AddDocumentForm from 'users/components/inbox/AddDocumentForm';
import DocumentsTableContainer from 'users/containers/inbox/DocumentsTable';

class InvoicesPageContainer extends Component {
  static propTypes = {
    updateResource: PropTypes.func.isRequired,
    fetchResources: PropTypes.func.isRequired,
    fetchInvoice: PropTypes.func.isRequired,
  };

  state = {
    notifications: [],
    open: false,
    invoiceId: null,
    customerId: null,
  };

  componentDidMount() {
    this.props
      .fetchResources({
        type: 'notifications',
        query: { type: 'invoice' },
      })
      .then(notifications => {
        this.setState({ notifications });
      });
  }

  updateNotification = (notification, action = 'complete') => {
    const { updateResource } = this.props;
    const { id } = notification;

    return updateResource({
      endpointPath: `notifications/${id}/${action}`,
      successMessage: `Successfully ${action}d invoice.`,
    }).then(() => {
      this.setState(prevState => ({
        notifications: prevState.notifications.filter(n => n.id !== id),
      }));
    });
  };

  updateAttachment = (values, { setErrors, setSubmitting }) => {
    const { updateResource, fetchInvoice } = this.props;
    const { invoiceId, customerId } = this.state;

    const formData = new FormData(); // eslint-disable-line no-undef
    formData.set('invoice[notes]', values.notes);
    formData.set('invoice[cost_center]', values.cost_center);
    formData.set('invoice[attachments_attributes][0][attachment]', values.file);

    updateResource({
      endpointPath: `customers/${customerId}/invoices/${invoiceId}`,
      type: 'attachments',
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
      data: formData,
    })
      .then(() => {
        fetchInvoice({ customerId, invoiceId });

        setSubmitting(false);
        this.closeModal();
      })
      .catch(errors => {
        setSubmitting(false);
        setErrors(errors);
      });
  };

  openModal = (invoiceId, customerId) => {
    this.setState({ open: true, invoiceId, customerId });
  };

  closeModal = () => {
    this.setState({ open: false, invoiceId: null, customerId: null });
  };

  render() {
    const { open, notifications } = this.state;
    const { t } = this.context;

    return (
      <React.Fragment>
        <section className="cnt notifications-page">
          <Paper className="root-paper">
            <InvoicesTable
              title={t('Invoices')}
              notifications={notifications}
              handleUpdate={this.updateNotification}
              openModal={this.openModal}
            />

            <DocumentsTableContainer />
          </Paper>
        </section>

        <Modal open={open} title="Add document" closeModal={this.closeModal}>
          <AddDocumentForm type="Invoice" updateAttachment={this.updateAttachment} />
        </Modal>
      </React.Fragment>
    );
  }
}

InvoicesPageContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(undefined, {
  updateResource,
  fetchResources,
  fetchInvoice,
})(InvoicesPageContainer);
