import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InvoicesTable from './InvoicesTable';
import PaymentModal from './PaymentModal';

const OpenItems = (props, { t }) => {
  const [kind, setKind] = useState('ap');
  const [year, setYear] = useState(2020);

  return (
    <section className="cnt">
      <header>
        <h1>{t('Open Items')}</h1>
      </header>

      <div className="content">
        <div className="heading clr">
          <ul className="links left clr">
            <li className={kind === 'ar' ? 'current' : undefined}>
              {/* eslint-disable-next-line no-script-url, jsx-a11y/anchor-is-valid */}
              <a href="javascript:void(0)" onClick={() => setKind('ar')}>
                {t('Open Receivables')}
              </a>
            </li>

            <li className={kind === 'ap' ? 'current' : undefined}>
              {/* eslint-disable-next-line no-script-url, jsx-a11y/anchor-is-valid */}
              <a href="javascript:void(0)" onClick={() => setKind('ap')}>
                {t('Open Payables')}
              </a>
            </li>
          </ul>

          <div className="sort">
            <div className="right">
              <FormControl variant="outlined" size="small">
                <InputLabel id="year">{t('Year')}</InputLabel>
                <Select
                  labelId="year"
                  id="year-outlined"
                  value={year}
                  onChange={e => setYear(e.target.value)}
                  label="Year"
                >
                  <MenuItem value={2020}>2020</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2017}>2017</MenuItem>
                  <MenuItem value={2016}>2016</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div>{kind === 'ar' && <InvoicesTable kind={kind} year={year} />}</div>
        <div>{kind === 'ap' && <InvoicesTable kind={kind} year={year} />}</div>
      </div>

      <PaymentModal />
    </section>
  );
};

OpenItems.propTypes = {};

OpenItems.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OpenItems;
