import {FETCH_INVOICE, REMOVE_DOCUMENT_PATTERN, REMOVE_INVOICE} from '../constants/types';
import { fetchResource, updateResource, confirmationRequest, deleteResource } from './api';
import { closeModalForm } from 'common/actions/utils';
import history from 'common/store/history';

export const fetchInvoice = ({ customerId, invoiceId, ...rest }) => dispatch =>
  fetchResource({
    version: '/api/v2',
    endpointPath: `customers/${customerId}/invoices/${invoiceId}`,
    ...rest,
  })(dispatch).then(invoice => {
    dispatch({
      type: FETCH_INVOICE,
      payload: invoice,
    });

    return invoice;
  });

export const downloadInvoices = ({ customerId, query }) => dispatch => {
  fetchResource({
    version: '/api/v2',
    endpointPath: `customers/${customerId}/invoices/batch_request`,
    query,
  })(dispatch).then(data => {
    dispatch(closeModalForm());
    // eslint-disable-next-line no-undef
    window.open(`/api/v1/customers/${customerId}/invoices/batch_download?file_name=${data.path}`);
  });
};

export const reassignInvoice = ({ customerId, invoiceId, values, setSubmitting }) => dispatch =>
  updateResource({
    version: '/api/v2',
    method: 'PUT',
    endpointPath: `customers/${customerId}/invoices/${invoiceId}/assign_invoice`,
    data: values,
    successMessage: 'Successfully assigned code to invoice.',
    setSubmitting,
  })(dispatch).then(() => {
    dispatch(closeModalForm());
  });

export const processCycle = ({ customerId, group }) => dispatch =>
  confirmationRequest({
    version: '/api/v2',
    method: 'POST',
    url: `customers/${customerId}/invoices/process_cycle`,
    data: group,
    successMessage: 'Successfully marked cycle for processing.',
    errorMessage: "Can't process currently selected cycle.",
    title: 'This action may take a few moments. Are you sure?',
  })(dispatch);

export const closeCycle = ({ customerId, id }) => dispatch =>
  confirmationRequest({
    version: '/api/v2',
    method: 'POST',
    url: `customers/${customerId}/invoice_activities/${id}/close`,
    successMessage: 'Successfully closed the cycle.',
    errorMessage: "Can't process currently selected cycle.",
    title: "You can't reopen a cycle once closed. Are you sure?",
  })(dispatch);

export const fetchInvoiceActivities = ({ customerId, invoiceId }) => dispatch =>
  fetchResource({
    version: '/api/v2',
    endpointPath: `customers/${customerId}/invoices/${invoiceId}/activities`,
  })(dispatch);

export const updateInvoiceImage = ({ customerId, invoiceId, attachment }) => dispatch => {
  const data = new FormData(); // eslint-disable-line no-undef
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  data.set('invoice[attachments_attributes][0][attachment]', attachment);

  return updateResource({
    version: '/api/v2',
    type: 'invoices',
    endpointPath: `customers/${customerId}/invoices/${invoiceId}`,
    data,
    config,
  })(dispatch).then(invoice => {
    dispatch({ type: FETCH_INVOICE, payload: invoice });
    return invoice;
  });
};

export const updateInvoiceStatus = ({
  customerId,
  invoiceId,
  status,
  successMessage,
}) => dispatch =>
  updateResource({
    version: '/api/v2',
    method: 'PUT',
    data: { status, create_transaction: false },
    endpointPath: `customers/${customerId}/invoices/${invoiceId}/update_status`,
    successMessage,
  })(dispatch).then(invoice => {
    dispatch({ type: FETCH_INVOICE, payload: invoice });
    return invoice;
  });

export const assignInvoice = ({ customerId, invoiceId, kind }) => dispatch =>
  updateResource({
    version: '/api/v2',
    method: 'PUT',
    data: { kind },
    endpointPath: `customers/${customerId}/invoices/${invoiceId}/assign_invoice`,
    successMessage: 'Successfully assigned code to invoice.',
  })(dispatch).then(invoice => {
    dispatch({ type: FETCH_INVOICE, payload: invoice });
  });

export const notifyCustomer = ({ customerId, invoiceId, data, actions }) => dispatch =>
  updateResource({
    version: '/api/v2',
    method: 'POST',
    data,
    endpointPath: `customers/${customerId}/invoices/${invoiceId}/notify_customer`,
    successMessage: 'Successfully created a notification for the customer.',
    ...actions,
  })(dispatch).then(invoice => {
    dispatch(closeModalForm());
    dispatch({ type: FETCH_INVOICE, payload: invoice });
    return invoice;
  });

export const deleteInvoice = ({ customerId, invoiceId }) => dispatch =>
  deleteResource({
    version: '/api/v2',
    type: 'invoices',
    endpointPath: `customers/${customerId}/invoices/${invoiceId}`,
  })(dispatch).then(() => {
    dispatch({ type: REMOVE_INVOICE, payload: invoiceId });
    history.push(`/react_admins/customers/${customerId}/invoices`);
  });
