import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthorizationGateContainer from 'common/containers/AuthorizationGate';
import NotificationsPageContainer from './NotificationsPage';
import InvoicesPageContainer from './InvoicesPage';

class InboxContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { tab: props.location.pathname === '/users/inbox' ? 0 : 1 };
  }

  handleChange = (e, tab) => this.setState({ tab });

  render() {
    const { tab } = this.state;
    const { t } = this.context;

    return (
      <AuthorizationGateContainer roles="manager" feature="inbox" redirect>
        <AppBar
          color="primary"
          position="static"
          style={{ marginBottom: 50, backgroundColor: '#fff', color: '#000' }}
        >
          <Tabs value={tab} onChange={this.handleChange} variant="fullWidth">
            <Tab
              component={Link}
              value={0}
              to="/users/inbox"
              icon={<FontAwesomeIcon icon="bell" size="lg" />}
              label={t('Notifications')}
            />
            <Tab
              component={Link}
              value={1}
              to="/users/inbox/invoices"
              icon={<FontAwesomeIcon icon="receipt" size="lg" />}
              label={t('Invoices & Documents')}
            />
          </Tabs>
        </AppBar>

        <Switch>
          <Route exact path="/users/inbox" component={NotificationsPageContainer} />
          <Route exact path="/users/inbox/invoices" component={InvoicesPageContainer} />
        </Switch>
      </AuthorizationGateContainer>
    );
  }
}

export default InboxContainer;
