import React from 'react';
import { connect } from 'react-redux';
import Sidebar from 'users/components/Sidebar';
import { handleSignOut } from 'common/actions/auth';

const SidebarContainer = props => <Sidebar {...props} />;

const mapStateToProps = ({ auth: { currentUser, isSignedIn } }) => ({
  currentUser,
  isSignedIn,
});

export default connect(mapStateToProps, {
  signOut: handleSignOut,
})(SidebarContainer);
