import {
  SHOW_LOADER,
  HIDE_LOADER,
  OPEN_HELP_MODAL,
  CLOSE_HELP_MODAL,
  OPEN_MODAL_FORM,
  CLOSE_MODAL_FORM,
  FETCH_COUNTRIES,
  FETCH_INDUSTRY_KEYS,
  FETCH_LEGAL_FORM_ENTRIES,
  FETCH_BWA_TYPES,
  FETCH_TEMPLATES,
  SET_GRID_ACCOUNT_DATA,
  RESET_GRID_ACCOUNT_DATA,
} from '../constants/types';
import { fetchResources } from './api';

export const showLoader = () => ({
  type: SHOW_LOADER,
});

export const hideLoader = () => ({
  type: HIDE_LOADER,
});

export const openHelpModal = () => ({
  type: OPEN_HELP_MODAL,
});

export const closeHelpModal = () => ({
  type: CLOSE_HELP_MODAL,
});

export const openModalForm = (data = {}) => ({
  type: OPEN_MODAL_FORM,
  payload: data,
});

export const closeModalForm = () => ({
  type: CLOSE_MODAL_FORM,
});

export const setGridAccountData = data => ({
  type: SET_GRID_ACCOUNT_DATA,
  payload: data,
});

export const resetGridAccountData = () => ({
  type: RESET_GRID_ACCOUNT_DATA,
});

export const fetchTemplates = () => (dispatch, getState) => {
  const {
    utils: { templates },
  } = getState();

  if (templates.length === 0) {
    fetchResources({ type: 'templates' })(dispatch).then(data => {
      dispatch({ type: FETCH_TEMPLATES, payload: data });
    });
  }
};

export const fetchBwaTemplates = () => (dispatch, getState) => {
  const {
    utils: { bwaTypes },
  } = getState();

  if (bwaTypes.length === 0) {
    fetchResources({ type: 'report_templates', endpointPath: 'report_templates/bwa_types' })(
      dispatch
    ).then(data => {
      dispatch({ type: FETCH_BWA_TYPES, payload: data });
    });
  }
};

export const fetchCountries = () => (dispatch, getState) => {
  const {
    utils: { countries },
  } = getState();

  if (countries.length === 0) {
    fetchResources({ type: 'countries' })(dispatch).then(data => {
      dispatch({ type: FETCH_COUNTRIES, payload: data });
    });
  }
};

export const fetchIndustryKeys = () => (dispatch, getState) => {
  const {
    utils: { industry_keys },
  } = getState();

  if (industry_keys.length === 0) {
    fetchResources({ version: '/api/v2', type: 'industry_keys' })(dispatch).then(data => {
      dispatch({ type: FETCH_INDUSTRY_KEYS, payload: data });
    });
  }
};

export const fetchLegalFormEntries = () => (dispatch, getState) => {
  const {
    utils: { legal_form_entries },
  } = getState();

  if (legal_form_entries.length === 0) {
    fetchResources({ version: '/api/v2', type: 'legal_form_entries' })(dispatch).then(data => {
      dispatch({ type: FETCH_LEGAL_FORM_ENTRIES, payload: data });
    });
  }
};

export const fetchCustomerFormData = () => dispatch =>
  Promise.all([
    dispatch(fetchTemplates()),
    dispatch(fetchBwaTemplates()),
    dispatch(fetchCountries()),
    dispatch(fetchIndustryKeys()),
    dispatch(fetchLegalFormEntries()),
  ]);

export const fetchInvoices = customerId => {
  const headers = { Range: '0-8', 'Range-Unit': 'items' };
  const query = { order: { created_at: 'desc' } };

  return fetchResources({
    type: 'invoices',
    endpointPath: `customers/${customerId}/invoices`,
    query,
    headers,
  });
};

export const fetchDocuments = customerId => {
  const headers = { Range: '0-8', 'Range-Unit': 'items' };
  const query = { order: { created_at: 'desc' } };

  return fetchResources({
    type: 'documents',
    endpointPath: `customers/${customerId}/documents`,
    query,
    headers,
  });
};
