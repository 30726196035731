import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommentsContainer from 'users/containers/inbox/Comments';
import './InvoiceDetailPanel.scss';

const DetailPanel = ({ record, addAttachment, removeAttachment, attachment, type }, { t }) => (
  <div className="invoice-detail-panel">
    <div className="invoice-preview-container">
      <div className="actions">
        <Tooltip
          onClick={addAttachment}
          title={t('Add attachment')}
          placement="top"
          className="add-icon"
        >
          <IconButton>
            <FontAwesomeIcon icon="plus" size="sm" />
          </IconButton>
        </Tooltip>

        {attachment && (
          <React.Fragment>
            <a href={attachment.attachment.url} target="_blank" className="ui button black">
              {t('Open in a new tab')}
            </a>

            <Tooltip
              onClick={e => removeAttachment(e, attachment.id)}
              title={t('Delete attachment')}
              placement="top"
              className="remove-icon"
            >
              <IconButton>
                <FontAwesomeIcon icon="trash" size="sm" />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </div>

      {attachment && (
        /* eslint-disable-next-line no-undef */
        <div className="invoice-preview" onClick={() => window.open(attachment.attachment.url)}>
          {attachment.attachment.type === 'application/pdf' ? (
            <embed src={attachment.attachment.url} width="100%" height="600px" />
          ) : (
            <img src={attachment.attachment.url} width="100%;" height="600px" alt="Attachment" />
          )}
        </div>
      )}
    </div>

    <div className="invoice-comments">
      <div className="invoice-info">
        {type === 'Invoice' && (
          <h6>
            {t('Invoice Date')} <br />
            <strong>{record.date ? moment(record.date).format('Do MMMM YYYY') : '----'}</strong>
          </h6>
        )}

        <h6>
          {t('Received on')} <br />
          <strong>{moment(record.created_at).format('Do MMMM YYYY')}</strong>
        </h6>
      </div>

      <hr />

      <CommentsContainer type={type} recordId={record.id} customerId={record.customer_id} />
    </div>
  </div>
);

DetailPanel.propTypes = {
  record: PropTypes.object.isRequired,
  addAttachment: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  attachment: PropTypes.object,
};

DetailPanel.defaultProps = {
  attachment: null,
};

DetailPanel.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DetailPanel;
