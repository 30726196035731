import omit from 'lodash/omit';
import {
  ADD_DOCUMENT_CATEGORY,
  UPDATE_DOCUMENT_CATEGORY,
  FETCH_DOCUMENT_CATEGORIES,
  REMOVE_DOCUMENT_CATEGORY,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_DOCUMENT_CATEGORY: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      };
    }

    case UPDATE_DOCUMENT_CATEGORY: {
      const topLevel = state.items[action.payload.id].parent_id;

      const subState = topLevel
        ? {
            ...state.items,
            [action.payload.id]: { ...state.items[action.payload.id], ...action.payload },
          }
        : omit(state.items, [action.payload]);

      return {
        ...state,
        items: subState,
      };
    }

    case FETCH_DOCUMENT_CATEGORIES: {
      return {
        ...state,
        items: arrayToHashMap(action.payload),
      };
    }

    case REMOVE_DOCUMENT_CATEGORY: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
