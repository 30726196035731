import { path } from 'ramda';
import qs from 'qs';
import swal from 'sweetalert';
import startCase from 'lodash/startCase';
import Axios from '../utils/axios';
import { NotificationManager } from './notifications';
import { showLoader, hideLoader } from './utils';

const API_VERSION = '/api/v1';
const HEADERS = { 'Content-Type': 'application/json' };

const resourceName = type => startCase(type.slice(0, -1));

const handleError = ({ error, dispatch, errorMessage, setErrors = null, groupErrors = false }) => {
  const { response } = error;

  if (errorMessage) {
    dispatch(NotificationManager.error(errorMessage));
    return;
  }

  if (response.status === 401) {
    dispatch(NotificationManager.error('You are not authorized to perform that action.'));
    return;
  }

  if (response.status > 401 && response.status < 500) {
    const { errors } = response.data;

    if (Array.isArray(errors)) {
      if (groupErrors) {
        const fullMessages = errors.map(message => message).join('<br />');
        dispatch(NotificationManager.error(fullMessages));
      } else {
        errors.map(message => dispatch(NotificationManager.error(message)));
      }
    } else {
      const keys = Object.keys(errors);

      if (keys) {
        if (setErrors) {
          dispatch(NotificationManager.error('Please fix the form errors.'));

          setErrors(errors);
        } else if (groupErrors) {
          const fullMessages = keys
            .map(key => {
              let msg = `<strong>${startCase(key)}:</strong> ${errors[key][0]}`;

              if (key === 'base') {
                msg = Array.isArray(errors[key])
                  ? errors[key].map(message => message).join('<br />')
                  : errors[key][0];
              }

              return msg;
            })
            .join('<br />');

          dispatch(NotificationManager.error(fullMessages));
        } else {
          keys.forEach(key => {
            const message = key === 'base' ? errors[key][0] : `${key} ${errors[key][0]}`;
            dispatch(NotificationManager.error(message));
          });
        }
      } else {
        dispatch(NotificationManager.error('There was an error processing your request.'));
      }
    }
  }
};

export const createResource = ({
  type,
  data,
  config = { headers: HEADERS },
  endpointPath = '',
  successMessage = '',
  showMessage = true,
  errorMessage = '',
  baseUrl = true,
  setSubmitting = null,
  setErrors = null,
  groupErrors = false,
  version = API_VERSION,
}) => dispatch => {
  const resourcePath = endpointPath || type;
  const endpoint = `${baseUrl ? version : ''}/${resourcePath}`;

  dispatch(showLoader());
  if (setSubmitting) setSubmitting(true);

  return Axios.post(endpoint, data, config)
    .then(response => {
      const msg = successMessage || `Successfully created ${resourceName(type)}.`;

      if (setSubmitting) setSubmitting(false);
      if (showMessage) dispatch(NotificationManager.success(msg));

      dispatch(hideLoader());
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      dispatch(hideLoader());
      handleError({
        error,
        dispatch,
        errorMessage,
        setErrors,
        groupErrors,
      });
      return Promise.reject(path(['response', 'data', 'errors'], error) || error);
    });
};

export const fetchResource = ({
  type,
  id,
  query,
  endpointPath = '',
  version = API_VERSION,
}) => dispatch => {
  const resourcePath = endpointPath || `${type}/${id}`;
  const baseEndpoint = `${version}/${resourcePath}`;
  const queryString = query ? qs.stringify(query) : '';
  const endpoint = queryString ? `${baseEndpoint}?${queryString}` : baseEndpoint;

  dispatch(showLoader());

  return Axios.get(endpoint)
    .then(response => {
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    })
    .catch(error => {
      dispatch(hideLoader());
      return Promise.reject(error);
    });
};

export const fetchResources = ({
  type,
  query,
  endpointPath = '',
  headers = {},
  version = API_VERSION,
}) => dispatch => {
  const resourcePath = endpointPath || type;
  const queryString = query ? qs.stringify(query) : '';
  const endpoint = queryString
    ? `${version}/${resourcePath}?${queryString}`
    : `${version}/${resourcePath}`;

  dispatch(showLoader());

  return Axios.get(endpoint, { headers })
    .then(response => {
      const data = response.status === 204 ? [] : response.data;

      dispatch(hideLoader());
      return Promise.resolve(data);
    })
    .catch(error => {
      dispatch(hideLoader());
      return Promise.reject(error);
    });
};

export const fetchPaginatedResource = ({
  type,
  query,
  endpointPath = '',
  headers = {},
  version = '/api/v2',
}) => dispatch => {
  const resourcePath = endpointPath || type;
  const endpoint = `${version}/${resourcePath}`;

  dispatch(showLoader());

  return Axios.get(endpoint, { headers, params: query })
    .then(response => {
      const data = response.status === 204 ? [] : response.data;
      const page = +response.headers['pagination-current-page'];
      const per_page = query.per_page || 10;
      const total = +response.headers['pagination-total-count'];

      dispatch(hideLoader());

      return {
        items: data,
        params: query,
        paginationMeta: {
          page,
          per_page,
          total,
        },
      };
    })
    .catch(error => {
      dispatch(hideLoader());
      return Promise.reject(error);
    });
};

export const updateResource = ({
  id,
  type,
  data,
  config = { headers: HEADERS },
  endpointPath = '',
  successMessage = '',
  errorMessage = '',
  setSubmitting = null,
  setErrors = null,
  version = API_VERSION,
  method = 'PATCH',
  groupErrors = false,
}) => dispatch => {
  const resourcePath = endpointPath || `${type}/${id}`;
  const endpoint = `${version}/${resourcePath}`;

  dispatch(showLoader());
  if (setSubmitting) setSubmitting(true);

  return Axios({
    url: endpoint,
    data,
    method,
    ...config,
  })
    .then(response => {
      const msg = successMessage || `Successfully updated ${resourceName(type)}`;

      if (setSubmitting) setSubmitting(false);

      dispatch(hideLoader());
      dispatch(NotificationManager.success(msg));
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      dispatch(hideLoader());
      handleError({
        error,
        dispatch,
        errorMessage,
        setErrors,
        groupErrors,
      });
      return Promise.reject(path(['response', 'data', 'errors'], error) || error);
    });
};

export const deleteResource = ({
  type,
  id,
  endpointPath = '',
  baseUrl = true,
  version = API_VERSION,
  groupErrors = false,
}) => dispatch => {
  const endpoint = endpointPath
    ? `${baseUrl ? version : ''}/${endpointPath}`
    : `${baseUrl ? version : ''}/${type}/${id}`;

  return new Promise((resolve, reject) => {
    swal({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then(result => {
      if (!result) return;

      Axios.delete(endpoint)
        .then(() => {
          const msg = `${resourceName(type)} deleted successfully.`;

          dispatch(NotificationManager.info(msg));
          resolve();
        })
        .catch(error => {
          handleError({ error, dispatch, errorMessage: '', groupErrors });
          reject(path(['response', 'data', 'errors'], error) || error);
        });
    });
  });
};

export const confirmationRequest = ({
  title = 'Are you sure?',
  buttons = ['No', 'Yes'],
  icon = 'warning',
  successMessage = '',
  errorMessage = '',
  url,
  method,
  data = {},
  version = API_VERSION,
}) => dispatch =>
  new Promise((resolve, reject) => {
    swal({
      title,
      text: '',
      icon,
      buttons,
    }).then(result => {
      if (!result) return;

      Axios({ url: `${version}/${url}`, method, data })
        .then(response => {
          if (successMessage) dispatch(NotificationManager.info(successMessage));
          resolve(response.data);
        })
        .catch(error => {
          handleError({ error, dispatch, errorMessage });
          reject(path(['response', 'data', 'errors'], error) || error);
        });
    });
  });

export const paginationRequest = ({
  endpoint,
  tableQuery,
  dispatch = null,
  type = null,
  query,
  callback = null,
}) =>
  new Promise((resolve, reject) => {
    const { pageSize, page, search } = tableQuery;

    let queryString = '';
    if (query) {
        queryString = qs.stringify(
          {
            ...query,
          },
          { arrayFormat: 'brackets' }
      );
    }
    if (tableQuery.orderBy) {
        if (queryString.length > 1)
          queryString += '&';
        queryString += "order[" + tableQuery.orderBy.field + "]=" + tableQuery.orderDirection;
    }
    const url = `${endpoint}?per_page=${pageSize}&page=${page + 1}&q=${search}&${queryString}`;

    return Axios.get(url)
      .then(({ data, headers, status }) => {
        const finalData = status === 204 ? [] : data;

        if (callback) callback(finalData);

        resolve({
          data: finalData,
          page: headers['pagination-current-page'] - 1,
          totalCount: +headers['pagination-total-count'],
        });

        if (dispatch && type) {
          dispatch({ type, payload: finalData });
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const sweetAlert = ({
  title,
  text = '',
  icon = 'warning',
  callback = null,
  buttons = ['No', 'Yes'],
}) =>
  swal({
    title,
    text,
    icon,
    buttons,
  }).then(result => {
    if (!result) return;

    callback();
  });
