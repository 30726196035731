import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line react/prop-types
export const CompleteButton = ({ notification, handleUpdate }, { t }) => (
  <Tooltip
    title={t('Complete')}
    placement="top"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      handleUpdate(notification, 'complete');
    }}
  >
    <IconButton>
      <FontAwesomeIcon icon="check" size="sm" />
    </IconButton>
  </Tooltip>
);

// eslint-disable-next-line react/prop-types
export const SnoozeButton = ({ notification, handleUpdate }, { t }) => (
  <Tooltip
    title={t('Snooze for 2 days')}
    placement="top"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      handleUpdate(notification, 'snooze');
    }}
  >
    <IconButton>
      <FontAwesomeIcon icon="clock" size="sm" />
    </IconButton>
  </Tooltip>
);

export const ViewButton = ({ path }, { t }) =>
  path && (
    <Tooltip title={t('View more details')} placement="top">
      <IconButton>
        <a href={path} target="_blank">
          <FontAwesomeIcon icon="external-link-alt" size="sm" />
        </a>
      </IconButton>
    </Tooltip>
  );

CompleteButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

SnoozeButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

ViewButton.contextTypes = {
  t: PropTypes.func.isRequired,
};
