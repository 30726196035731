import {
  FETCH_CUSTOMER_TIME_TRACKINGS,
  FETCH_CUSTOMER_TIME_TRACKING,
} from 'common/constants/types';
import { arrayToHashMap } from 'common/utils/helpers';

const INITIAL_STATE = {
  items: {},
  params: {
    page: 1,
    per_page: 10,
    total: 0,
    q: null,
    mine: false,
    by_customer: null,
  },
  show: {
    loading: false,
    item: null,
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_TIME_TRACKINGS:
      return {
        ...state,
        items: arrayToHashMap(action.payload.items),
        params: {
          ...action.payload.params,
          ...action.payload.paginationMeta,
        },
        show: {
          ...INITIAL_STATE.show,
        },
      };
    case FETCH_CUSTOMER_TIME_TRACKING:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
