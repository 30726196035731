import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import Loader from '@material-ui/core/CircularProgress';

import { infoNotification } from 'common/actions/notifications';
import New from 'users/components/documents/New';
import { createResource, fetchResource } from 'common/actions/api';

const NewContainer = ({ currentUser: { customerId }, createResource, fetchResource }) => {
  const [documents, setDocuments] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [remainingUploads, setRemainingUploads] = useState({});
  const [invalidFiles, setInvalidFiles] = useState([]);

  useEffect(() => {
    fetchResource({ type: 'customers', id: customerId }).then(customer => setCustomer(customer));
    fetchResource({
      type: 'customers',
      endpointPath: `customers/${customerId}/remaining_uploads_this_month`,
      version: '/api/v2/',
    }).then(uploads => setRemainingUploads(uploads));
  }, [customerId, fetchResource]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const documents = acceptedFiles.map(af => ({
      id: '',
      notes: '',
      cost_center: '',
      cost_unit: '',
      file_name: af.name,
      file: af,
      submitted: false,
    }));

    setDocuments(documents);
    setInvalidFiles(rejectedFiles);
  }, []);

  const chainableSubmit = (values, index) => {
    const document = values[index];
    const data = new FormData(); // eslint-disable-line no-undef

    data.append('document[attachments][][attachment]', document.file);
    data.append('document[notes]', document.notes);
    data.append('document[cost_center]', document.cost_center);
    data.append('document[cost_unit]', document.cost_unit);

    return createResource({
      type: 'documents',
      endpointPath: `customers/${customerId}/documents`,
      data,
      showMessage: false,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    }).then(id => id);
  };

  const submit = (values, index) => {
    const document = values[index];
    const cloned = cloneDeep(values);

    if (document.submitted) return;

    chainableSubmit(values, index).then(id => {
      cloned.splice(index, 1, {
        ...document,
        submitted: true,
        id,
      });

      setDocuments(cloned);
    });
  };

  const submitAll = values => {
    const promises = [];
    const cloned = cloneDeep(values);

    values.forEach((document, index) => {
      if (document.submitted) {
        promises.push(Promise.resolve(null));
      } else {
        promises.push(chainableSubmit(values, index));
      }
    });

    Promise.all(promises).then(resolved => {
      resolved.forEach((id, index) => {
        if (id === null) return;

        cloned[index] = {
          ...cloned[index],
          submitted: true,
          id,
        };
      });

      setDocuments(cloned);
    });
  };

  const reset = () => {
    setDocuments([]);
    setInvalidFiles([]);
  };

  if (!customer) {
    return (
      <Loader
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    );
  }

  return (
    <New
      documents={documents}
      customer={customer}
      remainingUploads={remainingUploads}
      invalidFiles={invalidFiles}
      onDrop={onDrop}
      submit={submit}
      submitAll={submitAll}
      reset={reset}
    />
  );
};

NewContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  createResource: PropTypes.func.isRequired,
  fetchResource: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps, {
  infoNotification,
  createResource,
  fetchResource,
})(NewContainer);
