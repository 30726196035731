import omit from 'lodash/omit';
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  REMOVE_EMPLOYEE,
  FETCH_EMPLOYEES,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EMPLOYEE:
    case UPDATE_EMPLOYEE: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: { ...action.payload },
        },
      };
    }

    case FETCH_EMPLOYEES: {
      return {
        ...state,
        items: arrayToHashMap(action.payload),
      };
    }

    case REMOVE_EMPLOYEE: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
