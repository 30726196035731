import omit from 'lodash/omit';
import {
  ADD_DOCUMENT_PATTERN,
  UPDATE_DOCUMENT_PATTERN,
  REMOVE_DOCUMENT_PATTERN,
} from '../constants/types';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_DOCUMENT_PATTERN:
    case UPDATE_DOCUMENT_PATTERN: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: { ...action.payload },
        },
      };
    }

    case REMOVE_DOCUMENT_PATTERN: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
