import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { Select } from 'formik-material-ui';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { GB, DE } from 'country-flag-icons/react/1x1';

const getFlagComponent = (countryCode, className = 'flag-icon') => {
  const upperCaseCountryCode = String(countryCode).toUpperCase();
  switch (upperCaseCountryCode) {
    case 'EN':
      return <GB svg className={className} />;
    case 'DE':
      return <DE svg className={className} />;
    default:
      return null;
  }
};

const OutlinedSelect = ({
  name,
  id,
  label,
  formControlClass,
  children,
  inputProps,
  fullWidth,
  required,
  margin,
  errors,
  errorKey,
  disabled,
    showFlags,
}) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  const fieldError = errorKey ? getIn(errors, errorKey) : getIn(errors, name);
  const showError = !!fieldError;

  return (
    <FormControl
      fullWidth={fullWidth}
      className={formControlClass}
      required={required}
      variant="outlined"
      margin={margin}
      error={showError}
    >
      <InputLabel htmlFor={id} ref={inputLabel}>
        {label}
      </InputLabel>

      <Field
          name={name}
          fullWidth={fullWidth}
          required={required}
          component={Select}
          input={<OutlinedInput name={name} id={id} labelWidth={labelWidth} />}
          inputProps={{ id, required, ...inputProps }}
          disabled={disabled}
      >
        {children.map((option) => (
            <MenuItem key={option.props.value} value={option.props.value}>
              {showFlags && getFlagComponent(option.props.value)}
              {option.props.children}
            </MenuItem>
        ))}
      </Field>

      {showError && <FormHelperText>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

OutlinedSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  formControlClass: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  errorKey: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  showFlags: PropTypes.bool,
};

OutlinedSelect.defaultProps = {
  formControlClass: '',
  inputProps: {},
  fullWidth: false,
  required: false,
  margin: 'normal',
  errorKey: null,
  errors: null,
  disabled: false,
  showFlags: false,
};

export default OutlinedSelect;
