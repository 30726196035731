/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar as removeSnackbarAction } from '../actions/notifications';

class Notifier extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.displayed = [];

    this.storeDisplayed = this.storeDisplayed.bind(this);
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;

    newSnacks.forEach(snack => {
      if (notExists) return;

      notExists = notExists || !currentSnacks.filter(({ key }) => snack.key === key).length;
    });

    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], enqueueSnackbar, removeSnackbar } = this.props;

    notifications.forEach(notification => {
      if (this.displayed.includes(notification.key)) return;

      enqueueSnackbar(
        <div dangerouslySetInnerHTML={{ __html: notification.message }} />,
        notification.options
      );
      this.storeDisplayed(notification.key);
      removeSnackbar(notification.key);
    });
  }

  storeDisplayed(id) {
    this.displayed = [...this.displayed, id];
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ notifications: { notifications } }) => ({
  notifications,
});

export default connect(mapStateToProps, { removeSnackbar: removeSnackbarAction })(
  withSnackbar(Notifier)
);
