import { SIGN_OUT, SIGN_IN } from '../constants/types';

const INITIAL_STATE = {
  isLoading: false,
  isSignedIn: false,
  currentUser: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_OUT:
      return {
        ...state,
        isLoading: false,
        isSignedIn: false,
        currentUser: {},
      };
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        currentUser: action.payload,
      };

    default:
      return state;
  }
}
