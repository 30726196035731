import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InboxSettingsPageContainer from './containers/inbox/SettingsPage';
import InboxPageContainer from './containers/inbox/';
import FigoIntegrationPage from './containers/figo/Integration';
import FigoAccountTransactionsPage from './containers/figo/TransactionsTable';
import NewDocument from './containers/documents/New';
import SupportPage from './pages/Support/';
import IndividualReports from './components/reports/Individual';
import OpenItems from './components/openItems/';
import Cashbook from 'users/components/cashbook/';

const Routes = () => (
  <Switch>
    <Route exact path="/users/evaluations/individual" component={IndividualReports} />
    <Route exact path="/users/inbox/settings" component={InboxSettingsPageContainer} />
    <Route path="/users/inbox" component={InboxPageContainer} />
    <Route exact path="/users/bank_accounts" component={FigoIntegrationPage} />
    <Route
      exact
      path="/users/bank_accounts/:id/transactions"
      component={FigoAccountTransactionsPage}
    />
    <Route exact path="/users/support" component={SupportPage} />
    <Route exact path="/users/documents/react_new" component={NewDocument} />
    <Route exact path="/users/open_items" component={OpenItems} />
    <Route exact path="/users/cashbook" component={Cashbook} />
  </Switch>
);

export default Routes;
