import omit from 'lodash/omit';
import {
  ADD_ACCOUNTANT,
  UPDATE_ACCOUNTANT,
  FETCH_ACCOUNTANTS,
  REMOVE_ACCOUNTANT,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ACCOUNTANT:
    case UPDATE_ACCOUNTANT: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: { ...state.items[action.payload.id], ...action.payload },
        },
      };
    }

    case FETCH_ACCOUNTANTS: {
      return {
        ...state,
        items: arrayToHashMap(action.payload),
      };
    }

    case REMOVE_ACCOUNTANT: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
