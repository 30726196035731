/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
const DueDate = ({ overdue, today, due_date }, { t }) => (
  <React.Fragment>
    {overdue && (
      <React.Fragment>
        <span style={{ color: 'red' }}>{t('Overdue')}</span>
        <br />
      </React.Fragment>
    )}

    {today && (
      <React.Fragment>
        <span style={{ color: 'red' }}>{t('Today')}</span>
        <br />
      </React.Fragment>
    )}

    {due_date && (
      <span style={{ color: overdue || today ? 'red' : 'inherit' }}>
        {moment(due_date).format('Do MMMM YYYY')}
      </span>
    )}
    {!due_date && '----'}
  </React.Fragment>
);

export default DueDate;

DueDate.contextTypes = {
  t: PropTypes.func.isRequired,
};
