import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import swal from 'sweetalert';

import { fetchResources } from 'common/actions/api';
import Axios from 'common/utils/axios';
import AuthorizationGateContainer from 'common/containers/AuthorizationGate';
import FigoIntegrationPage from 'users/pages/Figo/Integration';

class FigoIntegrationContainer extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    fetchResources: PropTypes.func.isRequired,
  };

  state = {
    location: null,
    accounts: [],
    showIframe: false,
    loading: false,
  };

  componentDidMount() {
    this.fetchAccounts();

    Axios.get('/api/v1/figo_shield_location').then(response =>
      this.setState({ location: response.data.location })
    );

    // eslint-disable-next-line no-undef
    window.addEventListener('message', this.receiveIframeMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveIframeMessage); // eslint-disable-line no-undef
  }

  fetchAccounts = () => {
    const { fetchResources, currentUser } = this.props;

    fetchResources({
      type: 'figo_accounts',
      endpointPath: `customers/${currentUser.customerId}/figo_accounts`,
    }).then(accounts => this.setState({ accounts }));
  };

  startIntegration = () => {
    this.setState({ showIframe: true });
  };

  receiveIframeMessage = event => {
    const { t } = this.context;
    const code = path(['data', 'query', 'code'], event);

    if (code) {
      this.setState({ loading: true, showIframe: false });

      Axios.post('/api/v1/callbacks/connect_financial_source', { code })
        .then(() => {
          swal({
            title: t('Finleap integration successful!'),
            text: '',
            icon: 'success',
          });

          this.fetchAccounts();

          Axios.get('/api/v1/figo_shield_location').then(response =>
            this.setState({ location: response.data.location, showIframe: false, loading: false })
          );
        })
        .catch(error => {
          swal({
            title: 'There was an error integrating with Figo.',
            text: path(['response', 'message'], error),
            icon: 'warning',
          });

          this.setState({ showIframe: false, loading: false });
        });
    }
  };

  render() {
    return (
      <AuthorizationGateContainer roles="manager" feature="figo" redirect>
        <FigoIntegrationPage {...this.state} startIntegration={this.startIntegration} />
      </AuthorizationGateContainer>
    );
  }
}

FigoIntegrationContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps, {
  fetchResources,
})(FigoIntegrationContainer);
