import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import WhitelistItemActions from 'users/components/WhitelistItemActions';

const InboxSettingsPage = (
  { email, whitelistItems, openCreateModal, openEditModal, handleDestroy, toggleStatus },
  { t }
) => (
  <section className="cnt">
    <header>
      <h1>{t('Inbox Settings')}</h1>
    </header>

    <div className="content">
      <h4>{t('Your Inbox Address')}</h4>
      <span>{email}</span>

      <div style={{ marginTop: 60 }}>
        <h4>{t('Whitelist')}</h4>

        <button className="black button pointer" onClick={openCreateModal}>
          <i className="fa fa-plus" />
          {t('Add Whitelist Item')}
        </button>

        {whitelistItems.length > 0 && (
          <div className="table" style={{ marginTop: 40 }}>
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Gmail Activation Code</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {whitelistItems.map(item => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                    <td>{item.gmail_code}</td>
                    <td>{capitalize(item.status)}</td>
                    <td>
                      <WhitelistItemActions
                        item={item}
                        openEditModal={openEditModal}
                        handleDestroy={handleDestroy}
                        toggleStatus={toggleStatus}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {whitelistItems.length === 0 && (
          <div className="ui message info">
            <p>
              {t(
                'There are whitelist items created yet. Press the button to create a new whitelist item.'
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  </section>
);

InboxSettingsPage.propTypes = {
  email: PropTypes.string.isRequired,
  whitelistItems: PropTypes.array.isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  handleDestroy: PropTypes.func.isRequired,
  toggleStatus: PropTypes.func.isRequired,
};

InboxSettingsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InboxSettingsPage;
