import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchResources, fetchResource } from 'common/actions/api';
import TransactionsTable from 'users/components/figo/TransactionsTable';
import AuthorizationGateContainer from 'common/containers/AuthorizationGate';

class TransactionsContainer extends Component {
  static propTypes = {
    fetchResources: PropTypes.func.isRequired,
    fetchResource: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  state = {
    transactions: [],
    account: null,
  };

  componentDidMount() {
    this.fetchTransactions();
  }

  fetchTransactions = () => {
    const {
      fetchResources,
      fetchResource,
      match: {
        params: { id },
      },
    } = this.props;

    fetchResources({
      type: 'figo_transactions',
      query: { figo_account_id: id },
    }).then(transactions => {
      fetchResource({
        type: 'figo_accounts',
        id,
      }).then(account => {
        this.setState({ transactions, account });
      });
    });
  };

  render() {
    return (
      <AuthorizationGateContainer roles="manager" feature="figo" redirect>
        <section className="cnt">
          <Link to="/users/bank_accounts" style={{ display: 'block', marginBottom: 10 }}>
            {this.context.t('Back to accounts')}
          </Link>

          {this.state.account && <TransactionsTable {...this.state} />}
        </section>
      </AuthorizationGateContainer>
    );
  }
}

TransactionsContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(undefined, {
  fetchResources,
  fetchResource,
})(TransactionsContainer);
