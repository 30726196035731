import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { path } from 'ramda';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('is not a valid email')
    .required('is required'),
});

class WhitelistItemFormContainer extends Component {
  static propTypes = {
    whitelistItem: PropTypes.object,
    handleCreate: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    whitelistItem: null,
  };

  handleSubmit = (values, { setErrors, setSubmitting }) => {
    const { whitelistItem, handleCreate, handleUpdate } = this.props;
    const submit = whitelistItem ? handleUpdate : handleCreate;

    return submit(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(errors => {
        setSubmitting(false);
        setErrors(errors);
      });
  };

  render() {
    const { whitelistItem } = this.props;
    const initialValues = { email: path(['email'], whitelistItem) || '' };

    return (
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={this.handleSubmit}>
        {({ isSubmitting }) => (
          <Form className="full ui form">
            <div className="content">
              <div className="field">
                <Field type="email" name="email" placeholder="Email" autoFocus />
                <ErrorMessage name="email" component="span" className="error" />
              </div>
            </div>

            <div className="field">
              <button type="submit" className="ui button black" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default WhitelistItemFormContainer;
