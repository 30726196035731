import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import Loader from '@material-ui/core/CircularProgress';

import DetailPanel from 'users/components/inbox/DetailPanel';
import { deleteResource } from 'common/actions/api';
import { fetchInvoice } from 'common/actions/invoices';
import { fetchDocument } from 'common/actions/documents';

class DetailPanelContainer extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    fetchInvoice: PropTypes.func.isRequired,
    fetchDocument: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    record: PropTypes.object,
  };

  static defaultProps = {
    record: null,
  };

  componentDidMount() {
    const { id, customerId, record, fetchInvoice, fetchDocument, type } = this.props;

    if (record) return;

    if (type === 'Invoice') fetchInvoice({ customerId, invoiceId: id });
    if (type === 'Document') fetchDocument({ customerId, documentId: id });
  }

  addAttachment = e => {
    e.preventDefault();
    e.stopPropagation();

    const { customerId, id, openModal } = this.props;

    openModal(id, customerId);
  };

  removeAttachment = (e, attachmentId) => {
    e.preventDefault();
    e.stopPropagation();

    const { deleteResource, customerId, id, fetchInvoice, fetchDocument, type } = this.props;

    deleteResource({ type: 'attachments', endpointPath: `attachments/${attachmentId}` }).then(
      () => {
        if (type === 'Invoice') fetchInvoice({ customerId, invoiceId: id });
        if (type === 'Document') fetchDocument({ customerId, documentId: id });
      }
    );
  };

  render() {
    const { record, type } = this.props;

    if (!record) return <Loader style={{ color: 'black' }} />;
    const attachment = type === 'Invoice' ? record.attachments[0] : record.attachment;

    return (
      <DetailPanel
        type={type}
        record={record}
        attachment={attachment}
        addAttachment={this.addAttachment}
        removeAttachment={this.removeAttachment}
      />
    );
  }
}
const mapStateToProps = ({ invoices, documents: { byCustomer } }, { id, customerId, type }) => ({
  record: type === 'Invoice' ? invoices.items[id] : path([customerId, id], byCustomer),
});

export default connect(mapStateToProps, {
  fetchInvoice,
  fetchDocument,
  deleteResource,
})(DetailPanelContainer);
