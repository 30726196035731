import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('#react-app');

class AppModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { open, title, children, closeModal } = this.props;

    return (
      <Modal
        isOpen={open}
        overlayClassName="ngdialog ngdialog-overlay ngdialog-theme-default"
        className="ngdialog-content"
      >
        <main className="modal">
          <div className="cnt">
            <header>
              <h1>{title}</h1>
            </header>

            {children}
          </div>
        </main>

        <button className="unstyled ngdialog-close" onClick={closeModal} />
      </Modal>
    );
  }
}

export default AppModal;
