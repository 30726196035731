import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import * as yup from 'yup';

import ModalFormContainer from 'common/containers/ModalForm';
import OutlinedSelect from 'common/components/OutlinedSelect';
import { createResource, fetchResources } from 'common/actions/api';

const unsetColorStyles = { color: 'unset' };

const schema = yup.object().shape({
  account_id: yup.string().required(),
  recipient: yup.string().required(),
  purpose: yup.string().required(),
  amount: yup.number().required(),
  iban: yup.string().required(),
});

const PaymentModal = (_, { t }) => {
  const dispatch = useDispatch();
  const { invoice } = useSelector(({ utils }) => utils.modalFormData);
  const [bankingAccounts, setBankingAccounts] = useState([]);
  const [finleapAccounts, setFinleapAccounts] = useState([]);

  const getBankingAccounts = useCallback(
    invoice => {
      fetchResources({
        endpointPath: `creditors/${invoice.creditor_id}/creditor_banking_accounts?invoice_id=${invoice.id}`,
      })(dispatch).then(data => setBankingAccounts(data));
    },
    [dispatch]
  );

  const getFinleapAccounts = useCallback(
    invoice => {
      fetchResources({ endpointPath: `customers/${invoice.customer_id}/figo_accounts` })(
        dispatch
      ).then(data => setFinleapAccounts(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!invoice) return;

    getBankingAccounts(invoice);
    getFinleapAccounts(invoice);
  }, [getBankingAccounts, getFinleapAccounts, invoice]);

  const handleSubmit = (values, actions) => {
    createResource({
      endpointPath: 'figo_onetime_payment',
      data: { payment: { ...values, invoice_id: invoice.id } },
      successMessage: t(
        'You will be redirected to the payment provider now to complete the payment.'
      ),
      ...actions,
    })(dispatch).then(data => {
      setTimeout(() => {
        window.open(data.location); // eslint-disable-line no-undef
      }, 2000);
    });
  };

  if (!invoice) return null;

  const initialValues = {
    recipient: invoice.creditor_name,
    purpose: invoice.finleap_payment_description,
    amount: invoice.amount,
    iban: invoice.creditor_iban || '',
    account_id: '',
  };

  return (
    <ModalFormContainer
      id="finleap-payment-modal"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      schema={schema}
      title={t('Make a Payment')}
      titleStyle={{ paddingBottom: 0 }}
    >
      <OutlinedSelect
        id="account_id"
        name="account_id"
        label={t('Your Accounts')}
        fullWidth
        autoFocus
      >
        {finleapAccounts.map(account => (
          <MenuItem key={account.id} value={account.iban}>
            {account.bank_name} - {account.account_number}
          </MenuItem>
        ))}
      </OutlinedSelect>

      <Field
        component={TextField}
        variant="outlined"
        margin="normal"
        label={t('Recipient')}
        name="recipient"
        style={unsetColorStyles}
        className="unset-input-color"
        fullWidth
        required
      />

      {invoice.creditor_iban || bankingAccounts.length > 0 ? (
        <OutlinedSelect
          id="iban"
          name="iban"
          label={t('Recipient Bank Accounts')}
          fullWidth
          required
        >
          {invoice.creditor_iban && (
            <MenuItem value={invoice.creditor_iban}>{invoice.creditor_iban}</MenuItem>
          )}

          {bankingAccounts.map(account => (
            <MenuItem key={account.id} value={account.iban}>
              {account.iban}
            </MenuItem>
          ))}
        </OutlinedSelect>
      ) : (
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          label={t('Recipient IBAN')}
          className="unset-input-color"
          name="iban"
          fullWidth
          required
        />
      )}

      <Field
        component={TextField}
        variant="outlined"
        margin="normal"
        className="unset-input-color"
        label={t('Amount')}
        name="amount"
        fullWidth
        required
        disabled
      />

      <Field
        component={TextField}
        variant="outlined"
        margin="normal"
        fullWidth
        label={t('Purpose')}
        className="unset-input-color"
        name="purpose"
        required
      />
    </ModalFormContainer>
  );
};

PaymentModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaymentModal;
