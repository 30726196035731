/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { ViewButton, CompleteButton, SnoozeButton } from './Buttons';

const Table = ({ title, notifications, handleUpdate, search }, { t }) => (
  <MaterialTable
    title={title}
    columns={[
      {
        title: t('Type'),
        field: 'kind',
        render: rowData => (
          <Tooltip title={rowData.avatar_tooltip} placement="top">
            <Avatar>{rowData.avatar_letter}</Avatar>
          </Tooltip>
        ),
      },
      {
        title: t('Info'),
        field: 'description',
        render: rowData => <span dangerouslySetInnerHTML={{ __html: rowData.description }} />,
      },
      {
        title: t('Due Date'),
        field: 'due_date',
        type: 'date',
        render: rowData => (
          <React.Fragment>
            {rowData.overdue && (
              <React.Fragment>
                <span style={{ color: 'red' }}>{t('Overdue')}</span>
                <br />
              </React.Fragment>
            )}

            {rowData.today && (
              <React.Fragment>
                <span style={{ color: 'red' }}>{t('Today')}</span>
                <br />
              </React.Fragment>
            )}

            {rowData.due_date && (
              <span style={{ color: rowData.overdue || rowData.today ? 'red' : 'inherit' }}>
                {moment(rowData.due_date).format('Do MMMM YYYY')}
              </span>
            )}
            {!rowData.due_date && '----'}
          </React.Fragment>
        ),
      },
      {
        title: t('Actions'),
        field: 'id',
        cellStyle: {
          textAlign: 'right',
        },
        headerStyle: {
          textAlign: 'right',
        },
        render: rowData => (
          <React.Fragment>
            <ViewButton path={rowData.action_path} />
            <CompleteButton notification={rowData} handleUpdate={handleUpdate} />
            {!rowData.today && !rowData.overdue && rowData.kind !== 'payslip_available' && (
              <SnoozeButton notification={rowData} handleUpdate={handleUpdate} />
            )}
          </React.Fragment>
        ),
      },
    ]}
    data={notifications}
    options={{
      search,
      paging: false,
    }}
  />
);

Table.propTypes = {
  title: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  search: PropTypes.bool,
};

Table.defaultProps = {
  search: true,
};

Table.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Table;
