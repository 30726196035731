import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from 'common/components/Pagination';

const CashbookTable = ({ entries, toggleChecked, pagination }, { t }) => {
  const today = moment().format('DD-MM-YYYY');

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Date')}</TableCell>
            <TableCell>{t('Amount')}</TableCell>
            <TableCell>{t('Exchange Rate')}</TableCell>
            <TableCell>{t('Document Number')}</TableCell>
            <TableCell>{t('Description')}</TableCell>
            <TableCell>{t('Cost Center')}</TableCell>
            <TableCell>{t('Cost Unit')}</TableCell>
            <TableCell>{t('Balance')}</TableCell>
            <TableCell>{t('Checked')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {entries.map(entry => (
            <Fragment key={entry.id}>
              <TableRow
                style={{
                  borderLeft: `5px solid ${entry.amount > 0 ? 'green' : 'red'}`,
                }}
              >
                <TableCell>{entry.date ? moment(entry.date).format('YYYY/MM/DD') : ''}</TableCell>
                <TableCell>
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: entry.currency,
                    minimumFractionDigits: 2,
                  }).format(entry.amount)}
                </TableCell>
                <TableCell>{entry.exchange_rate}</TableCell>
                <TableCell>{entry.document_number}</TableCell>
                <TableCell>{entry.description}</TableCell>
                <TableCell>{entry.cost_center}</TableCell>
                <TableCell>{entry.cost_unit}</TableCell>
                <TableCell>
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: entry.currency,
                    minimumFractionDigits: 2,
                  }).format(entry.balance)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    className="checkbox"
                    defaultChecked={entry.checked}
                    onChange={() => toggleChecked(entry.id)}
                    disableRipple
                    disabled={today !== moment(entry.created_at).format('DD-MM-YYYY')}
                  />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>

      <Pagination {...pagination} />
    </Fragment>
  );
};

CashbookTable.propTypes = {
  entries: PropTypes.array.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
};

CashbookTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CashbookTable;
