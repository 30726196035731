import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalForm from 'common/components/ModalForm';
import { openModalForm, closeModalForm } from 'common/actions/utils';

const ModalFormContainer = ({ id, handler, open: modalOpen, ...rest }) => {
  const open = modalOpen === id;

  return (
    <React.Fragment>
      {handler && handler(rest.openModalForm)}
      <ModalForm {...rest} open={open} />
    </React.Fragment>
  );
};

ModalFormContainer.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  handler: PropTypes.func,
};

ModalFormContainer.defaultProps = {
  handler: null,
};

const mapStateToProps = ({ utils: { modalFormOpen } }) => ({
  open: modalFormOpen,
});

export default connect(mapStateToProps, {
  openModalForm,
  closeModalForm,
})(ModalFormContainer);
