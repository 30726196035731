import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from 'common/components/Pagination';

const useStyles = makeStyles(() => ({
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
    },
  },
}));

const SimpleDataTable = ({
  data,
  columns,
  onRowClick,
  tableCellStyles,
  tableProps,
  striped,
  pagination,
  fallbackMessage,
  rowStyles,
}) => {
  const classes = useStyles();

  if (data.length === 0 && fallbackMessage) {
    return <div className="ui info message">{fallbackMessage}</div>;
  }

  return (
    <Fragment>
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell column="th" key={index} style={col.styles ? col.styles : {}}>
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((d, index) => (
            <TableRow
              key={index}
              hover={!!onRowClick}
              onClick={() => (onRowClick ? onRowClick(d) : null)}
              classes={{ hover: classes.hover }}
              style={{
                cursor: onRowClick ? 'pointer' : 'auto',
                backgroundColor: striped && index % 2 !== 0 ? '#E0E0E0' : 'inherit',
                ...rowStyles(d),
              }}
            >
              {columns.map((col, index) => (
                <TableCell component="td" key={index} style={{ ...tableCellStyles }}>
                  {col.render ? col.render(d) : d[col.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination && <Pagination {...pagination} />}
    </Fragment>
  );
};

SimpleDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  tableCellStyles: PropTypes.object,
  tableProps: PropTypes.object,
  onRowClick: PropTypes.func,
  striped: PropTypes.bool,
  fallbackMessage: PropTypes.string,
  rowStyles: PropTypes.func,
};

SimpleDataTable.defaultProps = {
  pagination: null,
  onRowClick: null,
  tableCellStyles: {},
  tableProps: {},
  striped: false,
  fallbackMessage: '',
  rowStyles: () => ({}),
};

export default SimpleDataTable;
