import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import './Comment.scss';

const Comment = ({ comment, isAuthor, deleteComment }) => (
  <ListItem key={comment.id} className={`inbox-comment ${!isAuthor && 'admin'}`}>
    {isAuthor && (
      <ListItemAvatar>
        <Tooltip placement="top" title={comment.author_name}>
          <Avatar style={{ backgroundColor: '#2185D0' }}>{comment.author_name.substr(0, 1)}</Avatar>
        </Tooltip>
      </ListItemAvatar>
    )}

    <ListItemText
      className={`bubble ${isAuthor ? 'user' : 'admin'}`}
      secondary={moment(comment.created_at).format('Do MMMM YYYY, HH:mm')}
    >
      <ReactMarkdown>{comment.comment}</ReactMarkdown>
    </ListItemText>

    {isAuthor && (
      <ListItemSecondaryAction>
        <IconButton onClick={() => deleteComment(comment.id)}>
          <FontAwesomeIcon icon="trash" size="xs" />
        </IconButton>
      </ListItemSecondaryAction>
    )}

    {!isAuthor && (
      <ListItemAvatar>
        <Tooltip placement="top" title="Eifas Admin">
          <Avatar>E</Avatar>
        </Tooltip>
      </ListItemAvatar>
    )}
  </ListItem>
);

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

export default Comment;
