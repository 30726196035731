import {
  faEnvelope as faEnvelopeRegular,
  faMoneyBillAlt as faMoneyBillAltRegular,
  faFilePdf as faFilePdfRegular,
  faCopy as faCopyRegular,
  faBuilding as faBuildingRegular,
  faFileExcel as faExcelRegular,
} from '@fortawesome/free-regular-svg-icons';

import {
  faForward,
  faQuestionCircle,
  faQuestion,
  faHome,
  faFile,
  faFileImport,
  faFolder,
  faFileInvoice,
  faUsers,
  faBriefcase,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faExternalLinkAlt,
  faTrash,
  faReceipt,
  faPlus,
  faClock,
  faBell,
  faSignOutAlt,
  faSearch,
  faCaretDown,
  faCaretRight,
  faTimes,
  faBars,
  faLock,
  faUser,
  faKey,
  faEdit,
  faPenSquare,
  faToggleOn,
  faToggleOff,
  faEnvelope,
  faMoneyBillAlt,
  faUserCog,
  faBuilding,
  faCreditCard,
  faMoneyBill,
  faCloudUploadAlt,
  faTasks,
  faTable,
  faList,
  faChevronRight,
  faChevronLeft,
  faFileCsv,
  faMagic,
  faBan,
  faDownload,
  faBook,
  faHistory,
  faFlag,
  faUndo,
  faDatabase,
  faTrashRestore,
  faCopy,
  faTags,
  faFileUpload,
  faFileDownload,
  faMinus,
  faFileSignature,
    faCircle,
    faCheckCircle,
    faIdCard,
    faPaperPlane
} from '@fortawesome/free-solid-svg-icons';

export default {
  faForward,
  faQuestion,
  faQuestionCircle,
  faHome,
  faFile,
  faFileImport,
  faFolder,
  faFileInvoice,
  faUsers,
  faBriefcase,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faExternalLinkAlt,
  faTrash,
  faReceipt,
  faPlus,
  faClock,
  faBell,
  faSignOutAlt,
  faSearch,
  faCaretDown,
  faCaretRight,
  faTimes,
  faBars,
  faLock,
  faUser,
  faKey,
  faEdit,
  faPenSquare,
  faToggleOn,
  faToggleOff,
  faEnvelope,
  faMoneyBillAlt,
  faUserCog,
  faBuilding,
  faCreditCard,
  faMoneyBill,
  faEnvelopeRegular,
  faMoneyBillAltRegular,
  faCloudUploadAlt,
  faTasks,
  faTable,
  faList,
  faFilePdfRegular,
  faChevronRight,
  faChevronLeft,
  faFileCsv,
  faMagic,
  faBan,
  faDownload,
  faBook,
  faHistory,
  faCopyRegular,
  faFlag,
  faBuildingRegular,
  faUndo,
  faDatabase,
  faTrashRestore,
  faCopy,
  faTags,
  faFileUpload,
  faFileDownload,
  faExcelRegular,
  faMinus,
  faFileSignature,
  faCircle,
  faCheckCircle,
  faIdCard,
  faPaperPlane
};
