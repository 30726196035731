import {
  FETCH_CUSTOMER_EMPOYEES_WITH_REQUEST,
  FETCH_CUSTOMER_EMPOYEES_WITH_CURRENT_YEAR_CLEARANCE,
  FETCH_CUSTOMER_EMPLOYEE_REQUESTS,
  FETCH_CUSTOMER_EMPLOYEE_OTHER_REQUESTS,
  PREVIEW_CUSTOMER_EMPLOYEE_REQUEST,
  RESET_CUSTOMER_EMPLOYEES,
  TOGGLE_CUSTOMER_EMPLOYEE_STATUS_REQUEST,
} from 'common/constants/types';

const INIT_STATE = {
  employeesWithRequest: {
    loading: false,
    items: [],
  },
  employeeRequests: {
    employeeId: null,
    loading: false,
    items: [],
  },
  otherRequests: {
    loading: false,
    items: [],
  },
  employeeRequestPreview: {
    objectType: null,
    data: null,
  },
};

export default function customerEmployees(state = INIT_STATE, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_EMPOYEES_WITH_REQUEST:
    case FETCH_CUSTOMER_EMPOYEES_WITH_CURRENT_YEAR_CLEARANCE:
      return {
        ...state,
        employeesWithRequest: {
          ...state.employeesWithRequest,
          ...action.payload,
        },
        employeeRequestPreview: null,
      };
    case FETCH_CUSTOMER_EMPLOYEE_REQUESTS:
      return {
        ...state,
        employeeRequests: {
          ...state.employeeRequests,
          ...action.payload,
        },
        employeeRequestPreview: null,
      };
    case FETCH_CUSTOMER_EMPLOYEE_OTHER_REQUESTS:
      return {
        ...state,
        otherRequests: {
          ...state.otherRequests,
          ...action.payload,
        },
      };
    case PREVIEW_CUSTOMER_EMPLOYEE_REQUEST:
      return {
        ...state,
        employeeRequestPreview: {
          ...action.payload,
        },
      };
    case TOGGLE_CUSTOMER_EMPLOYEE_STATUS_REQUEST: {
      const employeeRequestsItems = state.employeeRequests.items.filter(
        i => i.id !== action.payload.employeeRequestId
      );
      const employeesWithRequestItems = employeeRequestsItems.length
        ? state.employeesWithRequest.items
        : state.employeesWithRequest.items.filter(i => i.id !== state.employeeRequests.employeeId);

      return {
        ...state,
        employeesWithRequest: {
          ...state.employeesWithRequest,
          items: employeesWithRequestItems,
        },
        employeeRequests: {
          ...state.employeeRequests,
          items: employeeRequestsItems,
        },
        otherRequests: {
          ...state.otherRequests,
          items: state.otherRequests.items.filter(i => i.id !== action.payload.employeeRequestId),
        },
        employeeRequestPreview: null,
      };
    }
    case RESET_CUSTOMER_EMPLOYEES:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
}
