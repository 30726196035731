import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import has from 'lodash/has';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Pagination from 'common/components/Pagination';
import { usePagination } from 'common/hooks/apiActions';
import { openModalForm } from 'common/actions/utils';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    padding: 0,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  collapsed: {
    fontWeight: 'bold',
  },
}));

const InvoicesTable = ({ kind, year }, { t }) => {
  const dispatch = useDispatch();
  const customerId = useSelector(
    ({ auth: { currentUser } }) => currentUser.customerId || currentUser.customer_id
  );
  const [transactions, setTransactions] = useState({});
  const classes = useRowStyles();

  const { data, pagination } = usePagination({
    endpoint: `/api/v2/customers/${customerId}/invoices/open_items`,
    defaultQuery: { kind, year },
    preload: true,
  });

  const { fetchData } = usePagination({
    endpoint: `/api/v2/customers/${customerId}/exportable_transactions`,
    preload: false,
  });

  const fetchTransactions = invoiceId => {
    fetchData({ invoice_id: invoiceId }).then(data => {
      setTransactions(prev => ({ ...prev, [invoiceId]: { open: true, transactions: data } }));
    });
  };

  const toggleOpen = invoiceId => {
    if (has(transactions, invoiceId)) {
      setTransactions(prev => ({
        ...prev,
        [invoiceId]: { ...prev[invoiceId], open: !prev[invoiceId].open },
      }));
    } else {
      fetchTransactions(invoiceId);
    }
  };

  const openPaymentModal = invoice => {
    dispatch(openModalForm({ id: 'finleap-payment-modal', invoice }));
  };

  return (
    <Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '20%' }}>
              {kind === 'ar' ? t('Debtor') : t('Creditor')}
            </TableCell>
            <TableCell style={{ width: '20%' }}>{t('Invoice Number')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('Date')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('Due Date')}</TableCell>
            <TableCell style={{ width: '15%' }}>{t('Amount')}</TableCell>
            <TableCell style={{ width: '25%' }} align="right">
              {t('Actions')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(invoice => (
            <Fragment key={invoice.id}>
              <TableRow className={classes.root} onClick={() => toggleOpen(invoice.id)}>
                <TableCell
                  className={clsx({
                    [classes.collapsed]: get(transactions, [invoice.id, 'open'], false),
                  })}
                >
                  {invoice.creditor_name}
                </TableCell>

                <TableCell
                  className={clsx({
                    [classes.collapsed]: get(transactions, [invoice.id, 'open'], false),
                  })}
                >
                  {invoice.document_number}
                </TableCell>

                <TableCell
                  className={clsx({
                    [classes.collapsed]: get(transactions, [invoice.id, 'open'], false),
                  })}
                >
                  {invoice.date ? moment(invoice.date).format('YYYY/MM/DD') : ''}
                </TableCell>

                <TableCell />

                <TableCell
                  className={clsx({
                    [classes.collapsed]: get(transactions, [invoice.id, 'open'], false),
                  })}
                >
                  {kind === 'ap' ? '-' : ''}
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  }).format(invoice.amount)}
                </TableCell>

                <TableCell align="right" className={classes.actions}>
                  <Fragment>
                    {get(invoice, ['attachments', 0, 'attachment', 'url']) && (
                      <a
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        href={get(invoice, ['attachments', 0, 'attachment', 'url'])}
                        target="_blank"
                        className={clsx({
                          [classes.collapsed]: get(transactions, [invoice.id, 'open'], false),
                        })}
                      >
                        {t('View Invoice')}
                      </a>
                    )}

                    {kind === 'ap' && (
                      <button
                        className="ui button black"
                        style={{ marginLeft: 10 }}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          openPaymentModal(invoice);
                        }}
                      >
                        {t('Pay')}
                      </button>
                    )}
                  </Fragment>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={6} className={classes.cell}>
                  <Collapse
                    in={get(transactions, [invoice.id, 'open'], false)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Table className={classes.table}>
                      <TableBody>
                        {get(transactions, [invoice.id, 'transactions'], []).map(transaction => (
                          <TableRow key={transaction.id}>
                            <TableCell style={{ width: '20%' }} />

                            <TableCell style={{ width: '20%' }} align="left">
                              {invoice.document_number}
                            </TableCell>

                            <TableCell style={{ width: '10%' }}>
                              {transaction.invoice_date
                                ? moment(transaction.invoice_date).format('YYYY/MM/DD')
                                : ''}
                            </TableCell>

                            <TableCell style={{ width: '10%' }}>
                              {transaction.payment_due_date
                                ? moment(transaction.payment_due_date).format('YYYY/MM/DD')
                                : ''}
                            </TableCell>

                            <TableCell style={{ width: '15%' }}>
                              {kind === 'ap' ? '-' : ''}
                              {new Intl.NumberFormat('de-DE', {
                                style: 'currency',
                                currency: transaction.currency,
                                minimumFractionDigits: 2,
                              }).format(transaction.amount)}
                            </TableCell>

                            <TableCell style={{ width: '25%' }} />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}

          <TableRow className={classes.root}>
            <TableCell>{t('Sum')}</TableCell>

            <TableCell colSpan="3" />

            <TableCell>
              {kind === 'ap' ? '-' : ''}
              {new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
              }).format(get(data, [0, 'sum'], 0))}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>

      <Pagination {...pagination} />
    </Fragment>
  );
};

InvoicesTable.propTypes = {
  kind: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

InvoicesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InvoicesTable;
