import './utils/wdyr';
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import blue from '@material-ui/core/colors/blue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Router from './Router';
import configureStore from './store/configureStore';
import { translations } from './lib/translations';
import icons from './utils/icons';

library.add(fab, icons);

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: { main: '#000' },
  },
  typography: {
    fontSize: 14,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem',
        backgroundColor: '#fff',
        color: '#000',
        border: '0.5px solid grey',
      },
    },
    MuiTableCell: {
      body: {
        fontSize: '0.9rem',
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          color: 'white',
        },
      },
      label: {
        fontSize: '1rem',
      },
    },
  },
});

class Root extends React.Component {
  render() {
    const { path, storeState, children } = this.props;
    const store = configureStore(storeState);
    const initialLang = storeState.auth.currentUser.defaultLanguage || 'en';

    return (
      <Provider store={store}>
        <I18n translations={translations} initialLang={initialLang} fallbackLang="en">
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                maxSnack={5}
                autoHideDuration={3500}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                action={
                  <IconButton>
                    <FontAwesomeIcon icon="times" size="xs" color="#fff" />
                  </IconButton>
                }
              >
                <Router path={path}>{children}</Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </I18n>
      </Provider>
    );
  }
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  storeState: PropTypes.object,
};

Root.defaultProps = {
  path: '/',
  storeState: {
    auth: {
      isLoading: false,
      isSignedIn: false,
      currentUser: {},
    },
  },
};

export default Root;
