import omit from 'lodash/omit';
import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  FETCH_CUSTOMERS,
  FETCH_SIDEBAR_CUSTOMERS,
  REMOVE_CUSTOMER,
  ADD_TO_SIDEBAR,
  REMOVE_FROM_SIDEBAR,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
  sidebar: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CUSTOMER:
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: { ...state.items[action.payload.id], ...action.payload },
        },
      };
    }

    case FETCH_CUSTOMERS: {
      return {
        ...state,
        items: {
          ...state.items,
          ...arrayToHashMap(action.payload),
        },
      };
    }

    case ADD_TO_SIDEBAR: {
      if (state.sidebar[action.payload.id] || Object.keys(state.sidebar).length >= 50) {
        return state;
      }
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          [action.payload.id]: { ...state.items[action.payload.id], ...action.payload },
        },
      };
    }

    case REMOVE_FROM_SIDEBAR: {
      if (!state.sidebar[action.payload.id]) {
        return state;
      }
      return {
        ...state,
        sidebar: omit(state.sidebar, [action.payload.id]),
      };
    }

    case FETCH_SIDEBAR_CUSTOMERS: {
      return {
        ...state,
        sidebar: {
          ...arrayToHashMap(action.payload),
        },
      };
    }

    case REMOVE_CUSTOMER: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
