import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const ItemActions = ({ item, openEditModal, handleDestroy, toggleStatus }, { t }) => (
  <React.Fragment>
    <Tooltip title={t('Edit')} placement="top">
      <button style={{ width: 20 }} className="unstyled" onClick={() => openEditModal(item)}>
        <i className="fa fa-edit fs-14" />
      </button>
    </Tooltip>

    <Tooltip title={t('Delete')} placement="top">
      <button style={{ width: 20 }} className="unstyled" onClick={() => handleDestroy(item.id)}>
        <i className="fa fa-trash fs-14" />
      </button>
    </Tooltip>

    {['disabled', 'pending'].includes(item.status) && (
      <Tooltip title={t('Enable')} placement="top">
        <button style={{ width: 20 }} className="unstyled" onClick={() => toggleStatus(item)}>
          <i className="fa fa-plus fs-14" />
        </button>
      </Tooltip>
    )}

    {item.status === 'enabled' && (
      <Tooltip title={t('Disable')} placement="top">
        <button style={{ width: 20 }} className="unstyled" onClick={() => toggleStatus(item)}>
          <i className="fa fa-minus fs-14" />
        </button>
      </Tooltip>
    )}
  </React.Fragment>
);

ItemActions.propTypes = {
  item: PropTypes.object.isRequired,
  toggleStatus: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  handleDestroy: PropTypes.func.isRequired,
};

ItemActions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ItemActions;
