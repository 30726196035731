/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid, no-undef */
import React from 'react';
import BaseRoot from 'common/Root';
import Routes from './Routes';
import SidebarContainer from './containers/Sidebar';
import AuthenticationGateContainer from 'common/containers/AuthenticationGate';
import Notifier from 'common/containers/Notifier';
import ScrollToTop from 'common/components/ScrollToTop/';
import './styles/global.scss';

const Root = props => {
  const toggleMenu = () => {
    document.getElementsByTagName('body')[0].classList.toggle('active');
  };

  return (
    <BaseRoot {...props}>
      <AuthenticationGateContainer type="users">
        <SidebarContainer />

        <main id="main" role="main">
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </main>

        <a className="menu" href="javascript:void(0)" title="menu" onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </a>

        <Notifier />
      </AuthenticationGateContainer>
    </BaseRoot>
  );
};

export default Root;
