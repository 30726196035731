import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateResource, fetchResources } from 'actions/api';
import InboxNotificationsPage from 'users/pages/Inbox/Notifications';

class NotificationsPageContainer extends Component {
  static propTypes = {
    updateResource: PropTypes.func.isRequired,
    fetchResources: PropTypes.func.isRequired,
  };

  state = { notifications: [] };

  componentDidMount() {
    this.props
      .fetchResources({
        type: 'notifications',
      })
      .then(notifications => {
        this.setState({ notifications });
      });
  }

  updateNotification = (notification, action = 'complete') => {
    const { updateResource } = this.props;
    const { id } = notification;

    return updateResource({
      endpointPath: `notifications/${id}/${action}`,
      successMessage: `Successfully ${action}d notification.`,
    }).then(() => {
      this.setState(prevState => ({
        notifications: prevState.notifications.filter(n => n.id !== id),
      }));
    });
  };

  groupedNotifications = () => {
    const grouped = { today: [], other: [] };
    this.state.notifications.forEach(n =>
      n.today || n.overdue ? grouped.today.push(n) : grouped.other.push(n)
    );

    return grouped;
  };

  render() {
    return (
      <InboxNotificationsPage
        notifications={this.groupedNotifications()}
        handleUpdate={this.updateNotification}
      />
    );
  }
}

export default connect(undefined, {
  updateResource,
  fetchResources,
})(NotificationsPageContainer);
