import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { closeModalForm, openModalForm } from 'common/actions/utils';
import { usePagination } from 'common/hooks/apiActions';
import { createResource, updateResource } from 'common/actions/api';
import CashbookTable from './Table';
import AddCashbookModal from './AddCashbookModal';

const Cashbook = (_, { t }) => {
  const dispatch = useDispatch();

  const { data, setData, pagination } = usePagination({
    endpoint: `/api/v1/cash_book_entries`,
    preload: true,
  });

  const toggleChecked = id => {
    updateResource({ id, endpointPath: `cash_book_entries/${id}/toggle_checked` })(dispatch).then(
      cashEntry => {
        setData(prev =>
          prev.map(entry => {
            if (entry.id === id) return cashEntry;
            return entry;
          })
        );
      }
    );
  };

  const openCashbookModal = () => {
    dispatch(openModalForm({ id: 'cashbook-entry-modal' }));
  };

  const handleSubmit = (values, actions) => {
    const data = new FormData(); // eslint-disable-line no-undef

    Object.keys(values).forEach(key => {
      data.set(`cash_book_entry[${key}]`, values[key]);
    });

    createResource({
      endpointPath: 'cash_book_entries',
      data,
      successMessage: t('Successfully created a new cash book entry.'),
      config: {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      ...actions,
    })(dispatch).then(entry => {
      setData(prev => [entry, ...prev ]);
      dispatch(closeModalForm());
    });
  };

  return (
    <section className="cnt">
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 40,
        }}
      >
        <h1 style={{ padding: 0 }}>{t('Cashbook')}</h1>

        <button className="black button pointer" onClick={openCashbookModal} type="button">
          {t('Add Transaction')}
        </button>
      </header>

      <div className="content">
        <CashbookTable entries={data} toggleChecked={toggleChecked} pagination={pagination} />
      </div>

      <AddCashbookModal handleSubmit={handleSubmit} />
    </section>
  );
};

Cashbook.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Cashbook;
