import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'common/components/Modal';
import AuthorizationGateContainer from 'common/containers/AuthorizationGate';
import { createResource, updateResource, deleteResource, fetchResources } from 'common/actions/api';
import InboxSettingsPage from 'users/pages/Inbox/Settings';
import WhitelistItemFormContainer from '../WhitelistItemForm';

class SettingsPageContainer extends Component {
  static propTypes = {
    customerId: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    createResource: PropTypes.func.isRequired,
    updateResource: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    fetchResources: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  state = { modalOpen: false, whitelistItem: null, whitelistItems: [] };

  componentDidMount() {
    const { customerId, fetchResources } = this.props;

    fetchResources({
      type: 'whitelist_items',
      endpointPath: `customers/${customerId}/whitelist_items`,
    }).then(whitelistItems => {
      this.setState({ whitelistItems });
    });
  }

  handleCreate = values => {
    const { customerId, createResource } = this.props;

    return createResource({
      type: 'whitelist_items',
      endpointPath: `customers/${customerId}/whitelist_items`,
      data: {
        whitelist_item: { ...values },
      },
    }).then(item => {
      this.setState(prevState => ({
        whitelistItems: [...prevState.whitelistItems, item],
      }));

      this.closeModal();
    });
  };

  handleUpdate = values => {
    const { customerId, updateResource } = this.props;
    const {
      whitelistItem: { id },
    } = this.state;

    return updateResource({
      type: 'whitelist_items',
      endpointPath: `customers/${customerId}/whitelist_items/${id}`,
      data: {
        whitelist_item: { ...values },
      },
    }).then(() => {
      this.setState(prevState => ({
        whitelistItems: prevState.whitelistItems.map(item =>
          item.id === id ? { ...item, ...values } : item
        ),
      }));

      this.closeModal();
    });
  };

  handleDestroy = id => {
    const { customerId, deleteResource } = this.props;

    deleteResource({
      type: 'whitelist_items',
      endpointPath: `customers/${customerId}/whitelist_items/${id}`,
    }).then(() => {
      this.setState(prevState => ({
        whitelistItems: prevState.whitelistItems.filter(item => item.id !== id),
      }));
    });
  };

  toggleStatus = item => {
    const { customerId, updateResource } = this.props;
    const { id, status } = item;
    const nextStatus = ['disabled', 'pending'].includes(status) ? 'enabled' : 'disabled';

    return updateResource({
      endpointPath: `customers/${customerId}/whitelist_items/${id}`,
      data: {
        whitelist_item: { status: nextStatus },
      },
      successMessage: 'Successfully updated whitelist item status.',
    }).then(() => {
      this.setState(prevState => ({
        whitelistItems: prevState.whitelistItems.map(i =>
          i.id === id ? { ...item, status: nextStatus } : i
        ),
      }));
    });
  };

  openCreateModal = () => {
    this.setState({ modalOpen: true, whitelistItem: null });
  };

  openEditModal = whitelistItem => {
    this.setState({ modalOpen: true, whitelistItem });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, whitelistItem: null });
  };

  render() {
    const { modalOpen, whitelistItem, whitelistItems } = this.state;
    const { t } = this.context;

    return (
      <AuthorizationGateContainer roles="manager" feature="inbox" redirect>
        <InboxSettingsPage
          {...this.props}
          whitelistItems={whitelistItems}
          openCreateModal={this.openCreateModal}
          openEditModal={this.openEditModal}
          handleDestroy={this.handleDestroy}
          toggleStatus={this.toggleStatus}
        />

        <Modal
          open={modalOpen}
          closeModal={this.closeModal}
          title={whitelistItem ? t('Update Whitelist Item') : t('Create Whitelist Item')}
        >
          <WhitelistItemFormContainer
            whitelistItem={whitelistItem}
            handleCreate={this.handleCreate}
            handleUpdate={this.handleUpdate}
          />
        </Modal>
      </AuthorizationGateContainer>
    );
  }
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  email: currentUser.customerInvoiceEmail,
  customerId: currentUser.customerId,
});

export default connect(mapStateToProps, {
  createResource,
  updateResource,
  deleteResource,
  fetchResources,
})(SettingsPageContainer);
