import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@material-ui/core/CircularProgress';
import AccountsTable from 'users/components/figo/AccountsTable';

const FigoIntegrationPage = (
  { accounts, location, startIntegration, showIframe, loading },
  { t }
) => (
  <div className="figo-integration" style={{ position: 'relative' }}>
    <section className="cnt">
      <header>
        <h1>{t('Bank Accounts')}</h1>
      </header>

      <div style={{ marginBottom: 40 }}>
        {location && !showIframe && (
          <button className="black button pointer" onClick={startIntegration}>
            <i className="fa fa-bank" />
            {accounts.length > 0 ? t('Manage bank accounts') : t('Add a bank account')}
          </button>
        )}
      </div>

      {loading && (
        <Loader
          style={{
            color: 'black',
            position: 'absolute',
            left: '50%',
            zIndex: 99,
          }}
        />
      )}

      {showIframe && (
        <div style={{ width: '100%', height: '1200px' }}>
          <iframe
            title={t('Finleap Integration')}
            src={location}
            width="100%"
            height="100%"
            style={{ border: 0 }}
          />
        </div>
      )}

      <div>{accounts.length > 0 && <AccountsTable accounts={accounts} />}</div>
    </section>
  </div>
);

FigoIntegrationPage.propTypes = {
  location: PropTypes.string,
  accounts: PropTypes.array,
  startIntegration: PropTypes.func.isRequired,
  showIframe: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

FigoIntegrationPage.defaultProps = {
  location: null,
  accounts: [],
};

FigoIntegrationPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FigoIntegrationPage;
