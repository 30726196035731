/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { useDropzone } from 'react-dropzone';

const New = (
  { customer, onDrop, documents, submit, submitAll, reset, invalidFiles, remainingUploads },
  { t }
) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.pdf,.csv,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.gif',
  });
  const { ref, ...rootProps } = getRootProps();
  const [costCenter, setCostCenter] = useState('');
  const [costUnit, setCostUnit] = useState('');
  const showDropzone = documents.length === 0;

  const setAllCostCenters = (e, setFieldValue) => {
    setCostCenter(e.target.value);

    documents.forEach((d, i) => {
      if (d.submitted) return;
      setFieldValue(`documents[${i}].cost_center`, e.target.value);
    });
  };

  const setAllCostUnits = (e, setFieldValue) => {
    setCostUnit(e.target.value);

    documents.forEach((d, i) => {
      if (d.submitted) return;
      setFieldValue(`documents[${i}].cost_unit`, e.target.value);
    });
  };

  const dropInstructions = (
    <div className="drop-instruction">
      <div className="controls pointer">
        <div className="button black pointer">
          <i className="fa fa-files-o" />
          {t('Select Files')}
        </div>

        <label className="pointer">
          {t('Select files from your computer or drag and drop them to the area below.')}
        </label>
      </div>

      {invalidFiles.length > 0 && (
        <div className="alert-box error">
          <i className="fa fa-exclamation-triangle" />

          <div className="alert-heading" style={{ marginLeft: 70 }}>
            {t('Some Files Could Not Be Uploaded')}
          </div>

          <div className="alert-content with-icon">
            <p>
              {t(
                'We currently only support PDF and CSV files. The following files could not be uploaded'
              )}
              :
            </p>
          </div>
        </div>
      )}

      <div className="drop-area" {...rootProps}>
        <p>
          <input {...getInputProps()} />

          <i className="fa fa-cloud-upload" />
          {t('Drag & Drop')}
        </p>
      </div>
    </div>
  );

  return (
    <section className="cnt">
      <h1>{t('Upload Documents')}</h1>

      <div className="content">
        {customer.features.includes('document_tracking') && (
          <h5 style={{ marginBottom: 20 }}>
            {t('Remaining Uploads')}:{' '}
            <strong>
              {remainingUploads.number_of_uploads > 0 ? remainingUploads.number_of_uploads : 0}
            </strong>
          </h5>
        )}

        {showDropzone && dropInstructions}

        {!showDropzone && (
          <div className="dropped-content">
            <div className="heading clr">
              <p className="float-left">
                <b>
                  {t('Imported Files')} {documents.length}
                </b>
              </p>

              <Formik
                initialValues={{ documents }}
                enableReinitialize
                render={({ values, setFieldValue }) => (
                  <div>
                    <div className="sort">
                      <ul className="float-right">
                        <li>
                          <input
                            value={costCenter}
                            onChange={e => setAllCostCenters(e, setFieldValue)}
                            placeholder={t('Assign Cost Center')}
                          />
                        </li>

                        <li>
                          <input
                            value={costUnit}
                            onChange={e => setAllCostUnits(e, setFieldValue)}
                            placeholder={t('Assign Cost Unit')}
                          />
                        </li>

                        <li>
                          <button className="black button" onClick={reset}>
                            {t('Reset')}
                          </button>
                        </li>

                        {documents.length > 1 && (
                          <li>
                            <button className="black button" onClick={submitAll}>
                              {t('Submit All')}
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>

                    <div className="table">
                      <table
                        className="a-one-left a-two-left"
                        style={{ tableLayout: 'fixed', marginTop: 40 }}
                      >
                        <thead>
                          <tr>
                            <th>{t('Name')}</th>
                            <th>{t('Note')}</th>
                            <th>{t('Cost Center')}</th>
                            <th>{t('Cost Unit')}</th>
                            <th>{t('Actions')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {values.documents.map((document, index) => (
                            <tr key={index}>
                              <td>
                                {document.file_name}
                                <Field hidden type="text" name={`documents[${index}].name`} />
                              </td>

                              <td>
                                <Field
                                  component="textarea"
                                  name={`documents[${index}].notes`}
                                  rows={1}
                                  placeholder={t('Type here...')}
                                  style={{ height: 25 }}
                                />
                              </td>

                              <td>
                                <Field name={`documents[${index}].cost_center`} />
                              </td>

                              <td>
                                <Field name={`documents[${index}].cost_unit`} />
                              </td>

                              <td>
                                {document.submitted ? (
                                  <div className="result">
                                    <i className="fa fa-check" style={{ color: '#36D7B7' }}>
                                      {t('Submitted')}
                                    </i>
                                  </div>
                                ) : (
                                  <button
                                    className="black button"
                                    onClick={() => submit(values.documents, index)}
                                  >
                                    {t('Submit')}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

New.propTypes = {
  customer: PropTypes.object.isRequired,
  onDrop: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitAll: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
};

New.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default New;
