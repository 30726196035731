import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

const invoiceSchema = yup.object().shape({
  notes: yup.string(),
  cost_center: yup.string(),
  file: yup.mixed().required('A file is required'),
});

const documentSchema = yup.object().shape({
  file: yup.mixed().required('A file is required'),
});

class AddDocumentForm extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    updateAttachment: PropTypes.func.isRequired,
  };

  render() {
    const { updateAttachment, type } = this.props;
    const isInvoice = type === 'Invoice';
    const schema = isInvoice ? invoiceSchema : documentSchema;

    return (
      <Formik
        initialValues={{ notes: '', cost_center: '', file: undefined }}
        validationSchema={schema}
        onSubmit={updateAttachment}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="full ui form">
            <div className="content">
              {isInvoice && (
                <div className="field">
                  <Field type="text" name="notes" placeholder="Notes" autoFocus />
                  <ErrorMessage name="notes" component="span" className="error" />
                </div>
              )}

              {isInvoice && (
                <div className="field">
                  <Field type="text" name="cost_center" placeholder="Cost Center" />
                  <ErrorMessage name="cost_center" component="span" className="error" />
                </div>
              )}

              <div className="field">
                <input
                  type="file"
                  name="file"
                  placeholder="Invoice"
                  onChange={event => {
                    setFieldValue('file', event.currentTarget.files[0]);
                  }}
                />
                <ErrorMessage name="file" component="span" className="error" />
              </div>
            </div>

            <div className="field">
              <button type="submit" className="ui button black" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AddDocumentForm;
