/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewButton, CompleteButton, SnoozeButton } from './Buttons';
import DetailPanelContainer from 'users/containers/inbox/DetailPanel';
import DueDate from './DueDate';

const Table = ({ title, notifications, handleUpdate, search, openModal }, { t }) => (
  <MaterialTable
    title={title}
    columns={[
      {
        title: t('Amount'),
        field: 'amount',
        render: rowData =>
          rowData.amount ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>&euro; {rowData.amount}</span>
          ) : (
            '----'
          ),
      },
      {
        title: t('Due Date'),
        field: 'due_date',
        type: 'date',
        render: rowData => <DueDate {...rowData} />,
      },
      {
        title: t('Actions'),
        field: 'id',
        cellStyle: {
          textAlign: 'right',
        },
        headerStyle: {
          textAlign: 'right',
        },
        render: rowData => (
          <React.Fragment>
            {rowData.invoice_action === 'information_invalid' ? (
              <Tooltip title={t('Information Invalid')} placement="top">
                <IconButton>
                  <FontAwesomeIcon icon="exclamation-triangle" size="sm" color="#f39c12" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('Information Missing')} placement="top">
                <IconButton>
                  <FontAwesomeIcon icon="info-circle" size="sm" color="#3498db" />
                </IconButton>
              </Tooltip>
            )}
            <ViewButton path={rowData.action_path} />
            <CompleteButton notification={rowData} handleUpdate={handleUpdate} />
            {!rowData.today && !rowData.overdue && (
              <SnoozeButton notification={rowData} handleUpdate={handleUpdate} />
            )}
          </React.Fragment>
        ),
      },
    ]}
    data={notifications}
    options={{
      search,
      paging: true,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50],
    }}
    detailPanel={rowData => (
      <DetailPanelContainer
        type="Invoice"
        id={rowData.record_id}
        customerId={rowData.customer_id}
        openModal={openModal}
      />
    )}
    onRowClick={(event, rowData, togglePanel) => togglePanel()}
  />
);

Table.propTypes = {
  title: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  search: PropTypes.bool,
};

Table.defaultProps = {
  search: true,
};

Table.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Table;
