import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import range from 'lodash/range';
import get from 'lodash/get';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BlueButton, GreenButton } from 'common/components/Buttons';
import SimpleDataTableContainer from 'common/containers/SimpleDataTable';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const IndividualReports = (_, { t }) => {
  const { customerId } = useSelector(state => state.auth.currentUser);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef(null);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <section className="cnt">
      <header>
        <h1>{t('Individual Reports')}</h1>
      </header>

      <div className="content">
        <div className="heading clr">
          <div className="float-right">
            <FormControl variant="outlined" style={{ marginTop: 2, marginRight: 5, marginLeft: 5 }}>
              <InputLabel ref={inputLabel} id="month">
                {t('Month')}
              </InputLabel>

              <Select
                margin="dense"
                id="month"
                value={month}
                onChange={e => setMonth(e.target.value)}
                style={{ width: 250 }}
                input={<OutlinedInput labelWidth={labelWidth} name="month" id="month" />}
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {t(month)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ marginTop: 2, marginRight: 5, marginLeft: 5 }}>
              <InputLabel ref={inputLabel} id="year">
                {t('Year')}
              </InputLabel>

              <Select
                margin="dense"
                id="year"
                value={year}
                onChange={e => setYear(e.target.value)}
                style={{ width: 100 }}
                input={<OutlinedInput labelWidth={labelWidth} name="year" id="year" />}
              >
                {range(currentYear - 5, currentYear + 1).map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <SimpleDataTableContainer
          endpoint={`/api/v2/customers/${customerId}/documents`}
          query={{ doc_year: year, doc_cycle: month, individual_report: true }}
          tableProps={{
            style: { tableLayout: 'fixed', whiteSpace: 'nowrap' },
          }}
          columns={[
            {
              title: t('Name'),
              field: 'name',
              styles: { width: 200 },
            },
            {
              title: t('Note'),
              field: 'note',
              styles: { width: 200 },
            },
            {
              title: t('Year'),
              field: 'year',
              styles: { width: 100 },
            },
            {
              title: t('Cycle'),
              field: 'cycle',
              styles: { width: 100 },
            },
            {
              title: t('Actions'),
              field: 'id',
              styles: { width: 250 },
              render: row => (
                <div>
                  <BlueButton
                    size="small"
                    onClick={() => {
                      const url = get(row, 'attachment.attachment.url');
                      if (url) window.open(url, '_blank'); // eslint-disable-line no-undef
                    }}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('View')}

                    <FontAwesomeIcon
                      icon="external-link-alt"
                      size="sm"
                      style={{ marginLeft: 10 }}
                    />
                  </BlueButton>

                  <GreenButton
                    size="small"
                    onClick={() => {
                      const url = get(row, 'attachment.attachment.url');
                      if (url) window.open(`${url}?attachment=true`, '_blank'); // eslint-disable-line no-undef
                    }}
                    style={{ marginLeft: 5, textTransform: 'capitalize' }}
                  >
                    {t('Download')}

                    <FontAwesomeIcon icon="file-download" size="sm" style={{ marginLeft: 10 }} />
                  </GreenButton>
                </div>
              ),
            },
          ]}
        />
      </div>
    </section>
  );
};

IndividualReports.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default IndividualReports;
