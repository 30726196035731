import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import history from './store/history';

const SSRouter = ({ children, path }) => {
  if (typeof window !== 'undefined') {
    return <Router history={history}>{children}</Router>;
  }

  return (
    <StaticRouter location={path} context={{}}>
      {children}
    </StaticRouter>
  );
};

SSRouter.propTypes = {
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default SSRouter;
