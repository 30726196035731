import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';

import ModalFormContainer from 'common/containers/ModalForm';
import OutlinedSelect from 'common/components/OutlinedSelect';
import KeyboardDateField from 'common/components/KeyboardDateField/';

const unsetColorStyles = { color: 'unset' };
const titleStyles = { paddingBottom: 0 };
const currencies = ['eur', 'usd', 'gbp', 'chf'];
const initialValues = {
  date: null,
  document_number: '',
  amount: '',
  currency: 'eur',
  exchange_rate: 1.0,
  description: '',
  cost_unit: '',
  cost_center: '',
  checked: false,
};

const AddCashbookModal = ({ handleSubmit }, { t }) => (
  <ModalFormContainer
    id="cashbook-entry-modal"
    handleSubmit={handleSubmit}
    initialValues={initialValues}
    title={t('Add Cash Transaction')}
    titleStyle={titleStyles}
  >
    {({ setFieldValue }) => (
      <Fragment>
        <Field
          component={KeyboardDateField}
          name="date"
          label={t('Date')}
          inputVariant="outlined"
          variant="inline"
          format="DD/MM/YYYY"
          placeholder="25/12/2020"
          margin="normal"
          style={unsetColorStyles}
          className="unset-input-color"
          fullWidth
          required
          autoOk
        />

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          type="number"
          label={t('Amount')}
          name="amount"
          style={unsetColorStyles}
          className="unset-input-color"
          fullWidth
          required
        />

        <OutlinedSelect id="currency" name="currency" label={t('Currency')} fullWidth autoFocus>
          {currencies.map(currency => (
            <MenuItem key={currency} value={currency}>
              {currency.toUpperCase()}
            </MenuItem>
          ))}
        </OutlinedSelect>

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          type="number"
          label={t('Exchange Rate')}
          name="exchange_rate"
          style={unsetColorStyles}
          className="unset-input-color"
          fullWidth
          required
        />

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          label={t('Description')}
          name="description"
          style={unsetColorStyles}
          className="unset-input-color"
          fullWidth
          required
        />

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          className="unset-input-color"
          label={t('Document Number')}
          name="document_number"
          fullWidth
        />

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          className="unset-input-color"
          label={t('Cost Unit')}
          name="cost_unit"
          fullWidth
        />

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          className="unset-input-color"
          label={t('Cost Center')}
          name="cost_center"
          fullWidth
        />

        <input
          type="file"
          name="attachment"
          placeholder="Invoice"
          onChange={event => {
            setFieldValue('attachment', event.currentTarget.files[0]);
          }}
        />
      </Fragment>
    )}
  </ModalFormContainer>
);

AddCashbookModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

AddCashbookModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddCashbookModal;
