import omit from 'lodash/omit';
import {
  ADD_CREDITOR,
  UPDATE_CREDITOR,
  FETCH_CREDITORS,
  REMOVE_CREDITOR,
} from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  items: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CREDITOR:
    case UPDATE_CREDITOR: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: { ...state.items[action.payload.id], ...action.payload },
        },
      };
    }

    case FETCH_CREDITORS: {
      return {
        ...state,
        items: arrayToHashMap(action.payload),
      };
    }

    case REMOVE_CREDITOR: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
