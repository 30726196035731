import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from '../constants/types';

export const enqueueSnackbar = notification => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const successNotification = message => dispatch => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
};

export const errorNotification = message => dispatch => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'error' } }));
};

export const warningNotification = message => dispatch => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'warning' } }));
};

export const infoNotification = message => dispatch => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'info' } }));
};

export const NotificationManager = {
  success: successNotification,
  error: errorNotification,
  warning: warningNotification,
  info: infoNotification,
};
