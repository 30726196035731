/* eslint-disable */
import React from 'react';
import './AngularLink.scss';

const AngularLink = ({ to, children, ...rest }) => (
  <a
    {...rest}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();

      window.location.href = to;
    }}
    href="javascript:void(0)"
  >
    {children}
  </a>
);

export default AngularLink;
