import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slide from '@material-ui/core/Slide';
import { normalizeInitialValues } from '../../../common/utils/helpers';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} timeout={300} />
));

const ModalForm = (
  {
    open,
    closeModalForm,
    handleSubmit,
    title,
    initialValues,
    children,
    nullableFields,
    enableReinitialize,
    schema,
    style,
    fullScreen,
    titleStyle,
  },
  { t }
) => {
  const values = normalizeInitialValues(initialValues, nullableFields);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={closeModalForm}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingRight: 16,
          }}
        >
          <DialogTitle style={titleStyle}>{title}</DialogTitle>

          <IconButton
            onClick={closeModalForm}
            variant="text"
            color="primary"
            style={{ height: 40, width: 40 }}
          >
            <FontAwesomeIcon icon="times" size="sm" />
          </IconButton>
        </div>

        <Formik
          initialValues={values}
          onSubmit={handleSubmit}
          enableReinitialize={enableReinitialize}
          validationSchema={schema}
        >
          {actions => (
            <DialogContent style={style}>
              <Form>
                {typeof children === 'function' ? children(actions) : children}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={actions.isSubmitting}
                  style={{ marginTop: 15, float: 'right' }}
                >
                  {t('Submit')}
                </Button>
              </Form>
            </DialogContent>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModalForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  nullableFields: PropTypes.array,
  enableReinitialize: PropTypes.bool,
  schema: PropTypes.object,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  fullScreen: PropTypes.bool,
};

ModalForm.defaultProps = {
  nullableFields: [],
  enableReinitialize: false,
  schema: {},
  style: {},
  titleStyle: {},
  fullScreen: false,
};

ModalForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModalForm;
