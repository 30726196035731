import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { createResource, deleteResource, fetchResources } from 'common/actions/api';
import Comment from 'users/components/inbox/Comment';

const schema = yup.object().shape({
  comment: yup.string().required('is required'),
});

class CommentsContainer extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    createResource: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    fetchResources: PropTypes.func.isRequired,
    recordId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    comments: [],
  };

  componentDidMount() {
    const { fetchResources, recordId, customerId, type } = this.props;
    const query = type === 'Invoice' ? { by_invoice: recordId } : { by_document: recordId };

    fetchResources({
      type: 'comments',
      endpointPath: `customers/${customerId}/comments`,
      query,
    }).then(comments => {
      this.setState({ comments });
    });
  }

  createComment = (values, { setErrors, setSubmitting, resetForm }) => {
    const { createResource, recordId, customerId, type } = this.props;

    return createResource({
      type: 'comments',
      endpointPath: `customers/${customerId}/comments`,
      data: {
        comment: {
          commentable_type: type,
          commentable_id: recordId,
          customer_id: customerId,
          comment: values.comment,
        },
      },
    })
      .then(comment => {
        setSubmitting(false);
        this.setState(prevState => ({ comments: [...prevState.comments, comment] }));
        resetForm({ comment: '' });
      })
      .catch(errors => {
        setSubmitting(false);
        setErrors(errors);
        resetForm({ comment: '' });
      });
  };

  deleteComment = id => {
    const { customerId, deleteResource } = this.props;

    deleteResource({
      type: 'comments',
      endpointPath: `customers/${customerId}/comments/${id}`,
    }).then(() => {
      this.setState(prevState => ({
        comments: prevState.comments.filter(comment => comment.id !== id),
      }));
    });
  };

  render() {
    const { t } = this.context;
    const { comments } = this.state;
    const { currentUser } = this.props;

    return (
      <React.Fragment>
        <div className="comments">
          <List>
            {comments.map(comment => (
              <Comment
                key={comment.id}
                comment={comment}
                isAuthor={comment.author_id === currentUser.id && comment.author_type !== 'Admin'}
                deleteComment={this.deleteComment}
              />
            ))}
          </List>
        </div>

        <div className="form">
          <Formik
            initialValues={{ comment: '' }}
            validationSchema={schema}
            onSubmit={this.createComment}
          >
            {({ isSubmitting }) => (
              <Form className="full ui form">
                <div className="field">
                  <Field
                    component="textarea"
                    style={{ height: 65 }}
                    name="comment"
                    placeholder={t('Leave a comment')}
                  />
                  <ErrorMessage name="comment" component="span" className="error" />
                </div>

                <div className="field">
                  <button type="submit" className="ui button black" disabled={isSubmitting}>
                    {t('Reply')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

CommentsContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps, {
  createResource,
  deleteResource,
  fetchResources,
})(CommentsContainer);
