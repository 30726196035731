import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

const AuthenticationGateContainer = ({ isSignedIn, children, type, redirect, location }) => {
  const other =
    redirect && !location.pathname.includes('login') ? <Redirect to={`/${type}/login`} /> : null;

  return isSignedIn ? children : other;
};

AuthenticationGateContainer.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  redirect: PropTypes.bool,
};

AuthenticationGateContainer.defaultProps = {
  type: 'users',
  redirect: false,
};

const mapStateToProps = ({ auth: { isSignedIn } }) => ({
  isSignedIn,
});

export default withRouter(connect(mapStateToProps)(AuthenticationGateContainer));
