import omit from 'lodash/omit';
import { ADD_USER, UPDATE_USER, FETCH_USERS, REMOVE_USER } from '../constants/types';
import { arrayToHashMap } from '../utils/helpers';

const INITIAL_STATE = {
  byCustomer: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_USER:
    case UPDATE_USER: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customer_id]: {
            ...state.byCustomer[action.payload.customer_id],
            [action.payload.id]: {
              ...state.byCustomer[action.payload.customer_id][action.payload.id],
              ...action.payload,
            },
          },
        },
      };
    }

    case FETCH_USERS: {
      return {
        ...state,
        byCustomer: {
          ...state.byCustomer,
          [action.payload.customerId]: arrayToHashMap(action.payload.data),
        },
      };
    }

    case REMOVE_USER: {
      return {
        ...state,
        items: omit(state.items, [action.payload]),
      };
    }

    default:
      return state;
  }
}
